import React, { SVGProps } from 'react'

const CheckCircleIcon = ({ ...props }: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            {...props}
        >
            <path
                id="Icon_awesome-check-circle"
                data-name="Icon awesome-check-circle"
                d="M20.563,10.563a10,10,0,1,1-10-10A10,10,0,0,1,20.563,10.563ZM9.406,15.857l7.419-7.419a.645.645,0,0,0,0-.912l-.912-.912a.645.645,0,0,0-.912,0L8.95,12.664,6.125,9.839a.645.645,0,0,0-.912,0l-.912.912a.645.645,0,0,0,0,.912l4.194,4.194a.645.645,0,0,0,.912,0Z"
                transform="translate(-0.563 -0.563)"
            />
        </svg>
    )
}

export default CheckCircleIcon