import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as Api from '../../Api';

interface HomeState {
  readonly dictionaries: Api.DictionaryType[];
  readonly isLoading: boolean;
  readonly isSuccess: boolean;
  readonly vacancies: Api.VacancySearchType[];
  readonly prevLocation?: string;
  readonly indexPageCountViewModel: Api.DataCountType;
  readonly isEmailVerified: boolean;
}

const state: HomeState = {
  dictionaries: [],
  isLoading: false,
  isSuccess: false,
  vacancies: [],
  isEmailVerified: false,
  indexPageCountViewModel: {
    activeVacancyCount: 0,
    companyCount: 0,
    userCount: 0,
  },
};

export const getDictionaries = createAsyncThunk<Api.DictionaryType[]>('home/getDictionaries', async () => {
  const response = await Api.getAllDictionariesRequest(true);
  return response.data;
});

export const getLatestVacancies = createAsyncThunk<Api.VacancySearchType[]>('vacancy/getLatestVacancies', async () => {
  const response = await Api.getLatestVacanciesRequest();
  return response.data;
});
export const getNewCompanies = createAsyncThunk<Api.CompanySearchType[]>('company/getNewCompanies', async () => {
  const response = await Api.getNewCompaniesRequest();
  return response.data;
});

export const postVerifyUserEmail = createAsyncThunk(
  'Account/VerifyUserEmail',
  async ({ verificationId, email }: { verificationId: string; email: string | null }) => {
    const response = await Api.postVerifyUserEmailRequest(verificationId, email);
    return response.data;
  }
);

export const getHeroImageDetails = createAsyncThunk<Api.HeroImageDetailsType>(
  'heroImage/getHeroImageDetails',
  async () => {
    const response = await Api.getHeroImageDetails();
    return response.data.data;
  }
);

export const getIndexPageCount = createAsyncThunk<Api.DataCountType>('Account/GetIndexPageCount', async () => {
  const response = await Api.getIndexPageCount();
  return response.data;
});

export const homeSlice = createSlice({
  name: 'home',
  initialState: state,
  reducers: {
    setPrevLocation: (state, action: PayloadAction<string>) => {
      state.prevLocation = action.payload;
    },
    updateIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDictionaries.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDictionaries.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dictionaries = action.payload;
      })
      .addCase(getDictionaries.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getLatestVacancies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLatestVacancies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.vacancies = action.payload;
      })
      .addCase(getLatestVacancies.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getNewCompanies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNewCompanies.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getNewCompanies.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getIndexPageCount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getIndexPageCount.fulfilled, (state, action) => {
        state.indexPageCountViewModel = action.payload;
        state.isLoading = false;
      })
      .addCase(getIndexPageCount.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postVerifyUserEmail.fulfilled, (state) => {
        state.isSuccess = true;
        state.isEmailVerified = true;
      })
      .addCase(postVerifyUserEmail.rejected, (state) => {
        state.isSuccess = false;
      });
  },
});

export const { updateIsLoading, setPrevLocation } = homeSlice.actions;

export default homeSlice.reducer;
