import { FC } from 'react';
import classes from './styles.module.sass';

// import eventSeat from '../../../Assets/Icons/event_seat_lg.svg';
// import moreIcon from '../../../Assets/Icons/more-icon.svg';
// import techIcon from '../../../Assets/Icons/memory_lg.svg';
// import person from '../../../Assets/Icons/person.svg';
import { Link } from 'react-router-dom';

const Numbers: FC = () => {
  return (
    <div className={classes.numbers_containter}>
      <h3>
        <Link className={classes.numbers_link} to={'/'}>
          Techwave.ge
        </Link>{' '}
        in numbers
      </h3>
      <div className={classes.numbers_content}>
        <img
          className={classes.vacancies_img}
          src={'https://softwaregecdn.azureedge.net/websitefiles/images/partners/vacancies.png'}
          alt='f-icon'
        />
        <img
          className={classes.vacancies_img_sm}
          src={'https://softwaregecdn.azureedge.net/websitefiles/images/partners/vacancies-sm.png'}
          alt='f-icon'
        />
        <div className={classes.numbers_stats}>
          <div className={classes.numbers_stat_item}>
            <div className={classes.numbers_stat_icon}>
              <img src={'https://softwaregecdn.azureedge.net/websitefiles/icons/person.svg'} />
            </div>
            <div>
              <h4>350+</h4>
              <p>Users</p>
            </div>
          </div>
          <div className={classes.numbers_stat_item}>
            <div className={classes.numbers_stat_icon}>
              <img src={'https://softwaregecdn.azureedge.net/websitefiles/icons/event-seat.svg'} />
            </div>
            <div>
              <h4>50+</h4>
              <p>Vacancies</p>
            </div>
          </div>
          <div className={classes.numbers_stat_item}>
            <div className={classes.numbers_stat_icon}>
              <img src={'https://softwaregecdn.azureedge.net/websitefiles/icons/memory_lg.svg'} />
            </div>
            <div>
              <h4>36+</h4>
              <p>Technologies</p>
            </div>
          </div>
          <div className={classes.numbers_stat_item}>
            <div className={classes.numbers_stat_icon}>
              <img src={'https://softwaregecdn.azureedge.net/websitefiles/icons/more-icon.svg'} />
            </div>
            <div>
              <h4>More+</h4>
              <p>Features coming soon</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Numbers;
