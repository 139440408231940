import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import { Navigation, Scrollbar } from 'swiper';
import classes from './styles.module.sass';

import companies from './companyList';
import { ReactSVG } from 'react-svg';

const Slider = () => {
  return (
    <div className={classes.slider}>
      <h3>Join the list of companies</h3>
      <div className={classes.scrollable_carousel}>
        <div className={classes.icon + ' arrow__prev'}>
          <ReactSVG src='https://softwaregecdn.azureedge.net/websitefiles/icons/iconPrev.svg' />
        </div>
        <Swiper
          navigation={{
            nextEl: '.arrow__next',
            prevEl: '.arrow__prev',
          }}
          className={classes.carousel_images}
          modules={[Navigation, Scrollbar]}
          slidesPerView={1}
          breakpoints={{
            320: {
              spaceBetween: 0,
              slidesPerView: 'auto',
            },
            768: {
              spaceBetween: 60,
              slidesPerView: 'auto',
            },
            1024: {
              spaceBetween: 40,
              slidesPerView: 'auto',
            },
            1366: {
              spaceBetween: 40,
              slidesPerView: 'auto',
            },
            1600: {
              spaceBetween: 40,
              slidesPerView: 'auto',
            },
            1920: {
              spaceBetween: 200,
              slidesPerView: 'auto',
            },
          }}
        >
          {companies?.map((company, index) => (
            <SwiperSlide key={index}>
              <a href={company.link}>
                <div className={classes.partner_logo_container}>
                  <img
                    className={classes.partner_logo_container__partner_logo}
                    src={company.logo}
                    alt={company.name + ' logo'}
                  />
                  <img
                    className={classes.partner_logo_container__partner_logo_hover}
                    src={company.logoHover}
                    alt={company.name + ' logo'}
                  />
                </div>
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={classes.icon + ' arrow__next'}>
          <ReactSVG src='https://softwaregecdn.azureedge.net/websitefiles/icons/iconNext.svg' />
        </div>
      </div>
    </div>
  );
};

export default Slider;
