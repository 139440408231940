import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Footer from '../../Components/UI/Footer/Footer';
import Header from '../../Components/UI/Header/Header';
import { changeLanguage } from '../../Redux/slices/languageSlice';
import SignUpPage from '../SignUpPage/SignUpPage';
import Card from './Components/Card';
import Numbers from './Components/Numbers';
import PartnersFooter from './Components/PartnersFooter';
import Slider from './Components/Slider';
import classes from './styles.module.sass';

const Partners: FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeLanguage('en-US'));
  }, []);

  return (
    <div className={classes.partners}>
      <div className={classes.container}>
        <Header />
        <div className={classes.inner_container + ' base_container'}>
          <div className={classes.header_text}>
            <p className={classes.title}>
              <span>Start Hiring</span> Top Tech Talent Today at No Cost
            </p>
            <p className={classes.desc_text}>
              In today's fast-paced tech industry, finding the right candidate has become increasingly difficult. But
              with Techwave.ge, that challenge is a thing of the past. We've created a platform that brings together top
              IT companies and the most talented tech professionals in Georgia.
            </p>
          </div>
          <div className={classes.register_form}>
            <SignUpPage isOnlyBusiness />
          </div>
        </div>
      </div>
      <div className={classes.why_join_container + ' base_container'}>
        <h3>Why Join Techwave.ge</h3>
        <div className={classes.why_join_grid}>
          <Card
            iconName='eventSeat'
            headerText='Unlimited Vacancies'
            bodyText='By taking advantage of unlimited vacancies, companies on the Techwave.ge platform can save time, money, and resources while finding the best talent for their team. Join us today and start reaping the benefits of unlimited vacancies!'
          />
          <Card
            iconName='moneyOff'
            headerText='Zero Fees'
            bodyText="At Techwave.ge, we believe that the resources and opportunities to connect with top talent in the tech industry should be available to everyone, regardless of budget. That's why we offer a platform with zero fees."
          />
          <Card
            iconName='techIcon'
            headerText='Tech-Adapted'
            bodyText='With filters and tools specifically designed for the tech industry, a hiring platform is able to accurately match candidates with the right companies, saving time and effort for both parties.'
          />
          <Card
            iconName='peopleIcon'
            headerText='Access to top tech talent'
            bodyText='Companies can easily find and hire highly skilled candidates from a pool of top tech talent on Techwave.ge.'
          />
          <Card
            iconName='sandClock'
            headerText='Streamlined Process'
            bodyText='Our platform streamlines the hiring process, allowing you to find top tech talent with ease and efficiency. Spend less time on recruitment and more time on growing your business.'
          />
          <button onClick={() => window.scrollTo({ behavior: 'smooth', top: 0 })} className={classes.card}>
            Sign Up Today!
          </button>
        </div>
        <Slider />
      </div>
      <Numbers />
      <PartnersFooter />
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Partners;
