// import { VacancyCompanyType } from '../Search/types';

import { compareCompanyTechnologyType } from '../Company';
import { VacancySearchType } from '../Search';

export interface VacancyDescriptionSectionType {
  readonly text: string;
  readonly isBullet: boolean;
  readonly isTitle: boolean;
}

export enum VacancyTypeEnum {
  Active = 1,
  NoneActive = 2,
  Drafts = 3,
}

export interface VacancyDictionaryType {
  id: string;
  name: string;
  nameGE: string;
  iconUrl?: string;
}

export interface VacancyDetailType {
  readonly id: string;
  readonly company: VacancyCompanyType;
  readonly title: string;
  readonly titleGE: string;
  readonly elementContext: string;
  readonly elementContextGE: string;
  readonly isDraft: boolean;
  readonly city: VacancyDictionaryType[];
  readonly workLocation: VacancyDictionaryType[];
  readonly technology: VacancyDictionaryType[];
  readonly employmentType: VacancyDictionaryType[];
  readonly jobFunction: VacancyDictionaryType[];
  readonly deadlineMonth: number | null;
  // readonly vacancyDescriptionSections: VacancyDescriptionSectionType[];
  readonly deadline: Date;
  readonly publishDate: Date;
  readonly transformedTitle: string;
  readonly experience: VacancyDictionaryType[];
  readonly country: VacancyDictionaryType[];
  readonly currency: number;
  readonly language: number[];
  readonly displaySalary: boolean;
  readonly amountFrom: number | null;
  readonly amountTo: number | null;
  readonly field?: VacancyDictionaryType | null;
  readonly applicantCount: number;
}

export interface VacancyDictionaryType {
  id: string;
  name: string;
}

interface VacancyCompanyType {
  id: string;
  name: string;
  smallProfileImageUrl: string;
  transformedName: string;
  isVerified: boolean;
  isClaimingNecessary: boolean | null;
  verifiedDate?: string;
}

export enum CompanySortEnum {
  None = 0,
  LastAdded = 1,
  FirstAdded = 2,
  AtoZ = 3,
  ZtoA = 4,
}

export interface SearchVacancyParams {
  readonly searchTerm: string;
  readonly technologies: string[];
  readonly jobFunctions: string[];
  readonly employmentTypies: string[];
  readonly workLocations: string[];
  readonly sortBy: CompanySortEnum;
  readonly pageNumber: number;
  readonly pageSize: number;
  readonly totalPages: number;
  readonly data: VacancyDetailType[];
}

export interface SearchOtherAndSimilarVacancyType {
  readonly similarVacancies: VacancySearchType[];
  readonly otherVacancies: VacancySearchType[];
}

export interface getVacancyRequestType {
  readonly id: string;
  readonly fromAdmin?: string;
}

export type applicantsType = {
  readonly data: {
    readonly applicants: {
      readonly createDate: string;
      readonly email: string;
      readonly id: string;
      readonly fullName: string;
      readonly phone: string;
      readonly isFavorite: boolean | null;
      readonly vacancyId: string;
      readonly cv: {
        readonly fileName: string;
        readonly url: string;
      };
    }[];
  };
  readonly hasError: boolean;
  readonly logNotifications: [];
  readonly statusCode: number;
};

export type applicantDataType = {
  readonly data: {
    readonly email: string;
    readonly fullName: string;
    readonly id: string;
    readonly isDraft: boolean;
    readonly message: string | null;
    readonly phone: string;
    readonly publishDate: string;
    readonly deadline: string;
    readonly vacancyId: string;
    readonly vacancyTitle: string;
    readonly vacancyTitleGE: string;
    readonly talentTechnologies: compareCompanyTechnologyType[];
    readonly vacancyTechnologies: compareCompanyTechnologyType[];
    readonly cv: {
      readonly fileName: string;
      readonly url: string;
    };
    readonly talentJobFunctions: {
      readonly id: string;
      readonly name: string;
      readonly nameGE: string;
    }[];
    readonly vacancyJobFunctions: {
      readonly id: string;
      readonly name: string;
      readonly nameGE: string;
    }[];
  };
  readonly errors: null;
  readonly succeeded: boolean;
};

type regularObjectType = {
  readonly id: string;
  readonly name: string;
  readonly nameGE: string;
};

export type talentApplicationsType = {
  readonly appliedDate: string;
  readonly id: string;
  readonly vacancyId: string;
  readonly vacancyTitle: string;
  readonly vacancyTitleGE: string;
  readonly city: regularObjectType[];
  readonly employmentType: regularObjectType[];
  readonly jobFunction: regularObjectType[];
  readonly technology: {
    readonly id: string;
    readonly name: string;
    readonly nameGE: string;
    readonly iconUrl: string;
    readonly technologyCategoryId: string;
    readonly technologyCategoryName: string;
    readonly technologyCategoryNameGE: string;
  }[];
  readonly company: {
    readonly id: string;
    readonly isClaimingNecessary: boolean;
    readonly isVerified: boolean;
    readonly name: string;
    readonly smallProfileImageUrl: string;
    readonly transformedName: string;
    readonly verifiedDate: string | null;
  };
};
