import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as Api from '../../Api';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

interface CompanyPanelVacanciesState {
  readonly isLoading: boolean;
  readonly vacancies: {
    Active: Api.VacancySearchType[];
    NoneActive: Api.VacancySearchType[];
    Drafts: Api.VacancySearchType[];
  };
  readonly dictionaries: Api.DictionaryType[];
}

const state: CompanyPanelVacanciesState = {
  isLoading: false,
  vacancies: {
    Active: [],
    NoneActive: [],
    Drafts: [],
  },
  dictionaries: [],
};

export const getAllDictionaries = createAsyncThunk<Api.DictionaryType[]>('signUp/getDictionaries', async () => {
  const response = await Api.getAllDictionariesRequest();
  return response.data;
});

export const getAuthorizeCompanyVacancies = createAsyncThunk<Api.VacancySearchType[], { type: number }>(
  'vacancy/GetAuthorizeCompanyVacancies',
  async (args: { type: number }, thunkApi) => {
    try {
      const response = await Api.getAuthorizeCompanyVacanciesRequest({
        type: args.type,
      });
      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }
      throw error;
    }
  }
);

export const getCompanyVacancy = createAsyncThunk<Api.ResponseModel<Api.VacancyDetailType>, { vacancyId: string }>(
  'vacancy/GetCompanyVacancy',
  async (args: { vacancyId: string }, thunkApi) => {
    try {
      const response = await Api.getCompanyVacancyRequest({
        vacancyId: args.vacancyId,
      });
      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }
      throw error;
    }
  }
);

export const postDeleteCompanyVacancy = createAsyncThunk<Api.ResponseModel<boolean>, string>(
  'vacancy/DeleteCompanyVacancy',
  async (params: string, thunkApi) => {
    try {
      const response = await Api.postDeleteCompanyVacancyRequest(params);

      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }
      throw error;
    }
  }
);

export const postEndCompanyVacancy = createAsyncThunk<Api.ResponseModel<boolean>, string>(
  'vacancy/EndCompanyVacancy',
  async (params: string, thunkApi) => {
    try {
      const response = await Api.postEndCompanyVacancyRequest(params);

      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }
      throw error;
    }
  }
);

export const postRenewCompanyVacancy = createAsyncThunk<Api.ResponseModel<boolean>, string>(
  'vacancy/RenewCompanyVacancy',
  async (params: string, thunkApi) => {
    try {
      const response = await Api.postRenewCompanyVacancyRequest(params);

      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }
      throw error;
    }
  }
);

export const postCreateCompanyVacancy = createAsyncThunk<Api.ResponseModel<boolean>, Api.CompanyVacancyRequestParams>(
  'vacancy/CreateCompanyVacancy',
  async (params: Api.CompanyVacancyRequestParams, thunkApi) => {
    try {
      const response = await Api.postCreateCompanyVacancyRequest(params);

      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }
      throw error;
    }
  }
);

export const postUpdateCompanyVacancy = createAsyncThunk<Api.ResponseModel<boolean>, Api.CompanyVacancyRequestParams>(
  'vacancy/UpdateCompanyVacancy',
  async (params: Api.CompanyVacancyRequestParams, thunkApi) => {
    try {
      const response = await Api.postUpdateCompanyVacancyRequest(params);

      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }
      throw error;
    }
  }
);

export const companyPanelVacanciesSlice = createSlice({
  name: 'companyPanelVacancies',
  initialState: state,
  reducers: {
    updateIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    deleteVacancy: (state, action) => {
      state.vacancies.Active = state.vacancies['Active'].filter((vacancy) => vacancy.id !== action.payload.vacancyId);
      state.vacancies.NoneActive = state.vacancies['NoneActive'].filter(
        (vacancy) => vacancy.id !== action.payload.vacancyId
      );
      state.vacancies.Drafts = state.vacancies['Drafts'].filter((vacancy) => vacancy.id !== action.payload.vacancyId);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAuthorizeCompanyVacancies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAuthorizeCompanyVacancies.fulfilled, (state, action) => {
        state.isLoading = false;
        // @ts-ignore
        state.vacancies[Api.VacancyTypeEnum[action.meta.arg.type]] =
          // @ts-ignore
          action.payload.data;
      })
      .addCase(getAuthorizeCompanyVacancies.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postDeleteCompanyVacancy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postDeleteCompanyVacancy.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(postDeleteCompanyVacancy.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postEndCompanyVacancy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postEndCompanyVacancy.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(postEndCompanyVacancy.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postRenewCompanyVacancy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postRenewCompanyVacancy.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(postRenewCompanyVacancy.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postCreateCompanyVacancy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postCreateCompanyVacancy.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(postCreateCompanyVacancy.rejected, (state, response) => {
        state.isLoading = false;
        //@ts-ignore
        const error = response.payload.errors;
        for (const key in error) {
          toast.error(error[key][0]);
        }
      })
      .addCase(getAllDictionaries.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllDictionaries.fulfilled, (state, action) => {
        state.dictionaries = action.payload;
        state.isLoading = false;
      })
      .addCase(getAllDictionaries.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getCompanyVacancy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCompanyVacancy.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getCompanyVacancy.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postUpdateCompanyVacancy.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(postUpdateCompanyVacancy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postUpdateCompanyVacancy.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { updateIsLoading, deleteVacancy } = companyPanelVacanciesSlice.actions;

export default companyPanelVacanciesSlice.reducer;
