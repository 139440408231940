export enum UserTypeEnum {
  None = 0,
  User = 1,
  Company = 2,
}

export enum AuthenticationErrorType {
  None,
  Password,
  Email,
  Token,
}

export enum ResponseNotificationEnum {
  Info = 1,
  Success = 2,
  Warning = 3,
  Error = 4,
}

export enum VacancyPreviewLoginModelTypeEnum {
  None,
  ApplyNow,
  Subscribe,
}

export interface DataCountType {
  companyCount: number;
  activeVacancyCount: number;
  userCount: number;
}

export type EditBenefitsListType = {
  editBenefitsList: EditBenefitsListItemType[];
};

export type EditBenefitsListItemType = {
  id: string;
  name: string;
  isChecked: boolean;
};

export enum UserActivePageEnum {
  LandingPage = 1,
  CompaniesSearch = 2,
  VacanciesSearch = 3,
  CompanyPage = 4,
  VacancyPage = 5,
}

export enum LanguageEnum {
  NotDefined = 0,
  Georgian = 1,
  English = 2,
  German = 3,
  French = 4,
  Spanish = 5,
  Chinese = 6,
  Turkish = 7,
  Russian = 8,
}

export enum CurrencyEnum {
  None = 0,
  Gel = 1,
  Dollar = 2,
  Euro = 3,
  PoundSterling = 4,
}

export enum keyFactEnum {
  Text = 1,
  Number = 2,
  Year = 3,
  MultipleChoice = 4,
  SingleChoice = 5,
}

export enum dictionaryEnum {
  Benefit = 0,
  Field = 1,
  City = 2,
  Country = 3,
  EmploymentType = 4,
  Experience = 5,
  JobFunction = 6,
  WorkLocation = 7,
  Technology = 8,
  TechnologyCategory = 9,
}

export enum reviewDisplayTypeEnum {
  anonymous = 1,
  actualDetails = 2,
}
