import { useEffect, useRef, useState } from 'react';
import classes from './styles.module.sass';
import { companyByNamesType } from '../../../../Api';
import { useAppDispatch } from '../../../../Redux/store';
import { getCompaniesByName } from '../../../../Redux/slices/companiesSlice';
import { ReactSVG } from 'react-svg';
import useOutsideClick from '../../../../Hooks/useOutsideClick';
import CompanyTooltip from '../../CompanyTooltip/CompanyTooltip';

type propType = {
  label: string;
  placeHolder: string;
  setChosenValue: (company: string) => void;
  id: string;
  chosenValue: string;
  normalInput?: boolean;
};

type Timeout = NodeJS.Timeout;

const RadioSelect = ({ id, label, placeHolder, setChosenValue, chosenValue, normalInput }: propType) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const closeTimeRef = useRef<Timeout | null>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const [focused, setFocused] = useState<boolean>(false);
  const [searchVal, setSearchVal] = useState<string>('');
  const [options, setOptions] = useState<companyByNamesType[] | undefined>(undefined);
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const timeOutRef = useRef<Timeout | null>(null);

  useOutsideClick(optionsRef, () => setOpenDropdown(false));

  if (focused) inputRef.current?.focus();

  const blurElement = () => {
    if (inputRef.current?.value.length === 0) {
      timeOutRef.current = setTimeout(() => {
        setFocused(false);
        setOpenDropdown(false);
      }, 100);
    }
  };

  useEffect(() => {
    return () => {
      if (timeOutRef.current) {
        clearTimeout(timeOutRef.current);
      }
      if (closeTimeRef.current) {
        clearTimeout(closeTimeRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (searchVal.length === 0) setChosenValue('');
  }, [searchVal]);

  useEffect(() => {
    if (inputRef.current?.value.length === 0) {
      setFocused(false);
      return;
    }
    setFocused(true);
  }, []);

  useEffect(() => {
    if (!focused && !searchVal) setOpenDropdown(false);
  }, [focused]);

  useEffect(() => {
    dispatch(getCompaniesByName(''))
      .unwrap()
      .then((res) => {
        const filterOptions = res.filter((el) => el.transformedName !== chosenValue);
        setOptions(filterOptions);
      });
  }, []);

  useEffect(() => {
    if (!chosenValue) return;
    setOptions((prevOptions) => prevOptions?.filter((el) => el.transformedName !== chosenValue));
  }, [chosenValue]);

  const selectOption = (company: companyByNamesType) => {
    setSearchVal(company.name);
    setChosenValue(company.transformedName);
    setFocused(false);
    setOpenDropdown(false);
  };

  const handleUpdateSearch = (searchVal: string) => {
    if (normalInput) setChosenValue(searchVal);
    setSearchVal(searchVal);
    setOpenDropdown(true);
  };

  const openDropDown = () => {
    setFocused(true);
    setOpenDropdown(true);
  };

  return (
    <div
      className={classes.RadioSelect}
      onBlur={(e) => {
        e.stopPropagation();
        blurElement();
      }}
    >
      <label htmlFor={label}>{label}</label>
      <div className={classes.input} onClick={openDropDown}>
        <input
          type='text'
          id={id}
          key={`${id}_child`}
          ref={inputRef}
          value={searchVal}
          onChange={(e) => handleUpdateSearch(e.target.value)}
          placeholder={placeHolder}
          autoComplete='off'
          name={label}
        />
      </div>
      {!normalInput && openDropdown && (
        <div className={classes.options} ref={optionsRef}>
          {options
            ?.filter((el) => el.name.toLowerCase().includes(searchVal.toLowerCase()))
            .map((el) => (
              <div className={classes.option} key={el.id} onClick={() => selectOption(el)}>
                <img src={el.smallProfileImageUrl} />
                <p>{el.name}</p>
                {el?.isClaimingNecessary ? (
                  <CompanyTooltip verified={false} verifiedDate={el.verifiedDate} noTooltip />
                ) : (
                  <>
                    {el?.isVerified ? (
                      <CompanyTooltip verified verifiedDate={el.verifiedDate} noTooltip />
                    ) : (
                      <CompanyTooltip verified={false} verifiedDate={el.verifiedDate} noTooltip />
                    )}
                  </>
                )}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default RadioSelect;
