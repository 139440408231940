import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from '../styles.module.sass';
import PersonalForm from '../components/PersonalForm';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Redux/store';
import useResources from '../../../Hooks/useResources';
import Button from '../../../Components/UI/ReusableComponents/Button/Button';
import { ReactComponent as GoogleIco } from '../../../Assets/Icons/google_Logo.svg';
import { ReactComponent as LinkedinIcon } from '../../../Assets/Icons/LinkedIn_icon_square.svg';

const url = process.env.REACT_APP_API_URL;

interface Props {
  readonly profileType: 'personal' | 'business';
  readonly step: number;
  readonly setStep: React.Dispatch<React.SetStateAction<number>>;
}

const PersonalTab = ({ step, setStep, profileType }: Props) => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const resources = useResources();

  const handleGoogleSignIn = () => {
    window.location.replace(`${url}api/Account/GoogleSignIn`);
  };

  if (isLoggedIn) {
    navigate('/');
  }
  return (
    <>
      <PersonalForm step={step} setStep={setStep} profileType={profileType} />
      <p className={classNames.signInText}>
        {`${resources.SignUp.alreadyHaveAnAccount}? `}
        <Link to='/signIn' className={classNames.signInLink}>
          {resources.Reusable.signIn}
        </Link>
      </p>
      <div className={classNames.input_group_3}>
        <div className={classNames.secondary_text}>
          <p className={classNames.gray_color}>{resources.SignIn.textOr}</p>
        </div>
      </div>
      <div className={classNames.button_group}>
        <Button className={classNames.social_button} onClick={() => handleGoogleSignIn()} type='button'>
          <span className={classNames.header_addVacancy_icon}>
            <GoogleIco />
          </span>
          <span className={classNames.header_addVacancy_text}> {resources.SignIn.signInGoogle}</span>
        </Button>
        <a
          href={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=774k4dt1fhb3ek&redirect_uri=${url}api/Account/LinkedInResponse&state=z8dDS3457&scope=email%20r_basicprofile%20openid`}
        >
          <Button className={classNames.social_button} type='button'>
            <span className={classNames.header_addVacancy_icon}>
              <LinkedinIcon />
            </span>
            <span className={classNames.header_addVacancy_text}>{resources.SignIn.signInLinkeding}</span>
          </Button>
        </a>
      </div>
    </>
  );
};

export default PersonalTab;
