import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TimesCircleIcon } from '../../../Components/Svgs';
import ArrowIcon from '../../../Components/Svgs/ArrowIcon';
import PlusIcon from '../../../Components/Svgs/PlusIcon';
import Button from '../../../Components/UI/ReusableComponents/Button/Button';
import useResources from '../../../Hooks/useResources';
import { logIn } from '../../../Redux/slices/authSlice';
import {
  postCreateCompanyAccount,
  postCreateUserAccount,
  updateImagePreviewUrl,
  updateProfileImage,
} from '../../../Redux/slices/signUpSlice';
import { RootState, useAppDispatch } from '../../../Redux/store';
import classNames from '../styles.module.sass';
import Loader from '../../../Components/UI/Loader/Loader';
import appInsights from '../../../AppInsights/AppInsights';
import { CustomExceptionLog } from '../../../AppInsights/CustomExceptionLog';
import SocialIntegrations from '../../../helpers/socialIntegration';

interface Props {
  readonly profileType: 'personal' | 'business';
  readonly step: number;
  readonly setStep: React.Dispatch<React.SetStateAction<number>>;
}

const ProfileTab = ({ setStep, profileType }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const resources = useResources();
  const { formData, imagePreviewUrl, isLoading } = useSelector((state: RootState) => state.signUp);
  const { email, userType } = useSelector((state: RootState) => state.auth);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files == null) return;

    const file = e.target.files[0];

    const allowFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];

    if (!allowFileTypes.includes(file.type)) {
      toast.warn(resources.Reusable.fileFormatError);
      appInsights.trackException({
        exception: new CustomExceptionLog(
          'Profile Tab Error',
          `mail: ${email}, error: ${resources.Reusable.fileFormatError}`
        ),
      });
      e.target.value = '';
      return;
    }

    if (file.size / 1024 > 10000) {
      toast.warn(resources.Reusable.fileIsTooLarge);
      appInsights.trackException({
        exception: new CustomExceptionLog(
          'Profile Tab Error',
          `mail: ${email}, error: ${resources.Reusable.fileFormatError}`
        ),
      });
      e.target.value = '';
      return;
    }

    dispatch(updateProfileImage(file));
    const url = URL.createObjectURL(e.target.files[0]);
    dispatch(updateImagePreviewUrl(url));
  };

  const handleSubmit = () => {
    if (profileType === 'personal') {
      dispatch(postCreateUserAccount({ body: formData }))
        .unwrap()
        .then((payload) => {
          navigate('/welcome', { replace: true });
          SocialIntegrations('SignUp', formData.Email, { role: userType === 1 ? 'User' : 'Company' });
          dispatch(logIn(payload));
        });
    } else {
      // TODO: need message for profile image requirements
      if (formData.ProfileImage == null) {
        toast.warn(`${resources.SignUp.pleaseUploadThephoto}!`, { autoClose: 2000 });
        appInsights.trackException({
          exception: new CustomExceptionLog(
            'Profie Tab Error',
            `mail: ${email}, error: ${resources.Reusable.fileFormatError}`
          ),
        });
        return;
      }

      dispatch(
        postCreateCompanyAccount({
          body: {
            Name: formData.Name,
            Email: formData.Email,
            Password: formData.Password,
            ConfirmPassword: formData.Password,
            ProfileImage: formData.ProfileImage,
          },
        })
      )
        .unwrap()
        .then((payload) => {
          navigate('/welcome', { replace: true });
          SocialIntegrations('SignUp', formData.Email, { role: userType === 1 ? 'User' : 'Company' });
          dispatch(logIn(payload));
        });
    }
  };

  return (
    <>
      <div className={classNames.imageUploader}>
        {imagePreviewUrl ? (
          <>
            <img className={classNames.previewImg} src={imagePreviewUrl} alt='Preview' />
            <div
              className={classNames.removeImg}
              onClick={() => {
                dispatch(updateImagePreviewUrl());
                dispatch(updateProfileImage(undefined));
              }}
            >
              <TimesCircleIcon fill='#3E66F8' height='30px' width='30px' />
            </div>
          </>
        ) : (
          <label className={classNames.imageUploadLabel}>
            <div>
              <PlusIcon />
            </div>
            <input type={'file'} accept='.jpeg, .png, .jpg, .webp' onChange={handleImageChange} />
          </label>
        )}
      </div>
      <p className={classNames.pictureText}>
        {profileType === 'business' ? (
          <>
            {resources.SignUp.setCompanyLogo} <span style={{ color: 'red' }}>*</span>
          </>
        ) : (
          resources.SignUp.setProfilePicture
        )}
      </p>
      <div className={classNames.stepButtons}>
        <Button
          className={classNames.prevStep}
          type='button'
          onClick={() => (profileType === 'business' ? setStep(1) : setStep(2))}
        >
          <ArrowIcon fill='#3E66F8' transform='rotate(180)' /> {resources.Reusable.back}
        </Button>
        <Button disabled={isLoading} onClick={handleSubmit} className={classNames.nextStep} type='submit'>
          {resources.SignUp.nextStep}
        </Button>
        {isLoading && <Loader />}
      </div>
    </>
  );
};

export default ProfileTab;
