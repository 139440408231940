import React, { SVGProps } from 'react'

const ArrowIcon = ({ ...props }: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10.26" height="10"
            viewBox="0 0 10.26 10"
            {...props}
        >
            <path
                id="Icon_awesome-arrow-right"
                data-name="Icon awesome-arrow-right"
                d="M4.362,3.317l.508-.508a.547.547,0,0,1,.776,0L10.1,7.258a.547.547,0,0,1,0,.776L5.647,12.486a.547.547,0,0,1-.776,0l-.508-.508a.55.55,0,0,1,.009-.785L7.131,8.563H.55A.548.548,0,0,1,0,8.014V7.281a.548.548,0,0,1,.55-.55H7.131L4.371,4.1A.546.546,0,0,1,4.362,3.317Z"
                transform="translate(0 -2.647)"
            />
        </svg>
    )
}

export default ArrowIcon