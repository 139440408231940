import axios from 'axios';
import {
  getCheckUserExistRequestParams,
  postRecoverUserPasswordParams,
  postSendPasswordRecoveryParams,
  postUserSignInRequestParams,
  postPasswordChangeRequestParams,
  postRefreshTokenRequestParams,
} from '.';
import { UserActivePageEnum } from '../GlobalTypes';

const url = process.env.REACT_APP_API_URL;

export const getCheckUserExistRequest = (params: getCheckUserExistRequestParams) => {
  return axios.get(`${url}api/Account/CheckUserExist?Email=${params.args.email}`);
};

export const getGetUserInfoRequest = () => {
  return axios.get(`${url}api/Account/GetUserInfo`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      withCredentials: true,
    },
  });
};

export const postCreateUserAccountRequest = (formData: FormData) => {
  return axios({
    method: 'post',
    url: `${url}api/Account/CreateUserAccount`,
    data: formData,
    headers: {
      Accept: '*/*',
      'Access-Control-Allow-Origin': '*',
    },
  });
};

export const postCreateCompanyAccountRequest = (formData: FormData) => {
  return axios({
    method: 'post',
    url: `${url}api/Account/CreateCompanyAccount`,
    data: formData,
    headers: {
      Accept: '*/*',
      'Access-Control-Allow-Origin': '*',
    },
  });
};

export const postUserSignInRequest = (params: postUserSignInRequestParams) => {
  return axios.post(`${url}api/Account/SignIn`, params.body, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
};

export const postRefreshTokenRequest = (params: postRefreshTokenRequestParams) => {
  return axios.post(`${url}api/Account/RefreshToken`, params.body, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
};

export const postSendPasswordRecoveryRequest = (params: postSendPasswordRecoveryParams) => {
  return axios.post(`${url}api/Account/SendPasswordRecoveryLink`, params.body, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
};

export const postPasswordChangeRequest = (params: postPasswordChangeRequestParams) => {
  return axios.post(`${url}api/Account/PasswordChange`, params.body, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
};

export const postRecoverUserPasswordRequest = (params: postRecoverUserPasswordParams) => {
  return axios.post(`${url}api/Account/RecoverUserPassword`, params.body, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
};

export const postUpdateProfileImageRequest = (formData: FormData) => {
  return axios({
    method: 'post',
    url: `${url}api/Account/UpdateProfileImage`,
    data: formData,
    headers: {
      Accept: '*/*',
      'Access-Control-Allow-Origin': '*',
    },
  });
};

export const postVerifyUserEmailRequest = async (id: string, email: string | null) => {
  return axios
    .post(`${url}api/Account/VerifyUserEmail`, {
      verificationId: id,
      email: email,
    })
    .catch((err) => {
      throw new Error(err.response.data.error);
    });
};

export const postAddUserActivityRequest = (
  activityPage: UserActivePageEnum,
  companyId?: string,
  vacancyId?: string
) => {
  return axios.post(
    `${url}api/Account/AddUserActivity?userActivePage=${activityPage}${companyId ? `&CompanyId=${companyId}` : ''}${
      vacancyId ? `&VacancyId=${vacancyId}` : ''
    }`
  );
};

export const postReSendVerifyUserEmailRequest = () => {
  return axios.post(`${url}api/Account/ReSendVerifyUserEmail`);
};

export const getViewAsRequest = (id: string) => {
  return axios.get(`${url}api/Account/ViewAs?tokenId=${id}`);
};

export const postDeleteCompanyOrTalentRequest = () => {
  return axios.post(`${url}api/account/DeleteCompanyOrTalent`);
};
