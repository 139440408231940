import React from 'react';
import classes from './styles.module.sass';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: JSX.Element; // eslint-disable-line no-eval
  icon: JSX.Element;
}

function SocialInfoWrapper({ children, icon }: Props) {
  return (
    <div className={classes.socialInfoWrapper}>
      <div className={classes.socialInfoIcon}>{icon}</div>
      {children}
    </div>
  );
}

export default SocialInfoWrapper;
