import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import classes from './loader.module.sass';

const Loader = () => {
  useEffect(() => {
    document.documentElement.style.overflow = 'hidden';

    return () => {
      document.documentElement.style.overflow = 'unset';
    };
  }, []);

  return (
    <>
      {ReactDOM.createPortal(
        <div className={classes.loaderWrapper}>
          <div className={classes.loader}>
            <div className={classes.loader__ball}></div>
            <div className={classes.loader__ball}></div>
            <div className={classes.loader__ball}></div>
          </div>
        </div>,
        document.getElementById('loader')!
      )}
    </>
  );
};

export default Loader;
