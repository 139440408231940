import EditButton from '../EditButton/EditButton';
import classes from './styles.module.sass';

const SectionHeaderEdit = (props: {
  header: string;
  canEdit: boolean;
  handleClick?: () => void;
  customButton?: JSX.Element;
}) => {
  return (
    <div className={classes.sectionHeader}>
      <div className={classes.heading}>{props.header}</div>
      {props.canEdit ? (
        <>
          <div className={classes.divider} />
          {props.customButton ? props.customButton : <EditButton onClick={props.handleClick} />}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SectionHeaderEdit;
