import axios from 'axios';
import { KeyFacts } from '../../Pages/AboutCompany/types';
import * as Api from './index';
import { Benefit, BenefitApiResponse } from './index';

const url = process.env.REACT_APP_API_URL;

export const getCompanyProfileInfoRequest = () => {
  return axios.get(`${url}api/Company/GetCompanyProfileInfo`, {
    headers: {},
  });
};

export const postCompanyTechnologiesRequest = (technologyIds: string[]) => {
  return axios.post(
    `${url}api/Company/UpdateCompanyTechnologies`,
    {
      technologyIds: technologyIds,
    },
    {
      headers: {},
    }
  );
};

export const getSearchCompaniesRequest = (queryString?: string, pageSize = 36) => {
  return axios.get(
    `${url}api/company/searchCompanies${queryString ? `${queryString}&pageSize=${pageSize}` : `?&pageSize=${pageSize}`}`
  );
};

export const getCompaniesByNameRequest = (companyName: string) => {
  return axios.get(`${url}api/Company/GetCompaniesByName?companyName=${companyName}&pageSize=10`);
};

export const getGetCompareCompaniesRequest = () => {
  return axios.get(`${url}api/Company/GetCompareCompanies?pageSize=10`);
};

export const getCompareCompaniesRequest = (compareUrl: string) => {
  return axios.get(`${url}api/Company/CompareCompanies?compareUrl=${compareUrl}`);
};

export const getCompanyDetailsRequest = (id: string) => {
  return axios.get(`${url}api/Company/GetCompanyDetails?CompanyId=${id}`);
};

export const postUpdateCompanyAboutRequest = (enText: string, geText: string) => {
  return axios.post(
    `${url}api/Company/UpdateCompanyAbout`,
    {
      aboutEn: enText,
      aboutGe: geText,
    },
    {
      headers: {},
    }
  );
};

export const postUpdateCompanyTaglineRequest = (data: string) => {
  return axios.post(
    `${url}api/Company/UpdateCompanyTagline`,
    {
      tagline: data,
    },
    {
      headers: {},
    }
  );
};

export const postUpdateCompanyKeyFactsRequest = (data: Api.keyFactsType) => {
  return axios.post(`${url}api/Company/UpdateCompanyKeyFacts`, data, {
    headers: {},
  });
};

export const postUpdateCompanyKeyFactsUpdated = (data: KeyFacts) => {
  return axios.post(`${url}api/Company/UpdateCompanyKeyFacts`, data, {
    headers: {},
  });
};

export const getDictionaryRequest = () => {
  return axios.get(`${url}api/Common/GetDictionary?dictionaryType=1`, {
    headers: {},
  });
};

export const postUpdateCompanyBenefitsRequest = (benefitIds: FormDataEntryValue[]) => {
  return axios.post(
    `${url}api/Company/updateCompanyBenefits`,
    {
      benefitIds,
    },
    {
      headers: {},
    }
  );
};

export const getAllBenefits = (companyId: string) => {
  return axios.get(`${url}api/Benefits/GetCompanyBenefits/${companyId}`);
};

export const getCompanyBenefitsRequest = (id: string) => {
  return axios.get<BenefitApiResponse>(`${url}api/Benefits/GetCompanyBenefits/${id}`, {
    headers: {},
  });
};

export const updateCompanyBenefitsRequest = (benefits: Benefit[]) => {
  return axios.post<BenefitApiResponse>(`${url}api/Benefits/UpdateCompanyBenefits`, benefits, {
    headers: {},
  });
};

export const getKeyFactsRequest = () => {
  return axios.get(`${url}api/company/getKeyFacts`);
};

export const getCompanyKeyFactsByCompanyIdRequest = (id: string) => {
  return axios.get(`${url}api/Company/GetCompanyKeyFactsByCompanyId?companyId=${id}`);
};

export const postCreateUpdateCompanyKeyFactRequest = (KeyFact: Api.companyKeyFactParams) => {
  return axios.post(`${url}api/Company/CreateUpdateCompanyKeyFact`, KeyFact);
};

export const getCompanyProgressRequest = () => {
  return axios.get(`${url}api/Company/getCompanyProgress`);
};

export const postUpdateCoverImageRequest = (formData: FormData) => {
  return axios({
    method: 'post',
    url: `${url}api/Account/updateCoverImage`,
    data: formData,
    headers: {
      Accept: '*/*',
      'Access-Control-Allow-Origin': '*',
    },
  });
};

export const getCompanyReviewsRequest = (companyId: string) => {
  return axios.get(`${url}api/Company/GetCompanyReviews?companyId=${companyId}`);
};

export const postCreateUpdateReviewRequest = (data: Api.createUpdateReviewType) => {
  return axios.post(`${url}api/Company/CreateUpdateReview`, data);
};

export const postLikeReviewRequest = (reviewId: string) => {
  return axios.post(`${url}api/company/likeReview?reviewId=${reviewId}`);
};

export const postDeleteReviewRequest = (reviewId: string) => {
  return axios.post(`${url}api/company/deleteReview?reviewId=${reviewId}`);
};

export const getUserReviewRequest = (reviewId: string) => {
  return axios.get(`${url}api/company/getUserReview?reviewId=${reviewId}`);
};
