import React from 'react';
import useResources from '../../../../Hooks/useResources';
import classes from './styles.module.sass';

export default function EditButton(props: buttonProps) {
  const resources = useResources();
  return (
    <button className={classes.button} type={'button'} onClick={props.onClick}>
      {resources.Reusable.edit}
    </button>
  );
}

interface buttonProps {
  onClick?(): void;
}
