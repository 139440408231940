import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { GeneralSeoDetailsType } from '../../../../Api';
import { RootState } from '../../../../Redux/store';

export default function ReactHelmet() {
  const { seoDetails } = useSelector((state: RootState) => state.seoDetails);
  const { companyAbout, companyName } = useSelector((state: RootState) => state.companies);
  const { vacancyDetails } = useSelector((state: RootState) => state.vacancies);

  const currentLanguage = localStorage.getItem('lang') === 'ka-GE' ? 'ka' : 'en';
  const currentLocation = location.pathname.toLowerCase().slice();
  let currentSeoDetail = {
    title: 'Techwave.ge',
    description: 'Techwave.ge',
    lang: 'en',
  };

  if (seoDetails != null) {
    if (currentLocation == '/') {
      currentSeoDetail = getSeoDetails(seoDetails.homePageSeoDetails, currentLanguage);
    } else if (currentLocation.includes('/search')) {
      currentSeoDetail = {
        title: ' Search',
        description: 'Search - Techwave.ge',
        lang: 'en',
      };
    } else if (currentLocation == '/about') {
      currentSeoDetail = getSeoDetails(seoDetails.aboutUsSeoDetails, currentLanguage);
    } else if (currentLocation == '/companies') {
      currentSeoDetail = getSeoDetails(seoDetails.companiesListSeoDetails, currentLanguage);
    } else if (currentLocation == '/vacancies') {
      currentSeoDetail = getSeoDetails(seoDetails.vacanciesListSeoDetails, currentLanguage);
    } else if (currentLocation == '/signin' || currentLocation == '/signup' || currentLocation == '/passwordrecovery') {
      currentSeoDetail = getSeoDetails(seoDetails.authSeoDetails, currentLanguage);
    } else if (currentLocation.includes('/information')) {
      currentSeoDetail = getSeoDetails(seoDetails.staticPagesSeoDetails, currentLanguage);
    } else if (currentLocation.includes('/companies/') && companyName != null) {
      currentSeoDetail = {
        title: companyName + ' - Company Profile',
        description: companyAbout + ' - Techwave.ge',
        lang: 'en',
      };
    } else if (currentLocation.includes('/vacancies/') && vacancyDetails?.title != null) {
      currentSeoDetail = {
        title: `Become a ${vacancyDetails?.title} at ${vacancyDetails?.company.name} - Techwave.ge`,
        description:
          JSON.parse(vacancyDetails.elementContext).blocks[0].text.toString().substring(0, 150) + ' - Techwave.ge',
        lang: 'en',
      };
    } else if (currentLocation.includes('/companypanel/') || currentLocation.includes('/userpanel/')) {
      currentSeoDetail = {
        title: 'Panel',
        description: 'Panel - Techwave.ge',
        lang: 'en',
      };
    } else if (currentLocation.includes('/compare')) {
      currentSeoDetail = getSeoDetails(seoDetails.compareCompanySeoDetails, currentLanguage);
    } else if (currentLocation.includes('/news')) {
      currentSeoDetail = getSeoDetails(seoDetails.blogSeoDetails, currentLanguage);
    } else {
      currentSeoDetail = getSeoDetails(seoDetails.notFoundSeoDetails, currentLanguage);
    }
  }
  return (
    <Helmet>
      <html lang={currentSeoDetail.lang} />
      <title>{currentSeoDetail?.title || 'Techwave.ge'}</title>
      <meta name='description' content={currentSeoDetail?.description} />
      <link rel='canonical' href={location.pathname} />
    </Helmet>
  );
}

function getSeoDetails(detail: GeneralSeoDetailsType, language: string) {
  return {
    title: language === 'ka' ? detail.titleGe : detail.titleEn,
    description: language === 'ka' ? detail.descriptionGe : detail.descriptionEn,
    lang: language,
  };
}
