import axios from 'axios';
import { DictionaryType, UpdateUserFavoriteApplicantProps } from './types';

const url = process.env.REACT_APP_API_URL;

export const getAllDictionariesRequest = (activeVacancyCount?: boolean, id?: string, filter?: string) => {
  return axios.get(
    `${url}api/Common/GetDictionary${activeVacancyCount ? '?ActiveVacancyCount=true' : '?ActiveCompanyCount=true'}${
      id ? `&companyId=${id}` : ``
    }${filter ? `${filter}` : ``}`
  );
};

export const getTechnologiesRequest = () => {
  return axios.get(`${url}api/Common/GetDictionary?dictionaryType=8`);
};

export const getJobFunctionsRequest = () => {
  return axios.get<DictionaryType[]>(`${url}api/Common/GetDictionary?dictionaryType=6`);
};

export const getFieldsAndExperiencesRequest = () => {
  return axios.get(`${url}api/Common/GetDictionary?dictionaryType=4&dictionaryType=5`);
};

export const getTechnologiesAndCategoryRequest = () => {
  return axios.get(`${url}api/Common/GetDictionary?dictionaryType=7&dictionaryType=8`);
};

export const getIndexPageCount = () => {
  return axios.get(`${url}api/Common/GetIndexPageCount`);
};

export const getSeoDetailsRequest = () => {
  return axios.get(`${url}api/Common/GetSeoDetails`);
};

export const getLanguageRequest = () => {
  return axios.get(`${url}api/Account/GetDefaultLanguage`);
};

export const getHeroImageDetails = () => {
  return axios.get(`${url}api/BackOfficeStaticData/GetHeroTextImage`);
};

export const getCountryCodesRequest = () => {
  return axios.get(`${url}api/common/getCountryCodes`);
};

export const putUpdateUserFavoriteApplicantRequest = (data: UpdateUserFavoriteApplicantProps) => {
  return axios.put(`${url}api/company/updateUserFavoriteApplicant`, data);
};

export const getFavoriteApplicantsRequest = () => {
  return axios.get(`${url}api/company/getFavoriteApplicants`);
};

export const postFindTechnologiesInContextRequest = (data: string) => {
  return axios.post(`${url}api/common/findTechnologiesInContext`, data, {
    headers: { 'Content-Type': 'application/json' },
  });
};
