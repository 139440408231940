import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import * as Api from '../../Api';

interface State {
  readonly formData: Api.postCreateUserAccountRequestParams['body'];
  readonly dictionaries: Api.DictionaryType[];
  readonly isLoading: boolean;
  readonly emailExist: boolean;
  readonly imagePreviewUrl?: string;
}

const state: State = {
  formData: {
    Name: '',
    Email: '',
    Password: '',
    ConfirmPassword: '',
    Field: '',
    Experience: '',
    Technologies: [],
    ProfileImage: undefined,
  },
  dictionaries: [],
  isLoading: false,
  emailExist: false,
  imagePreviewUrl: undefined,
};

export const getAllDictionaries = createAsyncThunk<Api.DictionaryType[]>('signUp/getDictionaries', async () => {
  const response = await Api.getAllDictionariesRequest();
  return response.data;
});

export const getCheckUserExist = createAsyncThunk<boolean, Api.getCheckUserExistRequestParams>(
  'account/getCheckUserExist',
  async (params: Api.getCheckUserExistRequestParams, thunkApi) => {
    try {
      const response = await Api.getCheckUserExistRequest(params);
      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }

      throw err;
    }
  }
);
export const postCreateUserAccount = createAsyncThunk<Api.AuthenticationType, Api.postCreateUserAccountRequestParams>(
  'account/postCreateUserAccount',
  async (params: Api.postCreateUserAccountRequestParams, thunkApi) => {
    try {
      const formData = new FormData();

      if (params.body.ProfileImage != null) {
        formData.append('ProfileImage', params.body.ProfileImage);
      }
      formData.append('Name', params.body.Name);
      formData.append('Email', params.body.Email);
      formData.append('Password', params.body.Password);
      formData.append('ConfirmPassword', params.body.ConfirmPassword);
      if (params.body.Field) {
        formData.append('Field', params.body.Field);
      }
      if (params.body.Experience) {
        formData.append('Experience', params.body.Experience);
      }
      if (params.body.Technologies != null && params.body.Technologies.length > 0) {
        params.body.Technologies.forEach((technology) => {
          formData.append('Technologies', technology);
        });
      }
      if (params.body.JobFunctions && params.body.JobFunctions.length > 0) {
        params.body.JobFunctions.forEach((jobFunc) => {
          formData.append('JobFunctions', jobFunc);
        });
      }

      const response = await Api.postCreateUserAccountRequest(formData);

      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }

      throw err;
    }
  }
);

export const postCreateCompanyAccount = createAsyncThunk<
  Api.AuthenticationType,
  Api.postCreateCompanyAccountRequestParams
>('account/postCreateCompanyAccount', async (params: Api.postCreateCompanyAccountRequestParams, thunkApi) => {
  try {
    const formData = new FormData();

    if (params.body.ProfileImage != null) {
      formData.append('ProfileImage', params.body.ProfileImage);
    }
    formData.append('Name', params.body.Name);
    formData.append('Email', params.body.Email);
    formData.append('Password', params.body.Password);
    formData.append('ConfirmPassword', params.body.ConfirmPassword);

    const response = await Api.postCreateCompanyAccountRequest(formData);

    return response.data;
  } catch (err) {
    const error = err;
    if (error) {
      return thunkApi.rejectWithValue((error as AxiosError).response?.data);
    }

    throw err;
  }
});

export const signUpSlice = createSlice({
  name: 'signUp',
  initialState: state,
  reducers: {
    updateName: (state, action: PayloadAction<string>) => {
      state.formData.Name = action.payload;
    },
    updateEmail: (state, action: PayloadAction<string>) => {
      state.formData.Email = action.payload;
    },
    updatePassword: (state, action: PayloadAction<string>) => {
      state.formData.Password = action.payload;
    },
    updateConfirmPassword: (state, action: PayloadAction<string>) => {
      state.formData.ConfirmPassword = action.payload;
    },
    updateField: (state, action: PayloadAction<string>) => {
      state.formData.Field = action.payload;
    },
    updateExperience: (state, action: PayloadAction<string>) => {
      state.formData.Experience = action.payload;
    },
    updateTechnologies: (state, action: PayloadAction<string[]>) => {
      state.formData.Technologies = action.payload;
    },
    updateJobFunctions: (state, action: PayloadAction<string[]>) => {
      state.formData.JobFunctions = action.payload;
    },
    updateProfileImage: (state, action: PayloadAction<File | undefined>) => {
      state.formData.ProfileImage = action.payload;
    },
    updateImagePreviewUrl: (state, action: PayloadAction<string | undefined>) => {
      state.imagePreviewUrl = action.payload;
    },
    updateIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    updateEmailExist: (state, action: PayloadAction<boolean>) => {
      state.emailExist = action.payload;
    },
    clearFormData: (state) => {
      state.formData.Name = '';
      state.formData.Email = '';
      state.formData.Password = '';
      state.formData.ConfirmPassword = '';
      state.formData.Field = '';
      state.formData.Experience = '';
      state.formData.Technologies = [];
      state.formData.ProfileImage = undefined;
      state.imagePreviewUrl = undefined;
      state.emailExist = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDictionaries.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllDictionaries.fulfilled, (state, action) => {
        state.dictionaries = action.payload;
        state.isLoading = false;
      })
      .addCase(getAllDictionaries.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getCheckUserExist.pending, (state) => {
        state.emailExist = false;
      })
      .addCase(getCheckUserExist.fulfilled, (state) => {
        state.emailExist = false;
      })
      .addCase(getCheckUserExist.rejected, (state) => {
        state.emailExist = true;
      })
      .addCase(postCreateUserAccount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postCreateUserAccount.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(postCreateUserAccount.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postCreateCompanyAccount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postCreateCompanyAccount.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(postCreateCompanyAccount.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  updateConfirmPassword,
  updateEmail,
  updateExperience,
  updateField,
  updateName,
  updatePassword,
  updateProfileImage,
  updateTechnologies,
  updateImagePreviewUrl,
  updateIsLoading,
  clearFormData,
  updateEmailExist,
  updateJobFunctions,
} = signUpSlice.actions;

export default signUpSlice.reducer;
