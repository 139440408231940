import { useSelector } from 'react-redux';
import { RootState } from '../Redux/store';
import { resources } from '../resources/resources';

function useResources(): typeof resources.en {
  const SelectedLanguage = useSelector((state: RootState) => state.language.SelectedLanguage);
  if (SelectedLanguage === 'en-US') return resources.en;
  else return resources.ge;
}

export default useResources;
