import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

// ** style
import classes from './styles.module.sass';

// ** icons
import { ReactComponent as CalendarIcon } from '../../../Assets/Icons/calendar-icon.svg';

// ** hooks
import useResources from '../../../Hooks/useResources';

// ** 3rd party
import { ReactSVG } from 'react-svg';
import CompanyTooltipModal from './companyTooltipModal/CompanyTooltipModal';
import { format } from 'date-fns';

const CompanyTooltip = ({
  verified,
  verifiedDate,
  noTooltip,
  companyPanel,
}: {
  verified: boolean;
  verifiedDate?: string;
  noTooltip?: boolean;
  companyPanel?: boolean;
}) => {
  const resources = useResources();
  const [openModal, setOpenModal] = useState(false);

  let tooltipSubtitle;
  let title;

  if (verified) {
    tooltipSubtitle = resources.Companies.AboutCompanyVerified;
    title = resources.Companies.AboutCompanyVerified;
  } else if (companyPanel) {
    tooltipSubtitle = resources.Companies.thisAccountUnverified;
    title = resources.Companies.notVerified;
  } else {
    tooltipSubtitle = resources.Companies.AboutCompanyUnverified;
    title = resources.Companies.AboutCompanyUnverified;
  }

  return (
    <div className={classes.CompanyTooltip}>
      <ReactSVG
        src='https://softwaregecdn.azureedge.net/websitefiles/icons/circle-check-solid.svg'
        className={`${!verified && classes.notVerified}`}
        onClick={(e) => {
          e.preventDefault();
          setOpenModal(true);
        }}
      />
      {!noTooltip && (
        <div className={classes.tooltip}>
          <div className={classes.tooltipWrapper}>
            <h5>{title}</h5>
            <span>
              <ReactSVG
                src='https://softwaregecdn.azureedge.net/websitefiles/icons/circle-check-solid.svg'
                className={`${!verified && classes.notVerified}`}
              />
              <p>
                {tooltipSubtitle}
                <Link to='/about'>{resources.Reusable.learnMore}</Link>
              </p>
            </span>
            {verified && verifiedDate && (
              <span>
                <CalendarIcon />
                <p>
                  {resources.Companies.verifiedSince}
                  <p>{format(new Date(verifiedDate), 'MMMM yyyy')}</p>
                </p>
              </span>
            )}
          </div>
        </div>
      )}
      {!noTooltip && openModal && <CompanyTooltipModal verified={verified} onClose={() => setOpenModal(false)} />}
    </div>
  );
};

export default CompanyTooltip;
