import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import Button from '../../../Components/UI/ReusableComponents/Button/Button';
import classNames from '../styles.module.sass';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../Redux/store';
import appInsights from '../../../AppInsights/AppInsights';
import {
  getCheckUserExist,
  updateConfirmPassword,
  updateEmail,
  updateEmailExist,
  updateName,
  updatePassword,
} from '../../../Redux/slices/signUpSlice';
import { toast } from 'react-toastify';
import useResources from '../../../Hooks/useResources';
import { CustomExceptionLog } from '../../../AppInsights/CustomExceptionLog';
import FormInputFieldValidation from '../../../Components/UI/ReusableComponents/FormInputFieldValidation/FormInputFieldValidation';

interface Values {
  readonly name: string;
  readonly email: string;
  readonly password: string;
  readonly confirmPassword: string;
}

interface Props {
  readonly profileType: 'personal' | 'business';
  readonly step: number;
  readonly setStep: React.Dispatch<React.SetStateAction<number>>;
}

const PersonalForm = ({ setStep, profileType }: Props) => {
  const { formData, emailExist } = useSelector((state: RootState) => state.signUp);
  const { email } = useSelector((state: RootState) => state.auth);
  const resources = useResources();
  const dispatch = useAppDispatch();

  const nameValidation =
    profileType === 'business'
      ? Yup.string()
          .matches(/.*[^ ].*/, resources.SignUp.CompanyNameError)
          .matches(/^[\sa-zA-Z0-9._#\\|$@()~„“!%#^&{}*+=><,\][./?'":;`-]+$/i, resources.SignUp.CompanyNameLanguageError)
          .min(2, resources.SignUp.CompanyNameMinLengthError)
          .max(50, resources.SignUp.CompanyNameMaxLengthError)
          .required(resources.SignUp.CompanyNameError)
      : Yup.string()
          .matches(/^((?:[a-zA-Z\u10A0-\u10FF]+\s+)+[a-zA-Z\u10A0-\u10FF]+\s*)$/, resources.SignUp.fullNameError)
          .min(4, resources.SignUp.fullNameMinLengthError)
          .max(50, resources.SignUp.fullNameMaxLengthError)
          .required(resources.SignUp.fullNameLanguageError);

  const FormSchema = Yup.object().shape({
    name: nameValidation,
    email: Yup.string().email(resources.SignUp.emailWrongFormat).required(resources.SignUp.emailRequired),
    password: Yup.string()
      .required(resources.SignUp.passwordRequired)
      .matches(/[a-z]/)
      .matches(/[A-Z]/)
      .matches(/[0-9]/)
      .matches(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/)
      .min(8)
      .max(16),
  });

  const initialValues: Values = {
    name: formData.Name ?? '',
    email: formData.Email ?? '',
    password: '',
    confirmPassword: '',
  };

  const handleSubmit = (values: Values, { setErrors }: FormikHelpers<Values>) => {
    dispatch(getCheckUserExist({ args: { email: values.email } }))
      .unwrap()
      .then(() => {
        // TODO: needs popup if Email already exist
        if (emailExist) {
          appInsights.trackException({
            exception: new CustomExceptionLog(
              'Email already exists',
              `mail: ${email}, error: ${JSON.stringify(values.email)}`
            ),
          });
          return;
        }

        dispatch(updateName(values.name.trim()));
        dispatch(updateEmail(values.email));
        dispatch(updatePassword(values.password));
        dispatch(updateConfirmPassword(values.password));
        if (profileType === 'personal') {
          setStep(2);
        } else {
          setStep(3);
        }
      })
      .catch((err) => {
        setErrors({ email: err.Email[0] });
        appInsights.trackException({
          exception: new CustomExceptionLog('Incorrect Email', `mail: ${email}, error: ${err.Email[0]}`),
        });
      });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={FormSchema}
        onSubmit={handleSubmit}
        validateOnBlur={true}
        enableReinitialize
      >
        {({ errors, touched, values }) => (
          <Form className={classNames.signUpForm}>
            <FormInputFieldValidation
              labelName={profileType === 'business' ? resources.SignUp.companyName : resources.SignUp.fullName}
              valueName='name'
              required
              value={values.name}
              error={touched.name ? errors.name : ''}
              touched={touched.name != null && touched.name}
              autoComplete='new-name'
            />

            <FormInputFieldValidation
              labelName={resources.Reusable.email}
              valueName='email'
              required
              value={values.email}
              error={touched.email ? errors.email : ''}
              touched={touched.email != null && touched.email}
              autoComplete='new-email'
              onKeyUp={() => dispatch(updateEmailExist(false))}
            />

            <FormInputFieldValidation
              labelName={resources.Reusable.password}
              valueName='password'
              required
              type='password'
              value={values.password}
              error={touched.password ? errors.password : ''}
              touched={touched.password != null && touched.password}
              autoComplete='new-password'
              helperText={resources.SignUp.passwordRequirementText}
            />

            <Button className={classNames.nextStep} type='submit'>
              {resources.SignUp.nextStep}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PersonalForm;
