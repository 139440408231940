// ** styles
import classes from './styles.module.sass';

const Radio = ({
  checked,
  setChecked,
  name,
  id,
}: {
  checked: boolean;
  setChecked?(): void;
  name: string;
  id?: string;
}) => {
  return (
    <div className={classes.option} onClick={setChecked}>
      <span className={classes.radio} style={{ background: checked ? '#3E66F8' : '#EFF3F5' }}>
        <img
          src={'https://softwaregecdn.azureedge.net/websitefiles/icons/checkIcon.svg'}
          className={classes.check}
          style={{ display: checked ? 'block' : 'none' }}
        />
      </span>

      <input
        type='radio'
        name='teamSize'
        id={id || name}
        readOnly={!setChecked}
        checked={true}
        className={classes.input}
        style={{ background: checked ? 'red' : 'blue' }}
      />
      <label htmlFor={name} className={classes.label}>
        {name}
      </label>
    </div>
  );
};

export default Radio;
