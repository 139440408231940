import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import * as Api from "../../Api";

interface RecoverUserPasswordSliceState {
  readonly isLoading: boolean;
}

const recoverUserPasswordSliceState: RecoverUserPasswordSliceState = {
  isLoading: false,
};

export const postRecoverUserPassword = createAsyncThunk<
  Api.ResponseRecoveryPasswordStatusType,
  Api.postRecoverUserPasswordParams
>(
  "account/RecoverUserPassword",
  async (params: Api.postRecoverUserPasswordParams, thunkApi) => {
    try {
      const response = await Api.postRecoverUserPasswordRequest(params);
      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }

      throw error;
    }
  }
);

export const recoverUserPasswordSlice = createSlice({
  name: "recoverUserPasswordSlice",
  initialState: recoverUserPasswordSliceState,
  reducers: {
    updateIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postRecoverUserPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postRecoverUserPassword.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(postRecoverUserPassword.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { updateIsLoading } = recoverUserPasswordSlice.actions;

export default recoverUserPasswordSlice.reducer;
