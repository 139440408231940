import { useEffect, useState, useRef } from 'react';
import ProfileTypeSwitcher from './components/ProfileTypeSwitcher';
import SignUpSteps from './components/SignUpSteps';
import ExperiencePage from './Tabs/ExperienceTab';
import PersonalTab from './Tabs/PersonalTab';
import ProfileTab from './Tabs/ProfileTab';
import classNames from './styles.module.sass';
import { useDispatch } from 'react-redux';
import { clearFormData, getAllDictionaries } from '../../Redux/slices/signUpSlice';
import useResources from '../../Hooks/useResources';
import { useLocation, useSearchParams } from 'react-router-dom';

interface Props {
  readonly isOnlyBusiness?: boolean;
}

const SignUpPage = ({ isOnlyBusiness = false }: Props) => {
  const [step, setStep] = useState(1);
  const [profileType, setProfileType] = useState<'personal' | 'business'>(isOnlyBusiness ? 'business' : 'personal');
  const contentHeaderRef = useRef<HTMLHeadingElement>(null);
  const resources = useResources();
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearFormData());
    dispatch(getAllDictionaries());
    if (contentHeaderRef.current) contentHeaderRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [dispatch]);

  useEffect(() => {
    if (searchParams.has('business')) setProfileType('business');
  }, [searchParams]);

  return (
    <div className={`${classNames.container}  ${isOnlyBusiness && classNames.container_business}`}>
      <h2 ref={contentHeaderRef} className={classNames.title}>
        {isOnlyBusiness ? 'Register today!' : resources.Reusable.signUp}
      </h2>
      <h3 className={classNames.subTitle}>{resources.SignUp.pleaseFollowSteps}</h3>
      <SignUpSteps step={step} profileType={profileType} />
      {step === 1 && !isOnlyBusiness && (
        <ProfileTypeSwitcher profileType={profileType} setProfileType={setProfileType} />
      )}
      {step === 1 && <PersonalTab step={step} setStep={setStep} profileType={profileType} />}
      {step === 2 && profileType === 'personal' && <ExperiencePage step={step} setStep={setStep} />}
      {step === 3 && <ProfileTab step={step} setStep={setStep} profileType={profileType} />}
    </div>
  );
};

export default SignUpPage;
