import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { UserTypeEnum } from './Api';
import './App.sass';
import 'react-datepicker/dist/react-datepicker.css';
import CookiesModal from './Components/UI/CookiesModal/CookiesModal';
import Footer from './Components/UI/Footer/Footer';
import Header from './Components/UI/Header/Header';
import BreadCrumbs from './Components/UI/ReusableComponents/BreadCrumbs/BreadCrumbs';
import ReactHelmet from './Components/UI/ReusableComponents/ReactHelmet/ReactHelmet';
import useInterceptors from './Hooks/useInterceptors';
import { checkUserInfoStorage, getGetUserInfo, getViewAs, logIn, updateUserInfo } from './Redux/slices/authSlice';
import { updateProfileProgress } from './Redux/slices/companyPanelSlice';
import { setPrevLocation } from './Redux/slices/homeSlice';
import { getSeoDetails } from './Redux/slices/seoDetailSlice';
import { RootState, useAppDispatch } from './Redux/store';
import Routing from './Routing/Routing';
import Partners from './Pages/Partners/Partners';
import { useNavigate } from 'react-router-dom';
import { changeLanguage, getLanguage } from './Redux/slices/languageSlice';

export default function App() {
  const { SelectedLanguage } = useSelector((state: RootState) => state.language);
  const { viewAs } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (SelectedLanguage && SelectedLanguage === 'en-US') {
      // @ts-ignore
      import('./Assets/sass/_fonts.css');
    } else {
      // @ts-ignore
      import('./Assets/sass/_geoFonts.css');
    }
  }, [SelectedLanguage]);

  useEffect(() => {
    const setUserLanguage = () => {
      if (!SelectedLanguage) {
        dispatch(getLanguage())
          .unwrap()
          .then((payload) => {
            dispatch(changeLanguage(payload === 'EN' ? 'en-US' : 'ka-GE'));
          });
      }
    };
    setUserLanguage();
  }, []);

  useInterceptors();

  const { isLoggedIn, userLoaded } = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const googleTag1 = process.env.REACT_APP_GOOGLE_TAG_MANAGER;
  const googleTag2 = process.env.REACT_APP_GOOGLE_TAG_MANAGER_2;

  useEffect(() => {
    if (googleTag1 && googleTag2) {
      const firstScript = document.createElement('script');
      firstScript.setAttribute('async', '');
      firstScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${googleTag1}`);

      const secondScript = document.createElement('script');
      secondScript.innerHTML = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${googleTag1}'); `;

      const thirdScript = document.createElement('script');
      thirdScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${googleTag2}');`;

      document.head.appendChild(firstScript);
      document.head.appendChild(secondScript);
      document.head.appendChild(thirdScript);
    }
  }, []);

  useEffect(() => {
    dispatch(checkUserInfoStorage());
    dispatch(getSeoDetails());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setPrevLocation(location.pathname));
  }, [location]);

  useEffect(() => {
    if (!location.search.includes('tokenId')) return;
    const id = location.search.split('tokenId=')[1];
    viewAsAdmin(id);
  }, []);

  const viewAsAdmin = async (id: string) => {
    const token = await dispatch(getViewAs(id)).unwrap();

    localStorage.setItem('token', token.data);
    if (viewAs) {
      localStorage.setItem('viewAs', 'true');
    }

    const userInfo = await dispatch(getGetUserInfo()).unwrap();
    await dispatch(logIn({ token: token.data, userInfo, error: '', errorTypes: [], success: true }));
    if (token.goToProfile) {
      navigate('/userpanel/generalinformation');
      return;
    }
    navigate('/');
  };

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getGetUserInfo())
        .unwrap()
        .then((payload) => {
          dispatch(updateUserInfo(payload));
          if (payload.userType === UserTypeEnum.Company) {
            dispatch(updateProfileProgress(payload.profileProgress));
          }
        });
    }
  }, [isLoggedIn, dispatch]);

  const showPartnersPage = location.pathname === '/partners';

  if (!SelectedLanguage) return null;

  if (showPartnersPage) return <Partners />;

  return userLoaded ? (
    <div className='app'>
      <ReactHelmet />

      <div id='scroll'></div>
      <Header />
      <BreadCrumbs />
      <Routing />

      <Footer />
      <CookiesModal />

      <ToastContainer />
    </div>
  ) : (
    <></>
  );
}
