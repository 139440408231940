import React from 'react'
import ExperienceForm from '../components/ExperienceForm'

interface Props {
  readonly step: number
  readonly setStep: React.Dispatch<React.SetStateAction<number>>
}

const ExperiencePage = ({ step, setStep }: Props) => {

  return (
    <ExperienceForm step={step} setStep={setStep} />
  )
}

export default ExperiencePage




  