import { useSelector } from 'react-redux';
import { RootState } from '../../Redux/store';

interface Props {
  // eslint-disable-next-line
  valueEN: any;
  // eslint-disable-next-line
  valueGE: any;
}

function Language(props: Props) {
  const SelectedLanguage = useSelector((state: RootState) => state.language.SelectedLanguage);

  if (SelectedLanguage === 'en-US') return props.valueEN;
  else return props.valueGE;
}

export default Language;
