import React from 'react';
import EditButton from '../../../../Components/UI/ReusableComponents/EditButton/EditButton';
import InfoField from '../../../../Components/UI/ReusableComponents/InfoField/InfoField';
import classes from './styles.module.sass';
import { ReactComponent as GooglePlayIcon } from '../../../../Assets/Icons/google-play-icon.svg';
import { ReactComponent as TwitterIcon } from '../../../../Assets/Icons/twitter-icon.svg';
import { ReactComponent as LinkedInIcon } from '../../../../Assets/Icons/linkedin-in-icon.svg';
import { ReactComponent as FacebookIcon } from '../../../../Assets/Icons/facebook-icon.svg';
import { ReactComponent as PhoneIcon } from '../../../../Assets/Icons/phone-icon.svg';
import { ReactComponent as EmailIcon } from '../../../../Assets/Icons/email-icon.svg';
import { ReactComponent as WebsiteIcon } from '../../../../Assets/Icons/website-icon.svg';
import { ReactComponent as LocationIcon } from '../../../../Assets/Icons/locationIcon.svg';
import { ReactComponent as LinkIcon } from '../../../../Assets/Icons/link-icon.svg';
import { ReactComponent as TiktokIcon } from '../../../../Assets/Icons/tiktok.svg';
import SocialInfoWrapper from './SocialInfoWrapper';
import { KeyFacts } from '../../types';
import Language from '../../../../Components/Helpers/Language';
import useResources from '../../../../Hooks/useResources';

interface Props {
  keyFacts: KeyFacts;
  canEdit: boolean;
  setShowInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function ContactInfo({ keyFacts, setShowInfoModal, canEdit }: Props) {
  const resources = useResources();

  const getCorrectUrl = (link: string) => {
    if (!link) return link;
    if (!link.match(/^[a-zA-Z]+:\/\//)) {
      return 'http://' + link;
    } else {
      return link;
    }
  };

  const phoneNumber = keyFacts?.phoneNumber ? `${keyFacts.countryCode || ''}${keyFacts?.phoneNumber}` : null;

  return (
    <div className={classes.contactInfoContainer}>
      {canEdit && (
        <div className={classes.editButton}>
          <EditButton onClick={() => setShowInfoModal(true)} />
        </div>
      )}
      <div className={classes.contactInfo}>
        <SocialInfoWrapper icon={<PhoneIcon />}>
          <InfoField
            title={resources.Companies.PhoneNumber}
            subText={phoneNumber || '---'}
            isPhoneNumber={!!keyFacts?.phoneNumber}
          />
        </SocialInfoWrapper>
        <SocialInfoWrapper icon={<EmailIcon />}>
          <InfoField title={resources.Companies.Email} subText={keyFacts?.email || '---'} />
        </SocialInfoWrapper>
        <SocialInfoWrapper icon={<WebsiteIcon />}>
          <InfoField title={resources.Companies.Website} subText={''}>
            <a
              target='_blank'
              rel='noopener noreferrer'
              className={classes.directionsLink}
              href={getCorrectUrl(keyFacts?.website)}
            >
              {keyFacts?.website}
            </a>
          </InfoField>
        </SocialInfoWrapper>
        {keyFacts?.georgiaOffice && (
          <SocialInfoWrapper icon={<LocationIcon />}>
            <InfoField
              title={resources.Companies.AboutCompanyKeyFactsGeorgiaOffice}
              subText={keyFacts.georgiaOffice || '---'}
            >
              <>
                {keyFacts.googleMapUrl && (
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    className={classes.directionsLink}
                    href={getCorrectUrl(keyFacts.googleMapUrl)}
                  >
                    {resources.Companies.GetDirections} <LinkIcon />
                  </a>
                )}
              </>
            </InfoField>
          </SocialInfoWrapper>
        )}
        <SocialInfoWrapper icon={<LocationIcon />}>
          <InfoField
            title={resources.Companies.Headquarters}
            subText={Language({ valueEN: keyFacts?.headQuarter, valueGE: keyFacts?.headQuarterGe }) || '---'}
          >
            <>
              {keyFacts?.headQuarterGoogleMapUrl && (
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  className={classes.directionsLink}
                  href={getCorrectUrl(keyFacts?.headQuarterGoogleMapUrl)}
                >
                  {resources.Companies.GetDirections} <LinkIcon />
                </a>
              )}
            </>
          </InfoField>
        </SocialInfoWrapper>
        <div className={classes.socialLinksContainer}>
          {keyFacts?.facebookUrl && (
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={getCorrectUrl(keyFacts.facebookUrl || '/')}
              className={classes.socialLink}
            >
              <FacebookIcon />
            </a>
          )}
          {keyFacts?.linkedInUrl && (
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={getCorrectUrl(keyFacts.linkedInUrl || '/')}
              className={classes.socialLink}
            >
              <LinkedInIcon />
            </a>
          )}
          {keyFacts?.twitterUrl && (
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={getCorrectUrl(keyFacts.twitterUrl || '/')}
              className={classes.socialLink}
            >
              <TwitterIcon />
            </a>
          )}
          {keyFacts?.youTubeUrl && (
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={getCorrectUrl(keyFacts.youTubeUrl || '/')}
              className={classes.socialLink}
            >
              <GooglePlayIcon />
            </a>
          )}
          {keyFacts?.tikTokUrl && (
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={getCorrectUrl(keyFacts.tikTokUrl || '/')}
              className={classes.socialLink}
            >
              <TiktokIcon />
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default ContactInfo;
