import React, { SVGProps } from 'react'

const ArrowDownIcon = ({ ...props }: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.081" height="9.08"
            viewBox="0 0 15.081 9.08"
            {...props}
        >
            <path
                id="Icon_ionic-ios-arrow-down"
                data-name="Icon ionic-ios-arrow-down"
                d="M13.186,16.835l5.291-5.295a1,1,0,0,1,1.412,0,1.008,1.008,0,0,1,0,1.416l-5.995,6a1,1,0,0,1-1.379.029L6.479,12.961a1,1,0,0,1,1.412-1.416Z"
                transform="translate(-5.688 -10.746)"
                strokeWidth="1"
            />
        </svg>
    )
}

export default ArrowDownIcon