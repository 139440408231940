import React from 'react';
import classes from "./styles.module.sass"

export default function Button(props: buttonProps) {
    return (
        <button 
            style={props.style}
            className={`${classes.button} ${props.className}`} 
            type={props.type}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.children}
        </button>
    )
}

interface buttonProps {
    children: React.ReactNode;
    onClick?(): void;
    type: "button" | "submit";
    disabled: boolean;
    className?: string;
    style?: React.CSSProperties;
}

Button.defaultProps = {
    type: "button",
    disabled: false,
}
