import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classes from './styles.module.sass';
import LanguageSwitcher from '../ReusableComponents/LanguageSwitcher/LanguageSwitcher';
import useResources from '../../../Hooks/useResources';
import { ReactComponent as FacebookIcon } from '../../../Assets/Icons/facebook-f.svg';
import { ReactComponent as LinkedinIcon } from '../../../Assets/Icons/linkedin-in-icon.svg';
import TechwaveIcon from '../../../Assets/Images/techwave-icon.png';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Redux/store';

const Footer: FC = () => {
  const resources = useResources();
  const location = useLocation();
  const { SelectedLanguage } = useSelector((state: RootState) => state.language);
  const showLangSwitch = location.pathname !== '/partners';

  const scrollTop = () => {
    const app = document.querySelector('.app') as HTMLDivElement;
    app.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <footer>
      <div className={`${classes.content} base_container`}>
        <div className={classes.contentWrapper}>
          <div className={classes.products}>
            <div>
              <h4>{resources.Footer.products}</h4>
              <Link onClick={scrollTop} to='/companies'>
                {resources.Reusable.companies}
              </Link>
              <Link onClick={scrollTop} to='/vacancies'>
                {resources.Reusable.vacancies}
              </Link>
              <Link onClick={scrollTop} to='/news'>
                {resources.Footer.techNews}
              </Link>
              <Link onClick={scrollTop} to='/compare'>
                {resources.Compare.compare}
              </Link>
              {/* <Link to=''>{resources.Footer.projects}</Link> */}
            </div>
          </div>
          <div className={classes.aboutUs}>
            <div>
              <h4>{resources.Header.aboutUs}</h4>
              <Link onClick={scrollTop} to='/about'>
                {resources.AboutUs.aboutCompany}
              </Link>
              <Link onClick={scrollTop} to='/faq'>
                {resources.Footer.faq}
              </Link>
              {/* <Link to=''>{resources.Footer.helpCenter}</Link> */}
            </div>
          </div>
          <div className={classes.resources}>
            <div>
              <h4>{resources.Footer.resources}</h4>
              {/* <Link to=''>{resources.Footer.pricing}</Link> */}
              <Link onClick={scrollTop} to='/privacyPolicy'>
                {resources.Reusable.privacyPolicy}
              </Link>
              <Link onClick={scrollTop} to='/terms-of-use'>
                {resources.Reusable.termsOfUser}
              </Link>
            </div>
          </div>
          <div className={classes.contactUs}>
            <div>
              <h4>{resources.Footer.contactInfo}</h4>
              <a href='tel:+9950322309309'>
                {resources.Footer.hotline}: <p>(+995) 032 2 309 309</p>
              </a>
              <a href='mailto:info@techwave.ge'>
                {resources.Reusable.email}: <p>info@techwave.ge</p>
              </a>
              <div className={classes.socialIcons}>
                <a href='https://www.facebook.com/techwave.ge' rel='noreferrer' target='_blank'>
                  <FacebookIcon />
                </a>
                <a href='https://www.linkedin.com/company/techwave-ge/' rel='noreferrer' target='_blank'>
                  <LinkedinIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.mobileContactUs}>
          <h4>{resources.Footer.contactInfo}</h4>
          <a href='tel:+9950322309309'>
            {resources.Footer.hotline}: <p>(+995) 032 2 309 309</p>
          </a>
          <a href='mailto:info@techwave.ge'>
            {resources.Reusable.email}: <p>info@techwave.ge</p>
          </a>
          <div className={classes.socialIcons}>
            <a href='https://www.facebook.com/techwave.ge' rel='noreferrer' target='_blank'>
              <FacebookIcon />
            </a>
            <a href='https://www.linkedin.com/company/techwave-ge/' rel='noreferrer' target='_blank'>
              <LinkedinIcon />
            </a>
          </div>
        </div>
      </div>
      <div className={classes.copyRight}>
        <div className={`${classes.copyRightWrapper} base_container`}>
          <div className={classes.mobile}>
            <div className={classes.copyrightContent}>
              <Link onClick={scrollTop} to='/' className={classes.logo}>
                <img src={TechwaveIcon} alt='techwave logo' />
              </Link>
              <div className={classes.copyRightText}>
                © {new Date().getFullYear()} Techwave.ge ®. {resources.Footer.allRightReserved}.
              </div>
              <div className={classes.langWrapper}>
                <div className={`${classes.langContainer} ${SelectedLanguage === 'ka-GE' && classes.georgian}`}>
                  {showLangSwitch && (
                    <>
                      <LanguageSwitcher footer /> <p>{resources.Footer.changeLanguage}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className={classes.copyRightText}>
              © {new Date().getFullYear()} Techwave.ge ®. {resources.Footer.allRightReserved}.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
