import axios from 'axios';
import { useEffect } from 'react';
import { postRefreshToken } from '../Redux/slices/signInSlice';
import { useAppDispatch } from '../Redux/store';
import appInsights from '../AppInsights/AppInsights';
import { CustomExceptionLog } from '../AppInsights/CustomExceptionLog';

export default function useInterceptors() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        if (config.headers !== undefined) {
          const token = localStorage.getItem('token');
          if (token) {
            config.headers.Authorization = `bearer ${localStorage.getItem('token')}`;
          }
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        // const originalRequest = error.config;
        const oldToken = localStorage.getItem('token');
        const status = error.response?.status;

        if (
          status == 401 &&
          oldToken
          // && !originalRequest._retry
        ) {
          // originalRequest._retry = true;
          localStorage.removeItem('token');

          dispatch(
            postRefreshToken({
              body: {
                Token: oldToken,
              },
            })
          )
            .unwrap()
            .then((payload) => {
              if (payload) {
                localStorage.setItem('token', payload.token);
              }
            })
            .catch(() => {
              appInsights.trackException({
                exception: new CustomExceptionLog(
                  'Error occured during refreshing the token',
                  `user_Info: Email: ${localStorage.getItem('Email')}`
                ),
              });
              localStorage.removeItem('token');
            })
            .finally(() => {
              location.reload();
            });
        }
        return Promise.reject(error);
      }
    );
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
    };
  }, [dispatch]);
}
