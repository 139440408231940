import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import * as Api from '../../Api';
import { ResponseModel } from '../../Api';
export interface SeoState {
  isLoading: boolean;
  seoDetails: Api.SeoDetailsType | null;
}

const initialState: SeoState = {
  isLoading: false,
  seoDetails: null,
};

export const getSeoDetails = createAsyncThunk<ResponseModel<Api.SeoDetailsType>>('account/getSeoDetails', async (_, thunkApi) => {
  try {
    const response = await Api.getSeoDetailsRequest();
    return response.data;
  } catch (err) {
    const error = err;
    if (error) {
      return thunkApi.rejectWithValue((error as AxiosError).response?.data);
    }

    throw err;
  }
});

export const seoDetailSlice = createSlice({
  name: 'seo',
  initialState,
  reducers: {
    updateIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSeoDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSeoDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.seoDetails = action.payload.data;
      })
      .addCase(getSeoDetails.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { updateIsLoading } = seoDetailSlice.actions;
export default seoDetailSlice.reducer;
