import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import * as Api from "../../Api";

interface SendPasswordRecoveryState {
  readonly isLoading: boolean;
}

const sendPasswordRecoveryState: SendPasswordRecoveryState = {
  isLoading: false,
};

export const postSendPasswordRecovery = createAsyncThunk<
  Api.ResponseStatusType,
  Api.postSendPasswordRecoveryParams
>(
  "account/postSendPasswordRecovery",
  async (params: Api.postSendPasswordRecoveryParams, thunkApi) => {
    try {
      const response = await Api.postSendPasswordRecoveryRequest(params);

      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }

      throw error;
    }
  }
);

export const sendPasswordRecoverySlice = createSlice({
  name: "sendPasswordRecovery",
  initialState: sendPasswordRecoveryState,
  reducers: {
    updateIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postSendPasswordRecovery.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postSendPasswordRecovery.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(postSendPasswordRecovery.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { updateIsLoading } = sendPasswordRecoverySlice.actions;

export default sendPasswordRecoverySlice.reducer;
