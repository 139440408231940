import * as Api from '../../Api';

const tagsByCategory = (
  savedTags: string[],
  dictionaryList: Api.DictionaryType['dictionaryList'],
  selectedLanguage?: string
) => {
  const sorted: {
    [key: string]: Array<{
      readonly dictionaryName: string;
      readonly id: string;
      readonly name: string;
    }>;
  } = {};

  savedTags?.forEach((tag) => {
    const category = dictionaryList.find((i) => i.childDictionaries.find((item) => item.id === tag));
    const categoryName = selectedLanguage === 'ka-GE' ? category?.nameGE : category?.name;
    //@ts-ignore
    if (category && sorted[categoryName]) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      //@ts-ignore
      sorted[categoryName] = [...sorted[categoryName], category.childDictionaries.find((item) => item.id === tag)!];
    }
    //@ts-ignore
    if (category && !sorted[categoryName]) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      //@ts-ignore
      sorted[categoryName] = [category.childDictionaries.find((item) => item.id === tag)!];
    }
    //@ts-ignore
    if (category != null && sorted[categoryName] == null) {
      //@ts-ignore
      sorted[categoryName] = [];
    }
  });

  return sorted;
};

export default tagsByCategory;
