import React, { SVGProps } from 'react'

const TimesCircleIcon = ({ ...props }: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            {...props}
        >
            <path
                id="Icon_material-cancel"
                data-name="Icon material-cancel"
                d="M13,3A10,10,0,1,0,23,13,9.991,9.991,0,0,0,13,3Zm5,13.59L16.59,18,13,14.41,9.41,18,8,16.59,11.59,13,8,9.41,9.41,8,13,11.59,16.59,8,18,9.41,14.41,13Z"
                transform="translate(-3 -3)"
            />
        </svg>
    )
}

export default TimesCircleIcon