import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import classes from './styles.module.sass';
import FormInputFieldValidation from '../../../../Components/UI/ReusableComponents/FormInputFieldValidation/FormInputFieldValidation';
import Modal from '../../../../Components/UI/ReusableComponents/Modal/Modal';
import useResources from '../../../../Hooks/useResources';
import Button from '../../../../Components/UI/ReusableComponents/Button/Button';
import { postUpdateCompanyAboutRequest } from '../../../../Api';
import Tabs from '../../../../Components/UI/ReusableComponents/Tabs/Tabs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../Redux/store';

interface Props {
  about: string;
  aboutGe: string;
  setAboutCompanyModal: React.Dispatch<React.SetStateAction<boolean>>;
  aboutCompanyModal: boolean;
  refetchData: () => void;
}

const textLimit = 1000;

function AboutCompanyForm({ about, aboutGe, aboutCompanyModal, setAboutCompanyModal, refetchData }: Props) {
  const resources = useResources();
  const selectedLanguage = useSelector((state: RootState) => state.language.SelectedLanguage);
  const [activeTab, setActiveTab] = useState<string | number>('English');

  const handleSubmit = (data: { aboutEn: string; aboutGe: string }) => {
    postUpdateCompanyAboutRequest(data.aboutEn, data.aboutGe).then(() => {
      setAboutCompanyModal(false);
      refetchData();
    });
  };

  const tabs = [
    { name: resources.Reusable.english, value: 'English' },
    { name: resources.Reusable.georgian, value: 'Georgian' },
  ];

  return (
    <Modal
      action={resources.Companies.AboutCompanyHashAbout}
      onClick={() => setAboutCompanyModal(false)}
      visible={aboutCompanyModal}
      hideDivider
    >
      <Formik initialValues={{ aboutEn: about, aboutGe: aboutGe }} onSubmit={handleSubmit} enableReinitialize>
        {({ errors, touched, values }) => (
          <Form className={classes.infoForm}>
            <Tabs activeTab={activeTab} onChange={setActiveTab} tabs={tabs} />
            {activeTab === 'English' ? (
              <FormInputFieldValidation
                valueName='aboutEn'
                value={values.aboutEn}
                error={touched.aboutEn ? errors.aboutEn : ''}
                touched={touched.aboutEn != null && touched.aboutEn}
                autoComplete='aboutEn'
                type='textarea'
                component='textarea'
                className={classes.textarea}
                maxLength={textLimit}
              />
            ) : (
              <FormInputFieldValidation
                valueName='aboutGe'
                value={values.aboutGe}
                error={touched.aboutGe ? errors.aboutGe : ''}
                touched={touched.aboutGe != null && touched.aboutGe}
                autoComplete='aboutGe'
                type='textarea'
                component='textarea'
                className={classes.textarea}
                maxLength={textLimit}
              />
            )}

            <div className={classes.leftCharacters}>
              {selectedLanguage === 'ka-GE'
                ? `${resources.Companies.charactersAdd} ${
                    textLimit - (activeTab === 'English' ? values.aboutEn.length : values.aboutGe.length)
                  } ${resources.Companies.charactersSymbol}`
                : `${textLimit - (activeTab === 'English' ? values.aboutEn.length : values.aboutGe.length)} ${
                    resources.Companies.charactersLeft
                  }`}
            </div>

            <Button className={classes.submitButton} type='submit'>
              {resources.Reusable.save}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default AboutCompanyForm;
