import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { AuthenticationType, ResponseUpdateProfileImageStatusType, UserInfoResultType, UserTypeEnum } from '../../Api';

import * as Api from '../../Api';
export interface AuthState {
  isLoading: boolean;
  isLoggedIn: boolean;
  id: string;
  fullName: string;
  userType: UserTypeEnum;
  profileImageUrl: string;
  smallProfileImageUrl: string;
  expireDate?: Date;
  experience: string;
  field: string;
  tagLine: string;
  teamSize: number;
  userLoaded: boolean;
  isEmailVerified: boolean;
  email: string;
  transformedName: string;
  viewAs: boolean;
  cvUrl: string | null;
  cvFileName: string | null;
}

const initialState: AuthState = {
  isLoading: false,
  isLoggedIn: false,
  fullName: '',
  id: '',
  userType: UserTypeEnum.None,
  profileImageUrl: '',
  smallProfileImageUrl: '',
  experience: '',
  field: '',
  tagLine: '',
  teamSize: 0,
  userLoaded: false,
  isEmailVerified: false,
  email: '',
  transformedName: '',
  viewAs: false,
  cvUrl: '',
  cvFileName: '',
};

export const getGetUserInfo = createAsyncThunk<Api.UserInfoResultType>(
  'account/getGetUserInfo',
  async (_, thunkApi) => {
    try {
      const response = await Api.getGetUserInfoRequest();
      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }

      throw err;
    }
  }
);

export const postReSendVerifyUserEmail = createAsyncThunk('account/reSendVerifyUserEmail', async (_, thunkApi) => {
  try {
    const response = await Api.postReSendVerifyUserEmailRequest();
    return response.data;
  } catch (err) {
    const error = err;
    if (error) {
      return thunkApi.rejectWithValue((error as AxiosError).response?.data);
    }

    throw err;
  }
});

export const getViewAs = createAsyncThunk<Api.ResponseViewAsType, string>('account/ViewAs', async (id: string) => {
  const response = await Api.getViewAsRequest(id);
  return response.data;
});

export const postDeleteCompanyOrTalent = createAsyncThunk<string>('account/deleteCompanyOrTalent', async () => {
  const response = await Api.postDeleteCompanyOrTalentRequest();
  return response.data;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logIn: (state, action: PayloadAction<AuthenticationType | undefined>) => {
      if (action.payload == null) return;

      localStorage.setItem('token', action.payload.token);
      const dataToken = JSON.parse(atob(action.payload.token.split('.')[1]));

      state.id = dataToken.id;
      state.expireDate = dataToken.exp;
      state.viewAs = dataToken.ViewAs === 'true' ? true : false;
      state.isLoggedIn = true;
    },
    checkUserInfoStorage: (state) => {
      if (localStorage.getItem('token') != null && localStorage.getItem('token') !== undefined) {
        const dataToken = JSON.parse(atob((localStorage.getItem('token') as string).split('.')[1]));

        state.id = dataToken.id;
        state.expireDate = dataToken.exp;
        state.viewAs = dataToken.ViewAs === 'true' ? true : false;

        state.isLoggedIn = true;
      }

      state.userLoaded = true;
    },
    updateUserInfo: (state, action: PayloadAction<UserInfoResultType>) => {
      state.fullName = action.payload.name;
      state.tagLine = action.payload.tagLine;
      state.teamSize = action.payload.teamSize;
      state.userType = action.payload.userType;
      state.profileImageUrl = action.payload.profileImageUrl;
      state.smallProfileImageUrl = action.payload.smallProfileImageUrl;
      state.transformedName = action.payload.transformedName;
      state.email = action.payload.email;
      state.cvFileName = action.payload.cvFileName;
      state.cvUrl = action.payload.cvUrl;

      state.field = action.payload.field;
      state.experience = action.payload.experience;

      state.isLoggedIn = true;
    },
    logOut: (state) => {
      axios.defaults.headers.common['Authorization'] = '';
      localStorage.removeItem('token');
      localStorage.removeItem('viewAs');
      state.viewAs = false;
      state.email = '';

      state.isLoggedIn = false;
      state.id = '';
      state.userType = UserTypeEnum.None;
    },
    updateProfileImages: (state, action: PayloadAction<ResponseUpdateProfileImageStatusType>) => {
      state.profileImageUrl = action.payload.profileImageUrl;
      state.smallProfileImageUrl = action.payload.smallProfileImageUrl;
    },
    updateTagline: (state, action: PayloadAction<string>) => {
      state.tagLine = action.payload;
    },
    updateTeamSize: (state, action: PayloadAction<number>) => {
      state.teamSize = action.payload;
    },
    updateUsersExperience: (state, action: PayloadAction<string>) => {
      state.experience = action.payload;
    },
    updateUsersField: (state, action: PayloadAction<string>) => {
      state.field = action.payload;
    },
    updateUsersName: (state, action: PayloadAction<string>) => {
      state.fullName = action.payload;
    },
    updateCV: (state, action: PayloadAction<Api.CvViewRequestParams>) => {
      state.cvFileName = action.payload?.cvFileName;
      state.cvUrl = action.payload?.cvUrl;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGetUserInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGetUserInfo.fulfilled, (state, action) => {
        state.email = action.payload.email;
        state.experience = action.payload.experience;
        state.field = action.payload.field;
        state.fullName = action.payload.name;
        state.isEmailVerified = action.payload.isEmailVerified;
        state.profileImageUrl = action.payload.profileImageUrl;
        state.smallProfileImageUrl = action.payload.smallProfileImageUrl;
        state.tagLine = action.payload.tagLine;
        state.teamSize = action.payload.teamSize;
        state.transformedName = action.payload.transformedName;
        state.userType = action.payload.userType;
        state.cvUrl = action.payload.cvUrl;
        state.cvFileName = action.payload.cvFileName;
        state.userLoaded = true;
        state.isLoading = false;
      })
      .addCase(getGetUserInfo.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postReSendVerifyUserEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postReSendVerifyUserEmail.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(postReSendVerifyUserEmail.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getViewAs.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getViewAs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getViewAs.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(postDeleteCompanyOrTalent.fulfilled, (state) => {
        state.isLoading = false;
        const cookiesModal = localStorage.getItem('cookiesModal');
        localStorage.clear();
        localStorage.setItem('cookiesModal', cookiesModal!);
        window.location.reload();
      })
      .addCase(postDeleteCompanyOrTalent.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postDeleteCompanyOrTalent.pending, (state) => {
        state.isLoading = true;
      });
  },
});

export const {
  logIn,
  checkUserInfoStorage,
  updateUserInfo,
  logOut,
  updateProfileImages,
  updateTagline,
  updateTeamSize,
  updateUsersExperience,
  updateUsersField,
  updateUsersName,
  updateCV,
} = authSlice.actions;
export default authSlice.reducer;
