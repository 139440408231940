import { FC, useMemo } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import classes from './styles.module.sass';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../Redux/store';
import useResources from '../../../../Hooks/useResources';

const VacancyCompanyName = () => {
  const companyName = useSelector((state: RootState) => state.vacancies.vacancyDetails?.company.name);
  return <span>{companyName}</span>;
};

const CompanyPanelName = () => {
  const companyName = useSelector((state: RootState) => state.CompanyPanel.companyPanelInfo.data.name);
  return <span>{companyName}</span>;
};

const CompanyName = () => {
  const companyName = useSelector((state: RootState) => state.companies.companyName);
  return <span>{companyName}</span>;
};

const VacancyName = () => {
  const title = useSelector((state: RootState) => state.vacancies.vacancyDetails?.title);
  const titleGE = useSelector((state: RootState) => state.vacancies.vacancyDetails?.titleGE);
  const { SelectedLanguage } = useSelector((state: RootState) => state.language);
  return <span>{SelectedLanguage === 'ka-GE' ? titleGE : title}</span>;
};

const BlogName = () => {
  const title = useSelector((state: RootState) => state.blog.blogArticleTitle);
  return <span>{title}</span>;
};

const CompareCompanyNames = () => {
  const resources = useResources();
  const { firstCompanyName, secondCompanyName } = useSelector(
    (state: RootState) => state.companies.CompareCompanyNames
  );
  return (
    <span>{`${firstCompanyName || resources.Compare.anyCompany} vs ${
      secondCompanyName || resources.Compare.anyCompany
    }`}</span>
  );
};

const BreadCrumbs: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const resources = useResources();
  const BreadCrumbsRoutes = useMemo(
    () => [
      { path: '/', breadcrumb: resources.BreadCrumbs.home },
      { path: '/about', breadcrumb: resources.BreadCrumbs.aboutUs },
      { path: '/signUp', breadcrumb: resources.Reusable.signUp },
      { path: '/signIn', breadcrumb: resources.Reusable.signIn },
      {
        path: '/CompanyPanel',
        breadcrumb: resources.Reusable.companyPanel,
        props: { pathname: '/companyPanel/generalInformation' },
      },
      { path: '/faq', breadcrumb: resources.AboutUs.faq },
      { path: '/compare', breadcrumb: resources.Compare.compare },
      { path: '/compare/:id', breadcrumb: CompareCompanyNames },
      { path: '/:id', breadcrumb: CompanyName },
      { path: '/userPanel', breadcrumb: resources.Reusable.user, props: { pathname: '/userPanel/generalInformation' } },
      { path: '/userPanel/GeneralInformation', breadcrumb: null },
      { path: '/userPanel/user-subscriptions', breadcrumb: resources.Reusable.mySubscriptions },
      { path: '/passwordRecovery', breadcrumb: resources.Reusable.passwordRecovery },
      { path: '/companies', breadcrumb: resources.Reusable.companies },
      { path: '/companies/:id', breadcrumb: CompanyName },
      { path: '/companies/:id/EditBenefits', breadcrumb: resources.Companies.EditBenefits },
      { path: '/companies/:id/vacancies', breadcrumb: resources.BreadCrumbs.allVacancies },
      { path: '/:id/vacancies', breadcrumb: resources.Reusable.vacancies },
      { path: '/:companyId/vacancies/:id', breadcrumb: VacancyName },
      { path: '/vacancies', breadcrumb: resources.Reusable.vacancies },
      { path: '/vacancies/:id', breadcrumb: VacancyCompanyName },
      { path: '/:id/technologies', breadcrumb: resources.Companies.AboutCompanyHashTechnologies },
      { path: '/:id/benefits', breadcrumb: resources.Companies.AboutCompanyHashBenefits },

      {
        path: '/companyPanel/generalInformation',
        breadcrumb: CompanyPanelName,
        props: { pathname: '/companyPanel/generalInformation' },
      },
      { path: '/companyPanel/generalInformation', breadcrumb: null },
      { path: '/companyPanel/benefits', breadcrumb: resources.Companies.AboutCompanyHashBenefits },
      { path: '/companyPanel/technologies', breadcrumb: resources.Companies.Technologies },
      { path: '/companyPanel/statistics', breadcrumb: resources.Statistics.statistics },
      { path: '/companyPanel/settings', breadcrumb: resources.Reusable.settings },
      { path: '/companyPanel/settings/passwordChange', breadcrumb: resources.Reusable.passwordChange },
      { path: '/companyPanel/vacancies/update/:id', breadcrumb: null },
      { path: '/companyPanel/vacancies/vacancyDetails', breadcrumb: resources.Vacancy.vacancyDetails },
      { path: '/companyPanel/vacancies/active', breadcrumb: resources.Vacancy.activeJobs },
      { path: '/companyPanel/vacancies/ended', breadcrumb: resources.Vacancy.endedJobs },
      { path: '/companyPanel/vacancies/drafts', breadcrumb: resources.Reusable.drafts },
      { path: '/companyPanel/vacancies/create', breadcrumb: resources.Reusable.addVacancies },
      { path: '/companyPanel/passwordChange', breadcrumb: resources.Reusable.passwordChange },
      { path: '/news', breadcrumb: resources.Reusable.blog },
      { path: '/news/:id', breadcrumb: BlogName },
      {
        path: '/userPanel/generalInformation',
        breadcrumb: 'User',
        props: { pathname: '/userPanel/generalInformation' },
      },
      { path: '/userPanel/generalInformation', breadcrumb: null },
      { path: '/userPanel/settings/passwordChange', breadcrumb: resources.Reusable.passwordChange },

      { path: '/information', breadcrumb: null },
      { path: '/information/privacyPolicy', breadcrumb: resources.Reusable.privacyPolicy },
      { path: '/information/terms-of-use', breadcrumb: resources.Reusable.termsOfUser },
      { path: '/information/support', breadcrumb: resources.Reusable.support },
      { path: '/search', breadcrumb: resources.Reusable.search },
    ],
    [resources]
  );

  const breadcrumbs = useBreadcrumbs(BreadCrumbsRoutes, {
    excludePaths: ['/companies/:id/generalInformation', '/no-breadcrumb/for-this-route'],
  });

  if (location.pathname === '/' || location.pathname === '/partners') {
    return null;
  }

  return (
    <div className={classes.component}>
      <div className='base_container'>
        <div className={classes.content}>
          <div className={classes.left_side}>
            <ul className={classes.list}>
              {breadcrumbs.map(({ match, breadcrumb }) => (
                <li key={match.pathname} className={classes.list_item}>
                  <NavLink
                    className={classes.list_link}
                    to={
                      match?.route?.props?.pathname !== undefined
                        ? (match?.route?.props?.pathname as string)
                        : match.pathname
                    }
                  >
                    {breadcrumb}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
          <div className={classes.right_side}>
            <button onClick={() => navigate(-1)} className={classes.back_btn}>
              <FaArrowLeft /> <span className={classes.back_btn_txt}>{resources.Reusable.back}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreadCrumbs;
