import axios from 'axios';
import Cookies from 'js-cookie';
import sha256 from 'js-sha256';

function normalizeAndHash(input) {
  var normalized_input = input.trim().toLowerCase();
  // Hashing the normalized input with SHA 256
  var hashed_input = sha256(normalized_input);
  return hashed_input;
}

const access_token = process.env.REACT_APP_CONVERSION_API_KEY;
const pixel_id = process.env.REACT_APP_PIXEL_ID;
let current_timestamp = Math.floor(new Date() / 1000);

const SocialIntegrations = (componentName, email, data) => {
  const fbp = Cookies.get('_fbp');
  const fbc = Cookies.get('_fbc');
  axios.post(
    `https://graph.facebook.com/v16.0/${pixel_id}/events?access_token=${access_token}`,
    {
      data: [
        {
          event_name: componentName,
          event_time: current_timestamp,
          user_data: {
            em: [normalizeAndHash(email)],
            fbp,
            fbc,
          },
          custom_data: data,
          event_source_url: window.location.pathname,
          action_source: 'website',
        },
      ],
    },
    {
      headers: { Authorization: `access_token ${access_token}` },
    }
  );
};

export default SocialIntegrations;
