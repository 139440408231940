import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import * as Api from '../../Api';

interface AboutUsState {
  readonly aboutUs: Api.AboutUsInfoType;
  readonly isLoading: boolean;
}

const state: AboutUsState = {
  aboutUs: {
    descriptionEN: '',
    descriptionGE: '',
    faqList: [],
    indexPageCountViewModel: {
      activeVacancyCount: 0,
      companyCount: 0,
      userCount: 0,
    },
  },
  isLoading: false,
};

export const getAboutUs = createAsyncThunk<Api.AboutUsInfoType>('aboutUs/getAboutUs', async (_, thunkApi) => {
  try {
    const response = await Api.getAboutUsRequest();
    return response.data;
  } catch (err) {
    const error = err;
    if (error) {
      return thunkApi.rejectWithValue((error as AxiosError).response?.data);
    }

    throw err;
  }
});

export const aboutUsSlice = createSlice({
  name: 'aboutUs',
  initialState: state,
  reducers: {
    updateIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAboutUs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAboutUs.fulfilled, (state, action) => {
        state.isLoading = false;
        console.log(action.payload);
        state.aboutUs = action.payload;
      })
      .addCase(getAboutUs.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { updateIsLoading } = aboutUsSlice.actions;

export default aboutUsSlice.reducer;
