import { FC } from 'react';
import classes from './styles.module.sass';

// import eventSeat from '../../../Assets/Icons/event-seat.svg';
// import moneyOff from '../../../Assets/Icons/money-off.svg';
// import techIcon from '../../../Assets/Icons/tech-icon.svg';
// import peopleIcon from '../../../Assets/Icons/people-icon.svg';
// import sandClock from '../../../Assets/Icons/sand-clock.svg';

interface Props {
  readonly iconName: 'eventSeat' | 'moneyOff' | 'techIcon' | 'peopleIcon' | 'sandClock';
  readonly headerText: string;
  readonly bodyText: string;
}

const Card: FC<Props> = ({ iconName, headerText, bodyText }) => {
  const getIcon = () => {
    switch (iconName) {
      case 'eventSeat':
        return (
          <img className={classes.svg} src={'https://softwaregecdn.azureedge.net/websitefiles/icons/event-seat.svg'} />
        );
      case 'moneyOff':
        return (
          <img className={classes.svg} src={'https://softwaregecdn.azureedge.net/websitefiles/icons/money-off.svg'} />
        );
      case 'techIcon':
        return (
          <img className={classes.svg} src={'https://softwaregecdn.azureedge.net/websitefiles/icons/tech-icon.svg'} />
        );
      case 'peopleIcon':
        return (
          <img className={classes.svg} src={'https://softwaregecdn.azureedge.net/websitefiles/icons/people-icon.svg'} />
        );
      case 'sandClock':
        return (
          <img className={classes.svg} src={'https://softwaregecdn.azureedge.net/websitefiles/icons/sand-clock.svg'} />
        );
      default:
        break;
    }
  };

  return (
    <div className={classes.card}>
      {getIcon()}
      <div>
        <h3 className={classes.header}>{headerText}</h3>
        <p className={classes.p}>{bodyText}</p>
      </div>
    </div>
  );
};

export default Card;
