import { ApplicationInsights } from '@microsoft/applicationinsights-web';
const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_INSIGHTS_CONNECTION,
  },
});
appInsights.loadAppInsights();
appInsights.trackPageView();

export default appInsights;
