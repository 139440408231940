import axios from 'axios';
import { ContentDataType } from './types';

const url = process.env.REACT_APP_API_URL;

export const getContentData = (contentDataType: ContentDataType) => {
  const params = new URLSearchParams([['type', contentDataType.toString()]]);
  return axios.get(`${url}api/BackOfficeStaticData/GetStaticData`, {
    headers: {
      
    },
    params,
  });
};
