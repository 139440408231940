import { CSSProperties, FC, useState, useRef } from 'react';

import { GrFormDown } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage } from '../../../../Redux/slices/languageSlice';
import { RootState } from '../../../../Redux/store';
import classes from './styles.module.sass';
import useOutsideClick from '../../../../Hooks/useOutsideClick';

const LanguageSwitcher: FC<{ style?: CSSProperties; isInsideHeader?: boolean; footer?: boolean }> = (props) => {
  const optionsRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const SelectedLanguage = useSelector((state: RootState) => state.language.SelectedLanguage);
  const dispatch = useDispatch();
  useOutsideClick(optionsRef, () => setOpen(false));

  const changeLangHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, selLang: 'en-US' | 'ka-GE') => {
    if (SelectedLanguage !== selLang) {
      (e.target as HTMLButtonElement).blur();
      dispatch(changeLanguage(selLang));
      setOpen(false);
    }
  };

  return (
    <div className={`${classes.language} ${props.footer && classes.footer}`}>
      <div className={`${classes.languageWrapper} ${open && classes.open}`}>
        <div className={classes.chosenLanguage} onClick={() => setOpen(true)} style={!open ? props.style : {}}>
          {SelectedLanguage === 'ka-GE' && (
            <span>
              GE
              <GrFormDown />
            </span>
          )}
          {SelectedLanguage === 'en-US' && (
            <span>
              EN
              <GrFormDown />
            </span>
          )}
        </div>
        {open && (
          <div className={classes.options} ref={optionsRef}>
            {SelectedLanguage !== 'en-US' && <button onClick={(e) => changeLangHandler(e, 'en-US')}>EN</button>}
            {SelectedLanguage !== 'ka-GE' && <button onClick={(e) => changeLangHandler(e, 'ka-GE')}>GE</button>}
          </div>
        )}
      </div>
    </div>
  );
};

LanguageSwitcher.defaultProps = {
  style: {
    position: 'static',
    backgroundColor: '#F5F7F8',
  },
  isInsideHeader: true,
};
export default LanguageSwitcher;
