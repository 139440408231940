import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import * as Api from '../../Api';
import { UserActivePageEnum } from '../../Api';

export interface PageStatistics {
  isLoading: boolean;
  pageActivity: unknown;
}

const initialState: PageStatistics = {
  isLoading: false,
  pageActivity: null,
};

export const postAddUserActivity = createAsyncThunk(
  'account/AddUserActivity',
  async (
    { activePage, companyId, vacancyId }: { activePage: UserActivePageEnum; companyId?: string; vacancyId?: string },
    thunkApi
  ) => {
    try {
      const response = await Api.postAddUserActivityRequest(activePage, companyId, vacancyId);
      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }

      throw err;
    }
  }
);

export const pageStatisticsSlice = createSlice({
  name: 'pageStatistics',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postAddUserActivity.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postAddUserActivity.fulfilled, (state, action) => {
        (state.isLoading = false), (state.pageActivity = action.payload);
      })
      .addCase(postAddUserActivity.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

// export const {} = pageStatisticsSlice.actions;

export default pageStatisticsSlice.reducer;
