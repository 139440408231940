import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SectionHeaderEdit from '../../../Components/UI/ReusableComponents/SectionHeaderEdit/SectionHeaderEdit';
import useResources from '../../../Hooks/useResources';
import classes from './styles.module.sass';
import Overview from './Overview/Overview';
import ContactInfo from './ContactInfo/ContactInfo';
import { Company, KeyFacts } from '../types';
import InfoForm from './InfoForm/InfoForm';
import OverviewForm from './OverviewForm/OverviewForm';
import AboutCompanyForm from './AboutCompanyForm/AboutCompanyForm';
import { useOutletContext, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../Redux/store';
import { getCompanyKeyFactsByCompanyId, getCountryCodes, getKeyFacts } from '../../../Redux/slices/companyPanelSlice';
import { KeyfactDataType, companyKeyFactType, countryCodesType } from '../../../Api';
import { changeLanguage } from '../../../Redux/slices/languageSlice';

const emptyKeyFacts: KeyFacts = {
  founded: 0,
  headQuarter: '',
  headQuarterGe: '',
  companySize: '',
  companySizeGe: '',
  industry: '',
  industryGe: '',
  revenue: '',
  revenueGe: '',
  companyType: '',
  companyTypeGe: '',
  specialties: '',
  specialtiesGe: '',
  phoneNumber: '',
  email: '',
  website: '',
  location: '',
  locationGe: '',
  facebookUrl: '',
  linkedInUrl: '',
  twitterUrl: '',
  youTubeUrl: '',
  tikTokUrl: '',
  employeesGlobally: 0,
  employeesLocally: 0,
  georgiaOffice: '',
  georgiaOfficeGe: '',
  googleMapUrl: '',
  headQuarterGoogleMapUrl: '',
  paidLeave: 0,
  otherKeyFacts: {},
  countryCode: '+995',
};

function AboutCompanyDetails() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const firstRender = useRef<boolean>(true);
  const companyPanel = location.pathname.toLowerCase().includes('companypanel');

  const resources = useResources();

  const { company, refetchData } = useOutletContext<{ company: Company; refetchData: () => void }>();

  const canEdit = companyPanel;

  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [showOverviewModal, setShowOverviewModal] = useState<KeyfactDataType | companyKeyFactType | undefined>(
    undefined
  );
  const [allKeyFacts, setAllKeyFacts] = useState<KeyfactDataType[]>([]);
  const [companyKeyFacts, setCompanyKeyFacts] = useState<companyKeyFactType[]>();
  const [countryCodes, setCountryCodes] = useState<countryCodesType['countryCodes']>();
  const { SelectedLanguage } = useSelector((state: RootState) => state.language);
  const { id: companyId } = company;

  useEffect(() => {
    if (!companyId) return;
    initData();
  }, [companyId]);

  useEffect(() => {
    if (!showOverviewModal && !firstRender.current) {
      initData();
    }
    firstRender.current = false;
  }, [showOverviewModal]);

  const initData = async () => {
    const [allKeyFactResponse, companyKeyFactResponse, countryCodesRequest] = await Promise.all([
      dispatch(getKeyFacts()).unwrap(),
      dispatch(getCompanyKeyFactsByCompanyId(company.id)).unwrap(),
      dispatch(getCountryCodes()).unwrap(),
    ]);
    setCountryCodes(countryCodesRequest[0].countryCodes);
    setAllKeyFacts(allKeyFactResponse.data);
    setCompanyKeyFacts(companyKeyFactResponse);
  };

  // @ts-ignore
  const keyFacts: (KeyfactDataType | companyKeyFactType)[] = useMemo(() => {
    if (canEdit) {
      const result = allKeyFacts.map((el) => {
        const chosenKeyFact = companyKeyFacts?.find((companyEl) => companyEl.keyFactId === el.id);
        return chosenKeyFact ? { ...chosenKeyFact, type: 'companyKeyFactType' } : { ...el, type: 'KeyfactDataType' };
      });

      return result;
    }

    return companyKeyFacts?.map((el) => ({ ...el, type: 'companyKeyFactType' })) || [];
  }, [companyKeyFacts]);

  const AboutCompany = () => {
    if (SelectedLanguage === 'en-US') return <>{company.about}</>;
    if (!company.aboutGe) {
      return <p onClick={() => dispatch(changeLanguage('en-US'))}>კომპანიის აღწერა ხელმისაწვდომია ინლგისურად</p>;
    }

    return <>{company.aboutGe}</>;
  };

  return (
    <div className={classes.aboutCompanyContainer}>
      <div className={classes.leftContainer}>
        <SectionHeaderEdit
          header={resources.Reusable.aboutCompany}
          canEdit={canEdit}
          handleClick={() => setShowAbout(true)}
        />
        <p className={classes.aboutCompanyTextContainer} style={{ whiteSpace: 'pre-line' }}>
          <AboutCompany />
        </p>
        <Overview
          keyFacts={keyFacts || []}
          setShowOverviewModal={setShowOverviewModal}
          canEdit={canEdit}
          refetchData={refetchData}
        />
      </div>
      <ContactInfo keyFacts={company.keyFacts || emptyKeyFacts} setShowInfoModal={setShowInfoModal} canEdit={canEdit} />
      <InfoForm
        keyFacts={company.keyFacts || emptyKeyFacts}
        showInfoModal={showInfoModal}
        setShowInfoModal={setShowInfoModal}
        refetchData={refetchData}
        countryCodes={countryCodes}
      />
      <OverviewForm
        keyFacts={showOverviewModal}
        showOverviewModal={!!showOverviewModal}
        setShowOverviewModal={setShowOverviewModal}
        refetchData={refetchData}
      />
      <AboutCompanyForm
        aboutCompanyModal={showAbout}
        setAboutCompanyModal={setShowAbout}
        about={company.about || ''}
        aboutGe={company.aboutGe || ''}
        refetchData={refetchData}
      />
    </div>
  );
}

export default AboutCompanyDetails;
