import React from 'react'

const UserIcon = (props: React.SVGAttributes<SVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            {...props}
        >
            <path
                id="Icon_awesome-user-alt"
                data-name="Icon awesome-user-alt"
                d="M20,22.5A11.25,11.25,0,1,0,8.75,11.25,11.253,11.253,0,0,0,20,22.5ZM30,25H25.7A13.6,13.6,0,0,1,14.3,25H10A10,10,0,0,0,0,35v1.25A3.751,3.751,0,0,0,3.75,40h32.5A3.751,3.751,0,0,0,40,36.25V35A10,10,0,0,0,30,25Z"
            />
        </svg>

    )
}

export default UserIcon