export const resources = {
  en: {
    Jumbotron: {
      // TitlePrtOne: 'All Software ',
      // TitlePrtTwo: 'Companies in Georgia ',
      Title1: 'Unlock tech talents, ',
      Title2: ' jobs and companies',
      Description:
        'Techwave (formerly software.ge) is a tech job listing and tech company directory platform in Georgia. Discover and browse through tech jobs and tech company profiles, get insights into their culture, projects, and vision.',
      Companies: 'Companies in Georgia ',
      ActiveJobOpenings: 'Active job openings ',
    },
    CompanyPanel: {
      techTitle: 'Select tech stacks & tools',
      setCoverPicture: 'Set cover picture',
      savedTalents: 'Saved Talents',
      pleaseChooseEitherFalseOrTrue: 'Please choose either only favourite talants or not favourite ones',
      removeVacancy: 'Remove Vacancy',
      removeTalent: 'Remove Talent',
      areYouSureToDeleteTalentQuestion: 'Are you sure you want to delete talent from favorites?',
      addToFavoritesLater: 'You can add talent to "saved Talents" later',
    },
    Companies: {
      verifiedSince: 'Verified since',
      SearchKeyWord: 'Use any keywords',
      myCompany: 'My company',
      employees: 'Employees',
      SearchPlaceholder: 'Company name',
      openPositions: 'Open positions',
      incorrectFields: 'Please, fill all fields correctly',
      validYear: 'Enter valid year',
      SearchBtn: 'Search',
      Filter: 'Filter by',
      Header: 'Companies',
      hiringStatus: 'Hiring Status',
      CardOpenPositons: 'Open Jobs',
      notVerified: 'Not verified',
      CardTeam: 'Team',
      AboutCompanyOverview: 'Overview',
      Infobar: 'Infobar',
      Info: 'Info',
      SocialNetwork: 'Social Network',
      Founded: 'Founded',
      isHiring: 'Is Hiring',
      Headquarters: 'Headquarters',
      CompanySize: 'Company size',
      CompanySizeLocal: 'Company size locally',
      CompanySizeGlobal: 'Company size globally',
      Industry: 'Industry',
      Revenue: 'Revenue',
      Type: 'Type',
      Specialities: 'Specialities',
      PhoneNumber: 'Phone number',
      GoogleMapUrl: 'Your office Google maps URL',
      invalidPhone: 'Phone number is not valid',
      incorrectUrl: 'Enter correct url!',
      Email: 'Email',
      Website: 'Website',
      Location: 'Location',
      GetDirections: 'Get Directions',
      AboutCompanyHashAbout: 'About company',
      AboutCompanyHashKeyFacts: 'Key facts',
      AboutCompanyHashBenefits: 'Benefits',
      AboutCompanyHashTechnologies: 'Technologies',
      AboutCompanyHashVacancies: 'Vacancies',
      AboutCompanyVerified: 'Verified Account',
      AboutCompanyUnverified: 'Unclaimed account',
      thisAccountVerified: 'This account is verified.',
      thisAccountUnclaimed: 'This company account is unclaimed account on Techwave.',
      thisAccountUnverified: 'This company account is Not verified account on Techwave.',
      AboutCompanyKeyFactsFounded: 'Founded',
      AboutCompanyKeyFactsHeadQuarter: 'Headquarter',
      AboutCompanyKeyFactsLabelHeadQuarter: 'Headquarter Address (Written in English)',
      AboutCompanyKeyFactsLabelHeadQuarterGe: 'Headquarter Address (Written in Georgian)',
      AboutCompanyKeyFactsHeadQuarterToastError: 'Headquarter (Written in English) must be only in English.',
      AboutCompanyKeyFactsHeadQuarterToastErrorGe: 'Headquarter (Written in Georgian) must be only in Georgian.',
      AboutCompanyKeyFactsGeoOfficeToastError: 'Georgian office address (Written in English)" must be only in English.',
      AboutCompanyKeyFactsGeoOfficeToastErrorGe:
        'Georgian office address (Written in Georgian)" must be only in Georgian.',
      AboutCompanyKeyFactsLocalEmployees: 'Employees Locally',
      AboutCompanyKeyFactsGlobalEmployees: 'Employees Globally',
      AboutCompanyKeyFactsGeorgiaOffice: 'Georgian office address',
      AboutCompanyKeyFactsLabelGeorgiaOffice: 'Georgian office address (Written in English)',
      AboutCompanyKeyFactsLabelGeorgiaOfficeGe: 'Georgian office address (Written in Georgian)',
      AboutCompanyKeyFactsPaidLeave: 'Paid Leave Allowance',
      AboutCompanyTechnologiesCompanyTechnology: 'Company technology',
      AboutCompanyCopyLink: 'Copy Link',
      AboutCompanyLinkCopied: 'Link Copied',
      CompanyPanelGeneralInfo: 'Profile',
      CompanyPanelChangePassword: 'Change Password',
      CompanyPanelTagline: 'Tagline',
      ChooseTechnology: 'Choose technology',
      type: 'Type',
      EditBenefitChooseBenefits: 'Choose Benefit(s)',
      editAboutCompanyTextLengthWarn: ' Pasted text exceeded the limit. Your text was trimmed.',
      charactersLeft: 'characters left',
      charactersAdd: '',
      charactersSymbol: '',
      changePassword: 'Change Password',
      passwordChanged: 'has been changed',
      currentPassword: 'Current password',
      newPassword: 'New Password',
      confirmPassword: 'Confirm New Password',
      warnToFillProfile: ' In order to add vacancies you must fill your profile to 100%',
      reviewMode:
        " Your account is in review mode. You can still add jobs and modify your content but it won't be visible on the platform until your application is approved.",
      WarnEmailVerification:
        'Please verify your email address to access all Techwave.ge features. A verification email has been sent to the provided email address.',
      updatedSuccessfully: 'Updated Successfully',
      Subscribe: 'Subscribe',
      Subscribed: 'Subscribed',
      subscribedJobFunctions: 'Subscribed Roles',
      noSubscribedJobFunctions: 'No Subscribed Roles',
      noSubscribedFilters: 'No Subscribed Filters',
      subscribedCompanies: 'Subscribed Companies',
      noSubscribedCompanies: 'No Subscribed Companies',
      subSearch: 'Get job alerts',
      filterNsort: 'Filter & Sort',
      editSubCompanies: 'Edit Sub. Companies',
      editSubJobFunctions: 'Edit Sub. Job Functions',
      editSubscriptions: 'Edit Sub. Filters',
      availableJobFunctions: 'Available job functions',
      findCompany: 'Find company',
      FilterBy: 'Filter by',
      TeamSize: 'Team size',
      Technologies: 'Technologies',
      PleaseFillThisField: ' This field is required',
      Currency: 'Currency',
      TextInGeorgian: 'Text in Georgian',
      TextInEnglish: 'Text in English',
      TextOnlyInEnglish: '"Text in English" must be only in English.',
      TextOnlyInEnglishOrGeorgian: '"Text in Georgian" should be in Georgian or English only.',
      TextShouldContainOnlyGeLetters: '"Text in Georgian" must contain Georgian letters.',
      Statistics: 'Statistics',
      EditBenefits: 'Edit Benefits',
      MandatoryBenefits: 'Some benefits are mandatory by Georgian law or are an industry standard',
      AddSubBenefit: 'Add Sub Benefit',
      AddBenefit: 'Add Benefit',
      BenefitDeleteConfirm: 'Are you sure you want to delete Benefit?',
      DeleteBenefit: 'Delete Benefit',
      BenefitsSavedCreated: 'Benefits have been updated',
      BenefitUpdateFailed: 'Benefits update failed',
      DisplaySalary: 'I want to display salary on my vacancy',
      claimProfile: 'Claim Profile',
      claimProfileInfo:
        'Claim your free company profile. With a claimed profile you can post jobs, share your stories with tech talents and do many more.',
    },
    Vacancy: {
      activeJobs: 'Active jobs',
      endedJobs: 'Ended jobs',
      currentVacancies: 'Current Vacancies',
      vacancyDetails: 'Vacancy details',
      endedVacancies: 'Ended Vacancies',
      questionOnDeleteVacancy: 'Are you sure you want to delete the vacancy?',
      questionOnEndVacancy: 'Are you sure you want to end the vacancy?',
      questionOnRenewVacancy: 'Are you sure you want to republish the vacancy?',
      addVacancy: 'Add Vacancy',
      deleteVacancy: 'Delete Vacancy',
      endVacancy: 'End Vacancy',
      renewVacancy: 'Republish Vacancy',
      vacancySaved: 'Vacancy saved',
      editVacancies: 'Edit Vacancies',
      description: 'Description (EN)',
      descriptionGE: 'Description (GE)',
      Deadline: 'Deadline',
      oneMonth: '1 Month',
      twoMonth: '2 Months',
      threeMonth: '3 Months',
      sixMonth: '6 Months',
      oneYear: '1 Year',
      completeProfileToAddVacancies: 'Profile to add vacancies',
      vacancySavedCreated: ' Vacancy has been updated',
      stackNTools: 'Tech stacks & tools',
      hiringRange: 'Provided hiring range',
      seniorityLevel: 'Seniority level',
      salaryInfo: 'Salary range is provided by the company. Please note this isn’t a guarantee of compensation.',
      salaryRange: 'Salary range (Optional)',
      salaryRangeInfo:
        'We are not going to display salary on your vacancy, unless you check the box. However displaying salary range help the tech industry become more transparent.',
      companyBenefits: 'Company Benefits',
      georgian: 'Georgian',
      english: 'English',
      german: 'German',
      french: 'French',
      spanish: 'Spanish',
      chinese: 'Chinese',
      turkish: 'Turkish',
      russian: 'Russian',
      autoSuggestionsBasedDescription: 'Auto Suggest based on decription',
      alreadyHadSimilarVacancy: 'Already had similar vacancy?',
      usePreviousTemplate: 'Use previous template to auto populate form easier and quicker',
      autofillTemplate: 'Autofill Template',
    },
    Reusable: {
      english: 'English',
      georgian: 'Georgian',
      viewAll: 'View all',
      myProfile: 'My profile',
      getStarted: 'Get started',
      workType: 'Work type',
      role: 'Role',
      pleaseSelect: 'Please Select',
      optional: 'Optional',
      Applicants: 'Applicants',
      clearAll: 'Clear All',
      filters: 'Filters',
      salary: 'Salary',
      selected: 'Selected',
      pleaseInput: 'Please input',
      somethingWentWrong: 'Something went wrong, Please reload the page',
      incorrectLink: 'Incorrect Link',
      page: 'Page',
      prevPage: 'Previous',
      nextPage: 'Next',
      aboutCompany: 'About',
      logOut: 'Logout',
      loadMore: 'Load More',
      expired: 'Expired',
      talentInfo: 'Talent info',
      submitDate: 'Submit date',
      download: 'Download',
      status: 'Vacancy status',
      clear: 'Clear',
      active: 'Active',
      comingSoon: 'Coming soon',
      chooseCompany: 'Choose company',
      choose: 'Choose',
      blog: 'News',
      applyFilters: 'Apply Filters',
      edit: 'Edit',
      noResultFound: 'No Result Found',
      noResult: 'No Result',
      fullInfo: 'Full info',
      settings: 'Settings',
      type: 'Type',
      save: 'Save',
      drafts: 'Drafts',
      draft: 'Draft',
      password: 'Password',
      changePassword: 'Change Password',
      category: 'Category',
      delete: 'Delete',
      end: 'End',
      complete: 'Complete',
      update: 'Update',
      signUp: 'Sign Up',
      signIn: 'Sign In',
      useAnyKeyword: 'Use any keyword',
      renew: 'Renew',
      republish: 'Republish',
      publishAgain: 'Publish again',
      jobTitleOrCompanyName: 'Job title or company name',
      title: 'Title',
      titleGE: 'Title (GE)',
      titleEN: 'Title (EN)',
      vacancytitle: 'Vacancy title',
      vacancytitleGE: 'Vacancy title (GE)',
      search: 'Search',
      support: 'Support',
      privacyPolicy: 'Privacy Policy',
      termsOfUser: 'Terms of use',
      back: 'Back',
      user: 'User',
      mySubscriptions: 'My Subsriptions',
      passwordRecovery: 'Password Recovery',
      languages: 'Languages',
      companies: 'Companies',
      vacancies: 'Vacancies',
      city: 'City',
      workLocation: 'Work place type',
      vacancy: 'Vacancy',
      company: 'Company',
      addVacancies: 'Add Vacancies',
      add: 'Add',
      passwordChange: 'Password Change',
      sortBy: 'Sort by',
      filterBy: 'Filter by',
      sort: 'Sort',
      lastAdded: 'Last added',
      firstAdded: 'First added',
      aToZ: 'A to Z',
      zToA: 'Z to A',
      chooseTechnology: 'Choose technology',
      jobType: 'Job type',
      jobDescription: 'Job description (English)',
      jobDescriptionGe: 'Job description (Georgian)',
      workPlace: 'Work place',
      softwareRequired: 'Software Required',
      filterByPlace: 'Filter by place',
      deadline: 'Deadline',
      setDeadline: 'Set deadline',
      fillForm: 'Apply Now',
      userFillForm: 'Verify Account',
      Subscribe: 'Subscribe',
      Subscribed: 'Subscribed',
      SubscribeNow: 'Subscribe now',
      Unsubscribe: 'Unsubscribe',
      authorized: 'registered',
      technology: 'Technology',
      country: 'Country',
      employmentType: 'Job type',
      jobFunctions: 'Job role',
      jobCategory: 'Job category',
      linkCopied: 'Link Copied',
      copyLink: 'Copy Link',
      message: 'Message',
      selectAll: 'Select all',
      messageValidationError: 'The message is not in the correct format. Input message in GE or EN language.',
      phoneValidationError: 'Phone validation fail!',
      reCaptchaFailedError: 'The reCaptcha was not validated. Please try again.',
      aboutToSubscribe: 'You are about to subscribe to',
      notificationFrequency: 'Please set your job notification frequency',
      subscribtionFrequency: 'notification frequency',
      daily: 'Daily',
      weekly: 'Weekly',
      close: 'Close',
      attachFile: 'Attach File',
      and: 'and',
      publish: 'Publish',
      publishDate: 'Submit Date',
      view: 'View',
      all: 'All',
      fullName: 'Full Name',
      email: 'Email',
      phone: 'Phone',
      files: 'files',
      allVacancies: 'All Vacancies',
      next: 'Next',
      addTextElement: 'Add text element',
      addTitle: 'Add title',
      addBullet: 'Add bullets',
      addText: 'Add text',
      rememberMe: 'Remember Me',
      welcome: 'Welcome',
      submit: 'Submit',
      sendVerificationEmail: 'Send Verification Email',
      viewProfile: 'View Profile',
      pleaseChooseAtLeastOne: 'Please choose at least one',
      PleaseFillSection: 'Please fill section',
      youCantAddVacanciesWithoutThisSections: "You can't add vacancies without this section",
      wrongLanguageTextEN: 'Please enter only English text in the title (EN).',
      wrongLanguageTextGE: 'Please enter only English or Georgian text in the title (GE).',
      wrongLanguageTextDescEN: 'Description (EN) should not be empty.',
      wrongLanguageTextDescGE: 'Please enter only English or Georgian text in the description.',
      wrongLanguageDescriptionEN: 'Please enter only English text in the description (EN).',
      wrongLanguageDescriptionGE: 'Please enter only English or Georgian text in the description (GE).',
      position: 'Position',
      positions: 'Positions',
      level: 'Level',
      // marker
      fileIsTooLarge: 'The file is too large to upload.',
      fileFormatError: 'The file format is not valid.',
      accountVerifiedSuccessfully: 'Your account was verified successfully!',
      accountVerificationFailed: 'Account verification failed',
      pleaseBeInformedAboutUrlChange: 'Please be informed that the URL for the position will be also updated',
      saveChanges: 'Save Changes',
      cancel: 'Cancel',
      globally: 'Globally',
      locally: 'Locally',
      min: 'Min',
      max: 'Max',
      amount: 'Amount',
      notProvided: 'Not provided',
      showLess: 'Show Less',
      showMore: 'More',
      companyPanel: 'Company Panel',
      jobs: 'jobs',
      learnMore: 'Learn more',
      dismiss: 'Dismiss',
      seeDetails: 'See details',
      createProfile: 'Create Profile',
    },
    Header: {
      aboutUs: 'About Us',
      signIn: 'Sign In',
      signUp: 'Sign Up',
      logOut: 'Logout',
      generalInformation: 'General Information',
      changePassword: 'Change Password',
      ViewingAs: 'You are viewing as',
      exitViewAs: 'Exit view as',
    },
    Footer: {
      contactUs: 'Contact Us',
      allRightReserved: 'All rights reserved',
      products: 'Products',
      techNews: 'Tech news',
      changeLanguage: 'Change language',
      projects: 'Projects',
      faq: 'FAQ',
      helpCenter: 'Help center',
      resources: 'Resources',
      pricing: 'Pricing',
      contactInfo: 'Contact information',
      hotline: 'Hotline',
    },
    GotInterested: {
      gotInterested: 'The sea of opportunities knows no limits',
      gotInterested1: 'The sea of opportunities ',
      gotInterested2: 'knows no limits',
      description: 'Techwave connects leading tech companies and talents in Georgia. ',
      buttonText: 'Join the wave',
    },
    CompaniesList: {
      activelyHiring: 'Actively hiring',
      moreComingSoon: 'More coming soon',
    },
    LatestVacancies: {
      latestVacancies: 'Latest Vacancies',
    },
    NoVacancies: {
      noVacancies: 'be the first one to receive the notifications about announced vacancies.',
      sign_up: ' Sign up now and',
    },
    NoCompanies: {
      noCompanies: ' Soon we will reveal all of our new partners',
      sign_up: ' sign up now and gain from being a part of our community!',
    },
    TagSearch: {
      stillStruggling: 'Still struggling to Find',
      easyWay: 'Easy way to find company or position',
      findwithTechnology: 'Find with technology',
    },
    TypeAheadSearch: {
      resultsFound: 'Results found',
      vacancies: 'Vacancies',
      companies: 'Companies',
      published: 'Published',
      deadline: 'Deadline',
    },
    AboutUs: {
      aboutCompany: 'About Company',
      aboutSoftwareCompany: 'About Techwave.ge',
      aboutCompanyParagraph:
        'Techwave is a platform that combines tech job listing and tech company directory in Georgia. We give you access to the most relevant and up-to-date information on the tech scene, whether you are looking for a new job, a partner, or a fresh perspective. By putting all the tech opportunities in one place, we aim to contribute to the overall progress of the tech sector in Georgia.',
      companies: 'Companies',
      activeJobOppenings: 'Active job oppenings',
      registeredUsers: 'Registered users',
      employedTalent: 'Employed talent',
      whatWeDo: 'What we do',
      whatWeDoParagraph:
        'Our platform is for anyone who is interested in the tech industry, from beginners to experts, from employees to employers, from curious to committed. Here’s how we can help you:',
      forJobSeekers: 'For job seekers',
      forJobSeekersParagraph:
        'Browse through tech jobs and company profiles, and get insights into their tech stack, culture, projects, and benefits. Apply for the jobs that match your skills and preferences, and get feedback from the employers. Discover new opportunities and challenges, and find your dream job in the tech sector.',
      forEmployees: 'For employees',
      forEmployeesParagraph:
        'Connect with other tech professionals, and exchange ideas and feedback. Learn from the best, and improve your skills and knowledge. Explore new possibilities, and advance your career in the tech industry.',
      forEmployers: 'For employers',
      forEmployersParagraph:
        'Post your tech jobs and company profile, and attract the top talent in Georgia. Review the applications and profiles of the candidates, and contact the ones that fit your needs and expectations. Hire the best, and build your dream team in the tech sector.',
      faq: 'Frequently asked questions',
      joinTheWave: 'Join the wave',
      createCompanyProfile: 'Create Company Profile',
      navigatingSectionTitle: 'Navigating the open waters of Georgian tech sector',
      navigatingSectionP1:
        'Georgia is a strategic crossroad between the West and the East, and a top destination for doing business. With low taxes and incentives for tech companies, the tech sector grows rapidly and offers many opportunities.',
      navigatingSectionP2:
        'We see the Georgian tech sector as a sea of opportunities, and we’re here to help you navigate these open waters. From tech talents to tech companies, Techwave empowers the tech people in Georgia to make informed decisions, achieve their goals, and thrive in the tech industry.',
      navigatingSectionP3:
        ' Dive into the sea of opportunities, where each wave brings growth. See what’s possible - join us!',
      severalCompaniesTitle: 'Several Companies on platform',
      severalCompaniesP: 'Trusted by 30+ tech companies in Georgia',
      getAnswersForFAQ: 'Get answers for most questions',
    },
    BreadCrumbs: {
      home: 'Home',
      aboutUs: 'About Us',
      allVacancies: 'All Vacancies',
    },
    Vacancies: {
      jobTitle: 'Vacancy title',
      jobTitleGe: 'Vacancy title Georgian',
      vacancies: 'Vacancies',
      type: 'Type',
      techMust: 'Please choose at least one technology',
      noActiveJob: 'There are no active jobs right now',
      noSavedJobs: 'You dont have saved jobs',
      noJobs: 'There are no jobs right now',
      deletedFromFavorites: 'Vacancy deleted from favorites',
      addedToFavorites: 'Added to favorites',
    },
    VacancyPreview: {
      sendApplication: 'Send Resume',
      sendApplicationApplyNow: 'Apply Now',
      sendApplicationText: 'Easy way to send a resume is to fill the form below',
      subscribePosition: 'Subscribe Position',
      subscribePositionText: 'Subscribe position to receive other companies vacancies',
      otherVacancies: 'Other Vacancies',
      otherVacanciesText: 'Similar open positions from other companies',
      thisFeatureIsOnlyAvailableText:
        'This feature is not available for business profiles. Please switch to a personal profile',
      ExpiredNoLongerAccepting: 'Expired, no longer accepting applicants',
      logIn: 'log in',
      forUsers: 'users',
      iAgreeTo: 'I agree to',
      terms: 'terms',
      conditions: 'conditions',
      vacancyPreviewMode: 'Vacancy preview mode',
      pleaseUploadACV: 'Please upload a CV',
      previewMode: 'Preview Mode',
      beforePublishing: 'Before publishing',
      saveToDraft: 'Save to draft',
      youCanAttach: 'You can attach',
      cvUpload: 'CV uploaded successfully',
      cvRemoved: 'CV removed successfully',
      applicationHasBeenSent: 'You have successfully submitted the application.',
      hasBeenSentSuccessfully: 'Email sent successfully',
      hasBeenSentSuccessfullyText:
        'The verification email was sent to the provided email address. Please check your email and follow the instructions. If you do not see the email in a few minutes, check your “junk mail” folder or “spam” folder.',
      hasBeenSentSuccessfullyText1: 'The ',
      hasBeenSentSuccessfullyText2: 'verification ',
      hasBeenSentSuccessfullyText3:
        ' email was sent to the provided email address. Please check your email and follow the instructions. If you do not see the email in a few minutes, check your ',
      hasBeenSentSuccessfullyText4: '“junk mail” ',
      hasBeenSentSuccessfullyText5: 'folder or ',
      hasBeenSentSuccessfullyText6: '“spam” ',
      hasBeenSentSuccessfullyText7: 'folder.',
      youNeedToLogIn: 'You need to log in before you submit your',
      application: 'application',
      youNeedToLogInBeforeSub: 'You need to log in before',
      subscription: 'subscription',
      verification: 'Verification',
      verificationFullText:
        'In order to apply for a vacancy, your profile should be verified. Please follow the verification link that will be sent to your email.',
      verificationFirstPart: 'In order to apply for a vacancy, your profile should be ',
      boldVerification: 'verified.',
      verificationSecondPart: ' Please follow the verification link that will be sent to your email.',
    },
    SignIn: {
      welcomeBack: 'Welcome back',
      signIn: 'Sign In',
      signInGoogle: 'Sign In With Google',
      signInLinkeding: 'Sign In With Linkedin',
      textOr: 'Or',
      forgotPassword: 'Forgot password',
      dontHaveAnAccount: "Don't have an account",
    },
    SignUp: {
      pleaseFollowSteps: 'Please follow the steps',
      personalD: 'Personal',
      companyD: 'Company',
      details: 'Details',
      experience: 'Experience',
      profile: 'Profile',
      personal: 'Personal',
      business: 'Business',
      fullName: 'Full Name',
      fullNameError: 'Full name is not the correct format.',
      fullNameLanguageError: 'Input full name in GE or EN language.',
      fullNameMinLengthError: 'Full name must be at least 4 characters.',
      fullNameMaxLengthError: 'Full name must be at most 50 characters.',
      CompanyNameError: 'The company name is not in the correct format.',
      CompanyNameLanguageError: 'Input company name in EN language.',
      CompanyNameMinLengthError: 'Company name must be at least 2 characters.',
      CompanyNameMaxLengthError: 'Full name must be at most 50 characters.',
      passwordRequired: 'Password is a required field.',
      emailWrongFormat: 'Invalid email format.',
      emailRequired: 'The Email field is required.',
      confirmPassword: 'Confirm Password',
      nextStep: 'Next Step',
      alreadyHaveAnAccount: 'Already have an account',
      companyName: 'Company Name',
      passwordRequirements: 'Password requirements',
      passwordRequirementText:
        'Must be 8-16 characters long and contain at least 1 upper case, 1 number and 1 special character.',
      mustContainUpperCaseLetter: 'Must contain an upper case letter',
      mustContainLowerCaseletter: ' Must contain a lower case letter',
      mustContainNumber: 'Must contain a number',
      mustContainSpecialCharacter: 'Must contain a special character',
      mustBeNCharactersLong: 'Must be 8-16 characters long',
      mustMatchConfirmPassword: 'Repeat password',
      setCompanyLogo: 'Set company logo',
      pleaseUploadThephoto: 'Please upload the photo',
      openForNewChallenges: 'Now you are open for new challenges',
      openToRecruitEmployees:
        'You’ve registered successfully! Now its time to complete your profile and start recruiting.',
      field: 'Field',
      selectField: 'Select Field',
      selectExp: 'Select Experience',
      chooseTechnology: 'Choose technology',
      type: 'Type',
      setProfilePicture: 'Set profile picture',
    },
    PasswordRecovery: {
      passwordRecovery: 'Password Recovery',
      setNewPassword: 'Set new password',
      pleaseFillEmailToRecoverYouPassword: 'Please fill Email to recover your password',
      passwordHasBeenChanged: 'Password has been changed',
      newPassword: 'New Password',
      confirmNewPassword: 'Confirm New Password',
      passwordHasBeenSent: 'Please check your email',
    },
    Statistics: {
      profileVisits: 'Profile visits',
      vacancyVisits: 'Vacancy visits',
      applications: 'Applications',
      totalVisits: 'Total visits',
      totalApplications: 'Total Applications',
      receivedApplications: 'Received Applications',
      format: 'Format',
      weeks: 'Weeks',
      months: 'Months',
      years: 'Years',
      week: 'Week',
      month: 'Month',
      year: 'Year',
      statistics: 'Statistics',
      thisWeek: 'This Week',
      thisMonth: 'This Month',
      thisYear: 'This Year',
    },
    UserPanel: {
      filteredJobSubscriptions: 'Subscribed Job Alerts',
      notificationFrequency: 'Notification frequency',
      deleteAccount: 'Delete Account',
      signInNSecurity: 'Sign in & Security',
      account: 'Account',
      deletionWarrning1:
        'Once you confirm deletion of your account, your profile and information will be permanently removed. This is an',
      deletionWarrningBold: 'irreversible',
      deletionWarrning2: 'action.',
    },
    Blog: {
      latestArticles: 'Latest Articles',
      publishedOn: 'Published on',
      subscribeBlog: 'Subscribe Our News',
      subscribeBlogText: 'Subscribe news to receive recent articles and company post shares',
      shareBlog: 'Share News',
      FindInBlogs: 'Search in news',
      subscribeBlogSubtitle: 'Subscribe to the blog to receive recent articles and company post shares',
      subscriptionTerms:
        'By subscribing to "News", you consent to receive informative and engaging content from Techwave via email',
      termsCheckboxError: 'Please agree to receive emails from "Flat Rock Technology"',
      youAreAlreadyUnsub: 'You already unsubscribed',
      pleaseFillEmailField: 'Please fill email field',
    },
    Compare: {
      compare: 'Compare',
      chooseAndCompareCompanies: 'Choose tech companies and compare them',
      companyCompareSubtitle: 'See how any tech company compares with other tech companies by choosing them.',
      type: 'Type',
      popularComparisions: 'Popular Company Comparisions',
      plaseChooseAtLeastOneCompany: 'Please choose at least one company!',
      anyCompany: 'any company',
      vs: ' VS ',
      seeWhatsDifference: 'See how working at ',
      seeWhatsDifferenceText: '',
      seeWhatsDifferenceText2: ' compares on a various factor. By comparing company key facts, benefits, tech stack,',
      seeWhatsDifferenceText3: 'job openings, and more. Learn more about each company and apply to job that you like.',
      companySize: 'Company size',
      viewCompany: 'View company',
    },
    talent: {
      talentDetails: 'Talent Details',
      techStack: 'Tech stack',
      downloadCv: 'Download CV',
      appliedTalentsView: 'Applied talents view',
      applied: 'Applied',
      appliedJobs: 'Applied jobs',
      savedJobs: 'Saved jobs',
    },
    faq: {
      forEmployers: 'For employers',
      forTalents: 'For talents',
      general: 'General',
      faqSubtitle: 'Everything you need to know about the product and billing',
    },
    reviews: {
      reviews: 'Reviews',
      review: 'Reviews',
      writeAReview: 'Write a Review',
      rateCompany: 'Rate a Company',
      itOnlyTakesMinute: 'It only takes a minute! And your anonymous review will help other job seekers',
      overallCompanyRating: 'Overall company rating',
      reviewHeadline: 'Review headline',
      yourHonestFeedback: 'Your honest feedback matters',
      feedback:
        'Your feedback is invaluable to our platform and fellow users. It helps others in making informed decisions about potential employers and career choices',
      respectfulToneTitle: 'Please remember to maintain a respectful tone by refraining from:',
      respectfulToneLi1: 'Using aggressive or discriminatory language',
      respectfulToneLi2: 'Including profanities',
      respectfulToneLi3: 'Sharing confidential information or trade secrets',
      yourCooperation:
        'Your cooperation is vital in upholding the integrity of our platform as a trusted source for job and company insights. Thank you for your contribution!',
      statusOfReview: 'Status of review',
      displayAsAnonymous: 'Display me as anonymous',
      displayActualDetails: 'Display my actual details',
      keepInMind:
        'Keep in mind that we are not going to display your actual full name, only profile picture, position and your work duration with company',
      reviewDescription: 'Review Description',
      typeOverallReview: 'Type overall review',
      companyPros: 'Company Pros',
      companyCons: 'Company Cons',
      shareBestReasons: 'Share some of the best reasons to work at',
      inCompany: '',
      shareDownsides1: 'Share some of the downsides of working at',
      shareDownsides2: '',
      adviceForManagement: 'Advice for Management',
      shareManagementSuggestions: 'Share suggestions for how management can improve working at',
      bestAndWorstBenefits1: 'What is the best or worst thing about the benefits package at',
      bestAndWorstBenefits2: '',
      companyPerks: 'Tell us about things like company perks, health insurance, 401k, vacations and time off',
      iAgreeTerms: 'I agree with terms and conditions of Techwave',
      submitReview: 'Submit review',
      cons: 'Cons',
      pros: 'Pros',
      showMore: 'Show more',
      showLess: 'Show less',
      helpful: 'Helpful',
      myReviews: 'My Reviews',
      noReviewsYet: 'There are no reviews yet',
    },
  },
  ge: {
    Jumbotron: {
      // TitlePrtOne: 'ყველა სოფტ ',
      // TitlePrtTwo: 'კომპანია საქართველოში ',
      Title1: 'ტექ ვაკანსიებისა და',
      Title2: 'ტექ კომპანიების პლატფორმა',
      Description:
        'Techwave-ზე (უწინ software.ge) შეგიძლია გადახედო ტექ ვაკანსიებსა და ტექ კომპანიების პროფილებს. შეიხედო კომპანიების შიდა სამზარეულოში, გაეცანო მათ კულტურას, ბენეფიტებს და გაიგო რა ტექნოლოგიებს იყენებენ.',
      Companies: 'კომპანიები საქართველოში ',
      ActiveJobOpenings: 'ღია პოზიციები',
    },
    CompanyPanel: {
      techTitle: 'აირჩიეთ ტექნოლოგიები & ხელსაწყოები',
      setCoverPicture: 'დააყენეთ ქავერის სურათი',
      savedTalents: 'რჩეული ტალანტები',
      pleaseChooseEitherFalseOrTrue: 'გთხოვთ აირჩიოთ მხოლოდ ფავორიტები ან არაფავორიტი ტალანტები',
      removeVacancy: 'ვაკანსიის წაშლა',
      removeTalent: 'ტალანტის წაშლა',
      areYouSureToDeleteTalentQuestion: 'დარწმუნებული ხართ რომ გსურთ წაშალოთ ტალანტი რჩეულებიდან?',
      addToFavoritesLater: 'თქვენ შეგიძლიათ დაამატოთ ტალანტი „შენახულ ტალანტებს“ მოგვიანებით',
    },
    Companies: {
      verifiedSince: 'ვერიფიცირებულია',
      SearchKeyWord: 'გამოიყენეთ საკვანძო სიტყვა',
      myCompany: 'ჩემი კომპანია',
      employees: 'თანამშრომლები',
      SearchPlaceholder: 'კომპანიის სახელი',
      openPositions: 'ღია ვაკანსიები',
      SearchBtn: 'ძიება',
      Filter: 'ფილტრაცია',
      Header: 'კომპანიები',
      hiringStatus: 'დაქირავების სტატუსი',
      CardOpenPositons: 'ვაკანსიები',
      notVerified: 'არავერიფიცირებულია',
      CardTeam: 'გუნდი',
      AboutCompanyOverview: 'მიმოხილვა',
      invalidPhone: 'ტელეფონის ნომერი არასწორია',
      incorrectUrl: 'შეიყვანეთ სწორი მისმართი!',
      incorrectFields: 'გთხოვთ შეავსეთ ყველა ველი სწორად',
      validYear: 'წელი სწორად დაწერეთ',
      Infobar: 'საკონტაკტო ინფორმაცია',
      Info: 'ინფორმაცია',
      SocialNetwork: 'სოციალური ქსელები',
      Founded: 'დაფუძნდა',
      isHiring: 'ეძებს კანდიდატებს',
      Headquarters: 'სათაო ოფისი',
      CompanySize: 'კომპანიის ზომა',
      CompanySizeLocal: 'კომპანიის ზომა ლოკალურად',
      CompanySizeGlobal: 'კომპანიის ზომა გლობალურად',
      Industry: 'ინდუსტრია',
      Revenue: 'შემოსავალი',
      Type: 'ტიპი',
      Specialities: 'სპეციალობები',
      PhoneNumber: 'ნომერი',
      GoogleMapUrl: 'ოფისის Google maps URL',
      Email: 'ელ-ფოსტა',
      Website: 'ვებგვერდი',
      Location: 'ადგილმდებარეობა',
      GetDirections: 'ნახე რუკაზე',
      AboutCompanyHashAbout: 'კომპანიის შესახებ',
      AboutCompanyHashKeyFacts: 'ზოგადი ინფორმაცია',
      AboutCompanyHashBenefits: 'უპირატესობები',
      AboutCompanyHashTechnologies: 'ტექნოლოგიები',
      AboutCompanyHashVacancies: 'ვაკანსიები',
      AboutCompanyVerified: 'დამოწმებული ანგარიში',
      AboutCompanyUnverified: 'დაუმოწმებელი ანგარიში',
      thisAccountVerified: 'კომპნია ვერიფიცირებულია.',
      thisAccountUnclaimed: 'კომპანია არავერიფიცირებულია.',
      thisAccountUnverified: 'კომპანია არავერიფიცირებულია.',
      AboutCompanyKeyFactsFounded: 'დაარსდა',
      AboutCompanyKeyFactsHeadQuarter: 'სათაო ოფისი',
      AboutCompanyKeyFactsLabelHeadQuarter: 'სათაო ოფისი (ინგლისურად)',
      AboutCompanyKeyFactsLabelHeadQuarterGe: 'სათაო ოფისი (ქართულად)',
      AboutCompanyKeyFactsHeadQuarterToastError: 'შეიყვანეთ "სათაო ოფისი (ინგლისურად)" მხოლოდ ინგლისურ ენაზე.',
      AboutCompanyKeyFactsHeadQuarterToastErrorGe: 'შეიყვანეთ "სათაო ოფისი (ქართულად)" ქართულ ენაზე.',
      AboutCompanyKeyFactsGeoOfficeToastError: 'შეიყვანეთ "ოფისი საქართველოში (ინგლისურად)" მხოლოდ ინგლისურ ენაზე.',
      AboutCompanyKeyFactsGeoOfficeToastErrorGe: 'შეიყვანეთ "ოფისი საქართველოში (ქართულად)" ქართულ ენაზე.',
      AboutCompanyKeyFactsLocalEmployees: 'თანამშრომლები ლოკალურად',
      AboutCompanyKeyFactsGlobalEmployees: 'თანამშრომლები გლობალურად',
      AboutCompanyKeyFactsGeorgiaOffice: 'საქართველოს ოფისი',
      AboutCompanyKeyFactsLabelGeorgiaOffice: 'ოფისი საქართველოში (ინგლისურად)',
      AboutCompanyKeyFactsLabelGeorgiaOfficeGe: 'ოფისი საქართველოში (ქართულად)',
      AboutCompanyKeyFactsPaidLeave: 'ანაზღაურებადი შვებულება',
      AboutCompanyTechnologiesCompanyTechnology: 'კომპანიის ტექნოლოგიები',
      AboutCompanyCopyLink: 'ბმულის დაკოპირება',
      AboutCompanyLinkCopied: 'ბმული დაკოპირებულია',
      CompanyPanelGeneralInfo: 'პროფილი',
      CompanyPanelChangePassword: 'შეცვალეთ პაროლი',
      CompanyPanelTagline: 'სლოგანი',
      ChooseTechnology: 'აირჩიეთ ტექნოლოგია',
      type: 'ჩაწერეთ',
      EditBenefitChooseBenefits: 'აირჩიეთ დამატებითი სარგებელი',
      editAboutCompanyTextLengthWarn: 'ჩასმულმა ტექსტმა გადააჭარბა ლიმიტს. თქვენი ტექსტი ამოჭრილია.',
      charactersLeft: 'დარჩენილი სიმბოლო',
      charactersAdd: 'დაამატეთ კიდევ',
      charactersSymbol: 'სიმბოლო',
      changePassword: 'შეცვალეთ პაროლი',
      passwordChanged: 'შეცვლილია',
      currentPassword: 'მიმდინარე პაროლი',
      newPassword: 'ახალი პაროლი',
      confirmPassword: 'დაადასტურეთ ახალი პაროლი',
      warnToFillProfile: 'ვაკანსიების დასამატებლად, უნდა შეავსოთ თქვენი პროფილი 100%-ით',
      reviewMode:
        'თქვენი ანგარიში განხილვის რეჟიმშია. თქვენ მაინც შეგიძლიათ დაამატოთ ვაკანსიები და შეცვალოთ თქვენი კონტენტი, მაგრამ ის არ იქნება ხილული პლატფორმაზე, სანამ თქვენი განაცხადი არ დამტკიცდება.',
      WarnEmailVerification:
        'გთხოვთ, Techwave.ge-ს ყველა ფუნქციაზე წვდომის მისაღებად, გადაამოწმოთ თქვენი ელ.ფოსტის მისამართი. დამადასტურებელი მეილი გაიგზავნა მითითებულ ელფოსტის მისამართზე.',
      updatedSuccessfully: 'წარმატებით განახლებულია',
      Subscribe: 'გამოწერა',
      Subscribed: 'გამოწერილია',
      subscribedJobFunctions: 'გამოწერილი ფუნქციები',
      noSubscribedJobFunctions: 'სამუშაო ფუნქციები ჯერ არ გამოგიწერიათ',
      noSubscribedFilters: 'ფილტრები ჯერ არ გამოგიწერიათ',
      subscribedCompanies: 'გამოწერილი კომპანიები',
      noSubscribedCompanies: 'კომპანიები ჯერ არ გამოგიწერიათ',
      subSearch: 'ძიების გამოწერა',
      filterNsort: 'ფილტრი & სორტი',
      editSubCompanies: 'გამოწერილი კომპანიების ჩასწორება',
      editSubJobFunctions: 'გამოწერილი ფუნქციების ჩასწორება',
      editSubscriptions: 'გამოწერილი ფილტრების ჩასწორება',
      availableJobFunctions: 'ხელმისაწვდომი ფუნქციები',
      findCompany: 'იპოვე კომპანია',
      FilterBy: 'ფილტრაცია',
      TeamSize: 'გუნდის ზომა',
      Technologies: 'ტექნოლოგიები',
      PleaseFillThisField: 'ველი არ შეიძლება იყოს ცარიელი',
      Currency: 'ვალუტა',
      TextInGeorgian: 'ტექსტი ქართულად',
      TextInEnglish: 'ტექსტი ინგლისურად',
      TextOnlyInEnglish: 'შეიყვანეთ "ტექსტი ინგლისურად" ინგლისურ ენაზე.',
      TextOnlyInEnglishOrGeorgian: '"ტექსტი ქართულად" უნდა იყოს ქართულ ან ინგლისურ ენაზე.',
      TextShouldContainOnlyGeLetters: '"ტექსტი ქართულად" უნდა შეიცავდეს ქართულ ასოს.',
      Statistics: 'სტატისტიკა',
      EditBenefits: 'შეცვალე ბენეფიტები',
      MandatoryBenefits:
        'ზოგიერთი ბენეფიტი სავალდებულოა საქართველოს კანონმდებლობით ან წარმოადგენს ინდუსტრიის სტანდარტს',
      AddSubBenefit: 'დაამატე ბენეფიტი',
      AddBenefit: 'დაამატე ბენეფიტი',
      BenefitDeleteConfirm: 'დარწმუნებული ხართ რომ ბენეფიტის წაშლა გინდათ?',
      DeleteBenefit: 'ბენეფიტის წაშლა',
      BenefitsSavedCreated: 'ბენეფიტები წარმატებით განახლდა',
      BenefitUpdateFailed: 'ბენეფიტების განახლება ვერ მოხდა',
      DisplaySalary: 'მინდა გამოჩნდეს ხელფასი ვაკანსიაზე',
      claimProfile: 'თქვენი კომპანიაა?',
      claimProfileInfo:
        'მოითხოვეთ თქვენი კომპანიის პროფილი. ვერიფიცირებული პროფილის საშუალებით, შეგიძლაით განათავსოთ ვაკანსიები, გახდეთ მოთხოვნადი კომპანია და კიდევ მრავალი სხვა.',
    },
    Vacancy: {
      activeJobs: 'მიმდინარე ვაკანსიები',
      endedJobs: 'დასრულებული ვაკანსიები',
      currentVacancies: 'მიმდინარე ვაკანსიები',
      vacancyDetails: 'ვაკანსიის დეტალები',
      endedVacancies: 'დასრულებული ვაკანსიები',
      questionOnDeleteVacancy: 'დარწმუნებული ხართ, რომ გსურთ ვაკანსიის წაშლა?',
      questionOnEndVacancy: 'დარწმუნებული ხართ, რომ გსურთ ვაკანსიის დასრულება?',
      questionOnRenewVacancy: 'დარწმუნებული ხართ, რომ გსურთ ვაკანსიის ხელახლა გამოქვეყნება?',
      addVacancy: 'დაამატე ვაკანსია',
      vacancySaved: 'ვაკანსია შენახულია',
      editVacancies: 'ვაკანსიების ჩასწორება',
      description: 'აღწერა (ინგლისურად)',
      descriptionGE: 'აღწერა (ქართულად)',
      deleteVacancy: 'წაშალე ვაკანსია',
      endVacancy: 'ვაკანსიის დასრულება',
      renewVacancy: 'ვაკანსიის ხელახლა გამოქვეყნება',
      Deadline: 'ბოლო ვადა',
      oneMonth: '1 თვე',
      twoMonth: '2 თვე',
      threeMonth: '3 თვე',
      sixMonth: '6 თვე',
      oneYear: '1 წელი',
      completeProfileToAddVacancies: 'პროფილი ვაკანსიების დასამატებლად',
      vacancySavedCreated: 'ვაკანსია წარმატებით განახლდა',
      stackNTools: 'ტექნოლოგიები',
      hiringRange: 'ხელფასის ოდენობა',
      seniorityLevel: 'გამოცდილების დონე',
      salaryInfo:
        'ხელფასის ოდენობა მოწოდებულია კომპანიის მიერ. გაითვალისწინეთ რომ ხელფასის ეს ოდენობა გარანტირებული შეიძლება არ იყოს.',
      salaryRange: 'სახელფასო დიაპაზონი (ნებაყოფლობითი)',
      salaryRangeInfo:
        'თუ თქვენ არ მონიშნავთ შესაბამის ველს, ჩვენ არ გამოვაჩენთ სახელფასო დიაპაზონს. თუმცა სახელფასო დიაპაზონის გამოჩენა ქართულ ტექ ინდუსტრიას უფრო გამჭვირვალეს ხდის',
      companyBenefits: 'კომპანიის უპირატესობები',
      georgian: 'ქართული',
      english: 'ინგლისური',
      german: 'გერმანული',
      french: 'ფრანგული',
      spanish: 'ესპანური',
      chinese: 'ჩინური',
      turkish: 'თურქული',
      russian: 'რუსული',
      autoSuggestionsBasedDescription: 'ავტომატური შეთავაზება აღწერილობის საფუძველზე',
      alreadyHadSimilarVacancy: 'უკვე გქონდათ მსგავსი ვაკანსია?',
      usePreviousTemplate: 'გამოიყენეთ წინა შაბლონი ფორმის ავტომატური შევსებისთვის უფრო მარტივად და სწრაფად',
      autofillTemplate: 'ავტომატური შაბლონები',
    },
    Reusable: {
      english: 'ინგლისურად',
      georgian: 'ქართულად',
      myProfile: 'ჩემი პროფილი',
      getStarted: 'დაიწყე',
      viewAll: 'ყველას ნახვა',
      workType: 'სამუშაოს ტიპი',
      role: 'როლი',
      pleaseSelect: 'აირჩიეთ',
      Applicants: 'აპლიკანტი',
      optional: 'არასავალდებულო',
      filters: 'ფილტრი',
      aboutCompany: 'კომპანიის შესახებ',
      salary: 'ხელფასი',
      incorrectLink: 'ლინკი არასწორია',
      selected: 'არჩეულია',
      pleaseInput: 'გთხოვთ შეიყვანოთ',
      somethingWentWrong: 'შეფერხება მოხდა, გთხოვთ გადატვირთოთ გვერდი',
      page: 'გვერდიები',
      prevPage: 'წინა',
      nextPage: 'შემდეგი',
      clearAll: 'ყველას გასუფთავება',
      applyFilters: 'ფილტრით ძიება',
      loadMore: 'მეტი',
      expired: 'ვადაგასული',
      talentInfo: 'ტალანტის ინფო',
      submitDate: 'გამოგზავნის თარიღი',
      download: 'გადმოწერა',
      status: 'ვაკანსიის სტატუსი',
      clear: 'გასუფთავება',
      comingSoon: 'მალე',
      active: 'აქტიური',
      chooseCompany: 'აირჩიე კომპანია',
      choose: 'აირჩიეთ',
      logOut: 'გამოსვლა',
      edit: 'ჩასწორება',
      blog: 'სიახლეები',
      noResultFound: 'შედეგი ვერ მოიძებნა',
      noResult: 'შედეგი ვერ მოიძებნა',
      fullInfo: 'სრული ინფო',
      settings: 'პარამეტრები',
      type: 'ტიპი',
      save: 'შენახვა',
      drafts: 'Draft-ები',
      draft: 'Draft',
      password: 'პაროლი',
      changePassword: 'შეცვალეთ პაროლი',
      delete: 'წაშლა',
      end: 'დასრულება',
      category: 'კატეგორია',
      complete: 'შეავსეთ',
      update: 'განახლება',
      signUp: 'რეგისტრაცია',
      signIn: 'შესვლა',
      useAnyKeyword: 'გამოიყენეთ საკვანძო სიტყვა',
      renew: 'განახლება',
      republish: 'ხელახლა გამოქვეყნება',
      publishAgain: 'განახლება',
      jobTitleOrCompanyName: 'ვაკანსია ან კომპანია',
      title: 'დასახელება',
      titleGE: 'სათაური (ქართულად)',
      titleEN: 'სათაური (ინგლისურად)',
      vacancytitle: 'ვაკანსიის სათაური',
      vacancytitleGE: 'ვაკანსიის სათაური (ქართულად)',
      search: 'ძიება',
      support: 'დახმარება',
      privacyPolicy: 'პერსონალურ მონაცემთა დაცვის პოლიტიკა',
      termsOfUser: 'მომსახურების პირობები',
      back: 'უკან',
      user: 'მომხმარებელი',
      mySubscriptions: 'ჩემი გამოწერები',
      passwordRecovery: 'პაროლის აღდგენა',
      languages: 'ენა',
      companies: 'კომპანიები',
      vacancies: 'ვაკანსიები',
      city: 'ქალაქი',
      workLocation: 'სამუშაოს ადგილმდებარეობა',
      vacancy: 'ვაკანსია',
      company: 'კომპანია',
      addVacancies: 'დაამატე ვაკანსია',
      add: 'დაამატე',
      passwordChange: 'პაროლის შეცვლა',
      sortBy: 'სორტირება',
      filterBy: 'ფილტრაცია',
      sort: 'სორტირება',
      lastAdded: 'ბოლოს დამატებული',
      firstAdded: 'პირველად დამატებული',
      aToZ: 'ა-დან ჰ-მდე',
      zToA: 'ჰ-დან ა-მდე',
      chooseTechnology: 'აირჩიეთ ტექნოლოგია',
      jobType: 'სამსახურის ტიპი',
      jobDescription: 'სამსახურის აღწერა (ინგლისურად)',
      jobDescriptionGe: 'სამსახურის აღწერა (ქართულად)',
      workPlace: 'სამუშაო ადგილი',
      softwareRequired: 'საჭირო პროგრამული უზრუნველყოფა',
      filterByPlace: 'ადგილით გაფილტვრა',
      deadline: 'ბოლო ვადა',
      setDeadline: 'ვაკანსიის ვადა',
      fillForm: 'გაგზავნე რეზიუმე',
      userFillForm: 'მომხმარებლის ვერიფიკაცია',
      Subscribe: 'გამოწერა',
      Subscribed: 'გამოწერილია',
      SubscribeNow: 'გამოწერა',
      Unsubscribe: 'გამოწერის გაუქმება',
      authorized: 'რეგისტრირებულია',
      technology: 'ტექნოლოგია',
      country: 'ქვეყანა',
      employmentType: 'დასაქმების ტიპი',
      jobFunctions: 'სამუშაო ფუნქციები',
      jobCategory: 'სამუშაო კატეგორია',
      linkCopied: 'ბმული დაკოპირებულია',
      copyLink: 'ბმულის დაკოპირება',
      message: 'შეტყობინება',
      selectAll: 'ყველა',
      messageValidationError: 'მესიჯი არ არის სწორ ფორმატში. შეიყვანეთ ქართულ ან ინგლისურ ენაზე.',
      phoneValidationError: 'ტელეფონის დადასტურება ვერ მოხერხდა!',
      reCaptchaFailedError: 'reCaptcha არ დადასტურდა. გთხოვთ სცადეთ ხელახლა.',
      aboutToSubscribe: 'თქვენ აპირებთ გამოიწეროთ',
      notificationFrequency: 'გთხოვთ აირჩიოთ შეტყობინებების სიხშირე',
      subscribtionFrequency: 'გამოწერა',
      daily: 'ყოველ დღე',
      weekly: 'ყოველ კვირა',
      close: 'დახურვა',
      attachFile: 'ფაილის მიბმა',
      and: 'და',
      publish: 'გამოქვეყნება',
      publishDate: 'გამოგზავნის თარიღი',
      view: 'ნახვა',
      all: 'ყველა',
      files: 'ფაილები',
      fullName: 'სრული სახელი',
      email: 'ელფოსტა',
      phone: 'ტელეფონი',
      allVacancies: 'ყველა ვაკანსია',
      next: 'შემდეგი',
      addTextElement: 'დაამატეთ ტექსტი',
      addTitle: 'დაამატეთ სათაური',
      addBullet: 'დაამატეთ წერტილები',
      addText: 'დაამატეთ ტექსტი',
      rememberMe: 'დამიმახსოვრე',
      welcome: 'კეთილი იყოს თქვენი მობრძანება',
      submit: 'გაგზავნა',
      sendVerificationEmail: 'ვერიფიკაციის გაგზავნა',
      viewProfile: 'პროფილის ნახვა',
      pleaseChooseAtLeastOne: 'გთხოვთ აირჩიოთ ერთი მაინც',
      PleaseFillSection: 'გთხოვთ შეავსოთ განყოფილება',
      youCantAddVacanciesWithoutThisSections: 'თქვენ არ შეგიძლიათ ვაკანსიების დამატება ამ განყოფილების გარეშე',
      wrongLanguageTextEN: 'გთხოვთ სათაური (ინგლისურად) შეიყვანოთ მხოლოდ ინგლისური ტექსტი.',
      wrongLanguageTextGE: 'გთხოვთ სათაური (ქართულად) შეიყვანოთ მხოლოდ ინგლისური ან ქართული ტექსტი.',
      wrongLanguageTextDescEN: 'აღწერა (ინგლისურად) არ უნდა იყოს ცარიელი.',
      wrongLanguageTextDescGE: 'გთხოვთ აღწერაში შეიყვანოთ მხოლოდ ინგლისური ან ქართული ტექსტი.',
      wrongLanguageDescriptionEN: 'გთხოვთ აღწერა (ინგლისურად) შეავსოთ მხოლოდ ლათინური ასოებით',
      wrongLanguageDescriptionGE: 'გთხოვთ აღწერა (ქართულად) შეიყვანოთ მხოლოდ ინგლისური ან ქართული ტექსტი.',
      position: 'პოზიცია',
      positions: 'პოზიციები',
      level: 'დონე',
      fileIsTooLarge: 'ფაილის ზომა დიდია.',
      fileFormatError: 'ფაილის ფორმატი არასწორია.',
      accountVerifiedSuccessfully: 'თქვენი ანგარიში ვერიფიცირებულია!',
      accountVerificationFailed: 'თქვენ ვერ გაიარეთ ვერიფიკაცია.',
      pleaseBeInformedAboutUrlChange: 'გაითვალისწინეთ, რომ განახლდება ვაკანსიის ბმულიც (URL).',
      saveChanges: 'ცვლილებების შენახვა',
      cancel: 'გაუქმება',
      globally: 'გლობალურად',
      locally: 'ლოკალურად',
      min: 'მინიმალური',
      max: 'მაქსიმალური',
      amount: 'რაოდენობა',
      notProvided: 'არ არის მოწოდებული',
      showLess: 'ნაკლების ჩვენება',
      showMore: 'მეტის ჩვენება',
      companyPanel: 'კომპანიის პანელი',
      jobs: 'ვაკანსიები',
      learnMore: 'გაიგე მეტი',
      dismiss: 'გათიშვა',
      seeDetails: 'მეტის ნახვა',
      createProfile: 'პროფილის შექმნა',
    },
    Header: {
      aboutUs: 'ჩვენ შესახებ',
      signIn: 'შესვლა',
      signUp: 'რეგისტრაცია',
      logOut: 'გამოსვლა',
      generalInformation: 'ზოგადი ინფორმაცია',
      changePassword: 'პაროლის შეცვლა',
      ViewingAs: 'თქვენ უყურებთ როგორც',
      exitViewAs: 'გასვლა',
    },
    Footer: {
      contactUs: 'დაგვიკავშირდით',
      allRightReserved: 'ყველა უფლება დაცულია',
      products: 'პროდუქტი',
      techNews: 'ტექ. სიახლეები',
      changeLanguage: 'ენის შეცვლა',
      projects: 'პროექტები',
      faq: 'FAQ',
      helpCenter: 'დახმარება',
      resources: 'რესურსები',
      pricing: 'ტარიფები',
      contactInfo: 'საკონტაქტო ინფორმაცია',
      hotline: 'ცხელი ხაზი',
    },
    GotInterested: {
      gotInterested: 'ზღვა შესაძლებლობებში ზღვარი არ არსებობს',
      gotInterested1: 'ზღვა შესაძლებლობებში ',
      gotInterested2: 'ზღვარი არ არსებობს',
      description: 'Techwave აერთიანებს საქართველოში წამყვან ტექნოლოგიურ კომპანიებსა და ტალანტებს, შემოგვიერთდი!',
      buttonText: 'რეგისტრაცია',
    },
    CompaniesList: {
      activelyHiring: 'კომპანიები აქტიური ვაკანსიებით',
      moreComingSoon: 'მალე',
    },
    LatestVacancies: {
      latestVacancies: 'ბოლოს ატვირთული ვაკანსიები',
    },
    NoVacancies: {
      noVacancies: 'მიიღე შეტყობინებები ახალი ვაკანსიების შესახებ პირველმა',
      sign_up: 'დარეგისტრირდი და',
    },
    NoCompanies: {
      noCompanies: 'მალე გაგიმხელთ ჩვენს ახალ პარტნიორებს',
      sign_up: 'დარეგისტრირდით ახლავე და გახდი ჩვენი საზოგადოების ნაწილი!',
    },
    TagSearch: {
      stillStruggling: 'გიჭირთ მოძებნა',
      easyWay: 'მარტივი გზა კომპანიისა და ვაკანსიის მოსაძიებლად',
      findwithTechnology: 'ტექნოლოგიით ძიება',
    },
    TypeAheadSearch: {
      resultsFound: 'შედეგები მოიძებნა',
      vacancies: 'ვაკანსიები',
      companies: 'კომპანიები',
      published: 'გამოქვეყნებული',
      deadline: 'ბოლო ვადა',
    },
    AboutUs: {
      aboutCompany: 'კომპანიის შესახებ',
      aboutSoftwareCompany: 'Techwave.ge-ს შესახებ',
      aboutCompanyParagraph:
        'Techwave არის პლატფორმა, რომელიც აერთიანებს ტექ ვაკანსიებსა და ტექ კომპანიის პროფილებს საქართველოში. ეს არის ციფრული სივრცე კარიერული წინსვლისა და ნეთვორქინგისთვის. სწორედ აქ მიიღებთ ქართულ ტექ სექტორზე უახელეს ინფორმაციას. ტექ სამუშაო ბაზართან დაკავშირებით ყველა ინფორმაციის ერთ ადგილზე განთავსებით, ჩვენი მიზანია წვლილი შევიტანოთ ქართული ტექ სექტორის განვითარებაში.',
      companies: 'კომპანია',
      activeJobOppenings: 'ღია ვაკანსიები',
      registeredUsers: 'დარეგისტირირებული მომხმარებლები',
      employedTalent: 'დასაქმებული ტალანტი',
      whatWeDo: 'რას ვაკეთებთ',
      whatWeDoParagraph:
        'Techwave-ზე შესაძლებელია გაეცნოთ ტექ ვაკანსიებს, გადახედოთ ტექ კომპანიების პროფილებს, შეიხედოთ მათ შიდა სამზარეულოში და მიიღოთ ინფორმაცია მათი სამუშაო კულტურის, პროექტებისა და სხვადასხვა უპირატესობების შესახებ.',
      forJobSeekers: 'თუ ეძებთ სამსახურს',
      forJobSeekersParagraph:
        'ტექ ტალანტებისთვის ჩვენ ვამარტივებთ სამსახურის მოძიებისა და კომპანიის შესახებ ინფორმაციის მოძიების პროცესს. Techwave-ზე თქვენ ნახავთ არსებულ ტექ ვაკანსიებს, აღმოაჩენთ საინტერესო ტექ კომპანიებს და შესაძლებლობებს, რომლებიც შეესაბამება თქვენს ინტერესებსა და გამოცდილებებს.',
      forEmployees: 'თუ უკვე დასაქმებული ხართ',
      forEmployeesParagraph:
        'მათთვის, ვინც დაინტერესებულია ქართული ტექ ლანდშაფტით Techwave არის საუკეთესო ადგილი ნეთვორქინგისთვის. აღმოაჩინეთ ახალი ტრენდები, პარტნიორები და გზები კარიერული განვითარებისთვის.',
      forEmployers: 'თუ ეძებთ ტექ ტალანტებს',
      forEmployersParagraph:
        'ტექ კომპანიებს ვაძლევთ საშუალებას, სრულფასოვნად და მიმზიდველად წარმოაჩინონ საკუთარი კომპანია, რათა მოიზიდონ საუკეთესო ტალანტები და შეძლონ ახალი ბიზნეს პარტნიორული კავშირების დამყარება.',
      faq: 'ხშირად დასმული კითხვები',
      joinTheWave: 'შემოუერთდი ტექ ტალღას',
      createCompanyProfile: 'შექმენი კომპანიის პროფილი',
      navigatingSectionTitle: 'ქართული ტექ სექტორი: შესაძლებლობების ზღვა',
      navigatingSectionP1:
        'დაბალი გადასახადებისა და ტექ კომპანიების წამახალისებელი ინიციატივვების წყალობით, ქართული ტექ ბაზარი სწრაფად იზრდება.',
      navigatingSectionP2:
        'ჩვენთვის საქართველოს ტექ სექტორი შესაძლებლობების ზღვაა. ტექ ტალანტებით დაწყებული და ტექ კომპანიებით დამთავრებული, ტექ სფეროთი დაინტერესებულ ყველა პირს Techwave საშუალებას აძლევს მიიღოს ინფორმირებული გადაწყვეტილებები, მიაღწიოს მიზნებს და მეტად განავითაროს მისი ტექ კარიერა.',
      navigatingSectionP3: 'შემოგვიერთდით!',
      severalCompaniesTitle: 'რამოდენიმე კომპანია ჩვენი პლატფორმიდან',
      severalCompaniesP: 'ჩვენ გვენდობა 30+ ტექ კომპანია საქართველოში',
      getAnswersForFAQ: 'მიიღე პასუხები ხშირად დასმულ კითხვებზე',
    },
    BreadCrumbs: {
      home: 'მთავარი',
      aboutUs: 'ჩვენს შესახებ',
      allVacancies: 'ყველა ვაკანსია',
    },
    Vacancies: {
      jobTitle: 'ვაკანსიის დასახელება',
      jobTitleGe: 'ვაკანსიის დასახელება ქართულად',
      vacancies: 'ვაკანსიები',
      type: 'ჩაწერეთ',
      techMust: 'აირჩიეთ მინიმუმ ერთი ტექნოლოგია',
      noActiveJob: 'ამჟამად აქტიური ვაკანსიები არ არის',
      noSavedJobs: 'თქვენ არ გაქვს შენახული ვაკანსიები',
      noJobs: 'ამჟამად ვაკანსიები არ არის',
      deletedFromFavorites: 'ვაკანსია წაიშალა ფავორიტებიდან',
      addedToFavorites: 'დამატებულია ფავორიტებში',
    },
    VacancyPreview: {
      sendApplication: 'რეზიუმეს გაგზავნა',
      sendApplicationApplyNow: 'გაგზავნე ახლა',
      sendApplicationText: 'განაცხადის გაგზავნის მარტივი გზაა ქვემოთ მოცემული ფორმის შევსება',
      subscribePosition: 'გამოიწერეთ ვაკანსია',
      subscribePositionText: 'გამოიწერეთ პოზიცია, რომ მიიღოთ სხვა კომპანიების ვაკანსიები',
      otherVacancies: 'სხვა ვაკანსიები',
      otherVacanciesText: 'მსგავსი ღია პოზიციები სხვა კომპანიებისგან',
      thisFeatureIsOnlyAvailableText: 'ეს ფუნქცია მიუწვდომელია ბიზნეს პროფილებისთვის. გთხოვთ გადახვიდეთ პირად პროფილზე',
      ExpiredNoLongerAccepting: 'ვაკანსია დახურულია ან ვადა გაუვიდა',
      logIn: 'შესვლა',
      forUsers: 'მომხმარებლები',
      iAgreeTo: 'ვეთანხმები',
      terms: 'წესებსა',
      conditions: 'პირობებს',
      vacancyPreviewMode: 'ვაკანსიის გადახედვის რეჟიმი',
      pleaseUploadACV: 'გთხოვთ ატვირთოთ CV',
      previewMode: 'გადახედვის რეჟიმი',
      beforePublishing: 'სანამ გამოაქვეყნებთ',
      saveToDraft: 'შეინახეთ Draft-ში',
      youCanAttach: 'შეგიძლიათ დაურთოთ',
      cvUpload: 'CV ატვირთვა',
      cvRemoved: 'CV წაიშალა',
      applicationHasBeenSent: 'თქვენ წარმატებით გაგზავნეთ რეზიუმე!',
      hasBeenSentSuccessfully: 'ელფოსტა წარმატებით გაიგზავნა',
      hasBeenSentSuccessfullyText:
        'დამადასტურებელი ელფოსტა გაიგზავნა მითითებულ ელფოსტის მისამართზე. გთხოვთ, შეამოწმოთ თქვენი ელფოსტა და მიჰყევით ინსტრუქციას. თუ ელფოსტას რამდენიმე წუთში ვერ დაინახავთ, შეამოწმეთ თქვენი „junk mail“ ან „spam“ ფოლდერი.',
      hasBeenSentSuccessfullyText1: '',
      hasBeenSentSuccessfullyText2: 'ვერიფიკაციის  ',
      hasBeenSentSuccessfullyText3:
        'ელფოსტა გაიგზავნა მითითებულ ელფოსტის მისამართზე. გთხოვთ, შეამოწმოთ თქვენი ელფოსტა და მიჰყევით ინსტრუქციას. თუ ელფოსტას რამდენიმე წუთში ვერ ხედავთ, შეამოწმეთ თქვენი ',
      hasBeenSentSuccessfullyText4: '„junk mail“ ',
      hasBeenSentSuccessfullyText5: 'ან',
      hasBeenSentSuccessfullyText6: ' „spam“ ',
      hasBeenSentSuccessfullyText7: 'საქაღალდე.',
      youNeedToLogIn: 'შედით სისტემაში, სანამ გაგზავნით',
      application: 'აპლიკაციას',
      youNeedToLogInBeforeSub: 'საჭიროებს ავტორიზაციას',
      subscription: 'გამოწერა',
      verification: 'ვერიფიკაცია',
      verificationFullText:
        'ვაკანსიაზე განაცხადის მისაღებად, თქვენი პროფილი უნდა იყოს ვერიფიცირებული. გთხოვთ, მიჰყვეთ დამადასტურებელ ბმულს, რომელიც გაიგზავნება თქვენს ელფოსტაზე.',
      verificationFirstPart: 'ვაკანსიაზე რეზიუმეს გასაგზავნად, თქვენი პროფილი უნდა იყოს ',
      boldVerification: 'ვერიფიცირებული.',
      verificationSecondPart: ' გთხოვთ, მიჰყვეთ დამადასტურებელ ბმულს, რომელიც გაიგზავნება თქვენს ელფოსტაზე.',
    },
    SignIn: {
      welcomeBack: 'კეთილი იყოს თქვენი დაბრუნება',
      signIn: 'შესვლა',
      signInGoogle: 'Google-ით შესვლა',
      signInLinkeding: 'Linkedin-ით შესვლა',
      textOr: 'ან',
      forgotPassword: 'დაგავიწყდათ პაროლი',
      dontHaveAnAccount: 'არ გაქვს ანგარიში',
    },
    SignUp: {
      pleaseFollowSteps: 'გთხოვთ მიჰყევით ინსტრუქციას',
      personalD: 'პირადი',
      companyD: 'კომპანიის',
      details: 'დეტალები',
      experience: 'გამოცდილება',
      profile: 'პროფილი',
      personal: 'პირადი',
      business: 'ბიზნესი',
      fullName: 'სრული სახელი',
      fullNameError: 'სახელი და გვარი არ არის სწორ ფორმატში.',
      fullNameLanguageError: 'შეიყვანეთ სახელი და გვარი ქართულ ან ინგლისურ ენაზე.',
      fullNameMinLengthError: 'სრული სახელი უნდა შეიცავდეს მინიმუმ 2 სიმბოლოს.',
      fullNameMaxLengthError: 'სრული სახელი უნდა შეიცავდეს მაქსიმუმ 50 სიმბოლოს.',
      CompanyNameError: 'კომპანიის სახელი არ არის სწორ ფორმატში.',
      CompanyNameLanguageError: 'შეიყვანეთ კომპანიის სახელი ინგლისურ ენაზე.',
      CompanyNameMinLengthError: 'კომპანიის სახელი უნდა შეიცავდეს მინიმუმ 2 სიმბოლოს.',
      CompanyNameMaxLengthError: 'კომპანიის სახელი უნდა შეიცავდეს მაქსიმუმ 50 სიმბოლოს.',
      passwordRequired: 'მეილი აუცილებელია.',
      emailWrongFormat: 'მეილის ფორმატი არასწორია.',
      emailRequired: 'ელფოსტა აუცილებელია.',
      confirmPassword: 'პაროლის დადასტურება',
      nextStep: 'შემდეგი ეტაპი',
      alreadyHaveAnAccount: 'უკვე გაქვთ ანგარიში',
      companyName: 'კომპანიის სახელი',
      passwordRequirements: 'პაროლის მოთხოვნები',
      passwordRequirementText:
        'პაროლი უნდა შედგებოდეს 8-16 ნიშნისგან და შეიცავდეს მინიმუმ 1 დიდი შრიფტის ასოს, 1 ციფრს და 1 სპეციალურ სიმბოლოს.',
      mustContainUpperCaseLetter: 'უნდა შეიცავავდეს დიდ ლათინურ ასოს',
      mustContainLowerCaseletter: 'უნდა შეიცავავდეს პატარა ლათინურ ასოს',
      mustContainNumber: 'უნდა შეიცავდეს ციფრს',
      mustContainSpecialCharacter: 'უნდა შეიცავდეს სიმბოლოს',
      mustBeNCharactersLong: 'უნდა იყოს 8-16 სიმბოლო',
      mustMatchConfirmPassword: 'გაიმეორეთ პაროლი',
      setCompanyLogo: 'დააყენეთ კომპანიის ლოგო',
      pleaseUploadThephoto: 'გთხოვთ ატვირთეთ ფოტო',
      openForNewChallenges: 'ახლა თქვენ მზად ხართ ახალი გამოწვევებისთვის',
      openToRecruitEmployees: 'ახლა თქვენ მზად ხართ თანამშრომლების ასაყვანად',
      field: 'სფერო',
      selectField: 'აირჩიეთ სფერო',
      selectExp: 'აირჩიეთ გამოცდილება',
      chooseTechnology: 'აირჩიეთ ტექნოლოგია',
      type: 'ჩაწერეთ',
      setProfilePicture: 'დააყენეთ პროფილის ფოტო',
    },
    PasswordRecovery: {
      passwordRecovery: 'პაროლის აღდგენა',
      setNewPassword: 'ახალი პაროლის დაყენება',
      pleaseFillEmailToRecoverYouPassword: 'გთხოვთ, შეავსოთ ელფოსტა თქვენი პაროლის აღსადგენად',
      passwordHasBeenChanged: 'პაროლი შეცვლილია',
      newPassword: 'ახალი პაროლის დაყენება',
      confirmNewPassword: 'დაადასტურეთ ახალი პაროლი',
      passwordHasBeenSent: 'გთხოვთ შეამოწმოთ თქვენი მეილი',
    },
    Statistics: {
      profileVisits: 'ვიზიტები პროფილზე',
      vacancyVisits: 'ვიზიტები ვაკანსიაზე',
      applications: 'განაცხადები',
      totalVisits: 'ჯამური ვიზიტები',
      totalApplications: 'ჯამური განაცხადები',
      receivedApplications: 'მიღებული განაცხადები',
      format: 'ფორმატი',
      weeks: 'კვირები',
      months: 'თვეები',
      years: 'წლები',
      week: 'კვირა',
      month: 'თვე',
      year: 'წელი',
      statistics: 'სტატისტიკა',
      thisWeek: 'ამ კვირაში',
      thisMonth: 'ამ თვეში',
      thisYear: 'ამ წელს',
    },
    UserPanel: {
      filteredJobSubscriptions: 'გამოწერილი ფილტრები',
      notificationFrequency: 'გამოწერის სიხშირე',
      deleteAccount: 'პროფილის წაშლა',
      signInNSecurity: 'ავტორიზაცია და უსაფრთხოება',
      account: 'პროფილი',
      deletionWarrning1:
        'თქვენი ანგარიშის წაშლის დადასტურებისთანავე, თქვენი პროფილი და ინფორმაცია სამუდამოდ წაიშლება. ეს',
      deletionWarrningBold: 'შეუქცევადი',
      deletionWarrning2: 'ქმედებაა.',
    },
    Blog: {
      latestArticles: 'ბოლო სტატიები',
      publishedOn: 'გამოქვეყნებულია',
      subscribeBlog: 'გამოიწერეთ ჩვენი სიახლე',
      subscribeBlogText: 'გამოიწერეთ სიახლე რომ მიიღოთ',
      shareBlog: 'სიახლის გაზიარება',
      FindInBlogs: 'ძიება სიახლეებში',
      subscribeBlogSubtitle: 'გამოიწერეთ ბლოგი, რომ მიიღოთ უახლესი სტატიები და კომპანიის პოსტები',
      subscriptionTerms:
        '“News"-ის გამოწერით თქვენ თანხმობას აძლევთ Techwave-ს, ელფოსტით გამოგიგზავნოთ ინფორმაციული და საინტერესო შინაარსის კონტენტი',
      termsCheckboxError: 'გთხოვთ დაეთანხმოთ მეილების მიღებას "Flat Rock Technology"-სგან',
      youAreAlreadyUnsub: 'გამოწერა გაუქმებულია',
      pleaseFillEmailField: 'გთხოვთ შეავსოთ მეილის ველი',
    },
    Compare: {
      compare: 'შედარება',
      chooseAndCompareCompanies: 'არჩიე და შეადარე ტექ კომპანიები',
      companyCompareSubtitle: 'მონიშნე ნებისმიერი ტექ კომპანია და ნახე როგორ შეედრება იგი სხვას',
      type: 'დაწერე',
      popularComparisions: 'პოპულარული შედარებები',
      plaseChooseAtLeastOneCompany: 'გთხოვთ აირჩიოთ მინიმუმ ერთი კომპანია!',
      anyCompany: 'ნებისმიერ კომპანია',
      seeWhatsDifference: 'ნახე როგორ განსხვავდება ',
      vs: '',
      seeWhatsDifferenceText: '-ში მუშაობა ',
      seeWhatsDifferenceText2:
        '-ში მუშაობისგან. შეადარე მნიშვნელოვანი ფატქორები: ბენეფიტები; tech stack; ღია პოზიციები და სხვ. მიიღე მეტი ინფორმაცია თითოეულ კომპანიაზე და გააკეთე განაცხადი შენთვის საინტერესო ვაკანსიაზე.',
      seeWhatsDifferenceText3: '',
      companySize: 'გუნდის ზომა',
      viewCompany: 'კომპანიის ნახვა',
    },
    talent: {
      talentDetails: 'ტალანტის დეტალები',
      techStack: 'ტექ. სტეკი',
      downloadCv: 'სივის გადმოწერა',
      appliedTalentsView: 'ვაკანსიის აპლიკანტები',
      applied: 'გაგზავნილია',
      appliedJobs: 'გაგზავნილი',
      savedJobs: 'შენახული',
    },
    faq: {
      forEmployers: 'დამსაქმებლებისთვის',
      forTalents: 'ტალანტებისთვის',
      general: 'ზოგადი',
      faqSubtitle: 'ყველაფერი, რაც უნდა იცოდეთ პროდუქტისა და ანგარიშსწორების შესახებ',
    },
    reviews: {
      reviews: 'შეფასებები',
      review: 'შეფასება',
      writeAReview: 'შეაფასე',
      rateCompany: 'შეაფასე კომპანია',
      itOnlyTakesMinute: 'მხოლოდ ერთ წუთს წაგართმევს! შენი ანონიმური შეფასება კი დაეხმარება სამსახურის მაძიებლებს.',
      overallCompanyRating: 'კომპანიის საბოლოო შეფასება',
      reviewHeadline: 'შეფასების სათაური',
      yourHonestFeedback: 'გულწრფელი უკუკავშირი ძალიან მნიშვნელოვანია',
      feedback:
        'უკუკავშირი ფასდაუდებელია ჩვენი პლატფორმისა და მომხმარებლებისთვის. შენი შეფასების დახმარებით სხვები შეძლებენ მიიღონ პოტენციურ დამსაქმებლებსა და კარიერულ არჩევანთან დაკავშირებული ინფორმირებული გადაწყვეტილებები.',
      respectfulToneTitle: 'გთხოვთ, შეინარჩუნოთ თავაზიანი ტონი და თავი შეიკავოთ:',
      respectfulToneLi1: 'აგრესიული ან დისკრიმინაციული ენის გამოყენებისგან',
      respectfulToneLi2: 'უხამსი სიტყვების ხმარებისგან',
      respectfulToneLi3: 'კონფიდენციალური ინფორმაციის ან სავაჭრო საიდუმლოების გაზიარებისგან',
      yourCooperation:
        'თქვენი თანამშრომლობა აუცილებელია იმისათვის, რომ ჩვენმა პლატფორმამ შეინარჩუნოს საკუთარი იდენტობა, როგორც სანდო წყარომ ვაკანციებისა და კომპანიების შესახებ. მადლობა კონტრიბუციისთვის!',
      statusOfReview: 'მიმოხილვის სტატუსი',
      displayAsAnonymous: 'ანონიმურად ჩვენება',
      displayActualDetails: 'ჩემი რეალური დეტალების ჩვენება',
      keepInMind:
        'გაითვალისწინე, რომ ჩვენ არ ვაპირებთ სრული სახელის გასაჯაროებას. საჯარო იქნება მხოლოდ პროფილის სურათი, თანამდებობა და კომპანიაში მუშაობის ხანგრძლივობა.',
      reviewDescription: 'შეჯამებითი მიმოხილვა',
      typeOverallReview: 'დაწერე შეჯამებითი მიმოხილვა',
      companyPros: 'კომპანიის უპირატესობები',
      companyCons: 'კომპანიის უარყოფითი მხარეები',
      shareBestReasons: 'ჩამოთვალე საუკეთესო მიზეზები, რომელთა გამოც ღირს მუშაობა',
      inCompany: '-ში',
      shareDownsides1: 'გაგვიზიარე',
      shareDownsides2: '-ში მუშაობის უარყოფითი მხარეები',
      adviceForManagement: 'რჩევები მენეჯმენტს',
      shareManagementSuggestions: 'შემოგვთავაზე რა პირობები უნდა გააუმჯობესოს მენეჯმენტმა',
      bestAndWorstBenefits1: 'რა არის ყველაზე კარგი/ცუდი რამ',
      bestAndWorstBenefits2: '-ის ბენეფიტების პაკეტში?',
      companyPerks:
        'გაგვიზიარე შთაბეჭდილებები ისეთ საკითხებზე, როგორიცაა კომპანიის ბონუსები, ჯანმრთელობის დაზღვევა, 401k, შვებულება, დასვენების დრო, ა.შ.',
      iAgreeTerms: 'ვეთანხმები Techwave-ის პირობებს',
      submitReview: 'მიმოხილვის გაგზავნა',
      cons: 'უარყოფითი მხარეები',
      pros: 'უპირატესობები',
      showMore: 'მეტის ჩვენება',
      showLess: 'ნაკლების ჩვენება',
      helpful: 'სასარგებლო',
      myReviews: 'ჩემი შეფასებები',
      noReviewsYet: 'შეფასებები არ არის',
    },
  },
};
