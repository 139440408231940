import { RiCloseFill } from 'react-icons/ri';
import React, { useEffect } from 'react';
import classes from './styles.module.sass';

const Modal = (props: {
  visible: boolean;
  children: React.ReactNode;
  action: string;
  subTitle?: string;
  actionIcon?: React.ReactElement;
  onClick: () => void;
  id?: string;
  hideDivider?: boolean;
  actionColor?: 'red';
}) => {
  useEffect(() => {
    if (props.visible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [props.visible]);

  return (
    <>
      {props.visible && (
        <div
          className={`${classes.modal} ${!props.visible && classes.hidden}`}
          // className={classes.modal}
          id={props.id}
        >
          <div className={classes.spareArea} onClick={props.onClick}></div>
          <div className={classes.container}>
            <div className={classes.heading}>
              <div className={classes.actionType}>
                {props.actionIcon}
                <div className={`${classes.titleContainer} ${props.actionColor && classes[props.actionColor]}`}>
                  {props.action}
                  <div className={classes.subTitle}>{props.subTitle}</div>
                </div>
              </div>
              <span className={classes.close_icon} onClick={props.onClick}>
                <RiCloseFill />
              </span>
            </div>
            {!props.hideDivider && <div className={classes.divider}></div>}
            <div className={classes.children}>{props.children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
