import React, { lazy, ReactElement, Suspense, useEffect } from 'react';
import { Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../Redux/store';
import { UserActivePageEnum, UserTypeEnum } from '../Api';
import FullPageLoader from '../Components/UI/FullPageLoader/FullPageLoader';
import { ContentDataType } from '../Api/ContentData';
import SocialIntegrations from '../helpers/socialIntegration';
import AboutCompanyDetails from '../Pages/AboutCompany/AboutCompanyDetails/AboutCompanyDetails';

const HomePage = lazy(() => import(/* webpackChunkName: "home-page" */ '../Pages/HomePage/HomePage'));
const PasswordRecovery = lazy(
  () => import(/* webpackChunkName: "password-recovery" */ '../Pages/PasswordRecovery/PasswordRecovery')
);

const SignIn = lazy(() => import(/* webpackChunkName: "sign-in" */ '../Pages/SignIn/SignIn'));
const SignUpPage = lazy(() => import(/* webpackChunkName: "sign-up-page" */ '../Pages/SignUpPage/SignUpPage'));
const AboutUs = lazy(() => import(/* webpackChunkName: "about-us" */ '../Pages/AboutUs/AboutUs'));
const Companies = lazy(() => import(/* webpackChunkName: "companies" */ '../Pages/Companies/Companies'));
const Information = lazy(() => import(/* webpackChunkName: "information" */ '../Pages/Information/Information'));
const NotFound = lazy(() => import(/* webpackChunkName: "not-found" */ '../Pages/NotFound/NotFound'));
const Search = lazy(() => import(/* webpackChunkName: "search" */ '../Pages/Search/Search'));
const CompanyPanel = lazy(() => import(/* webpackChunkName: "company-panel" */ '../Pages/CompanyPanel/CompanyPanel'));
const AboutCompany = lazy(() => import(/* webpackChunkName: "about-company" */ '../Pages/AboutCompany/AboutCompany'));
const Technologies = lazy(
  () =>
    import(
      /* webpackChunkName: "general-information" */ '../Pages/AboutCompany/AboutCompanyPages/Technologies/Technologies'
    )
);
const Blog = lazy(() => import(/* webpackChunkName: "blog" */ '../Pages/Blog/Blog'));
const BlogArticle = lazy(() => import(/* webpackChunkName: "blogArticle" */ '../Pages/BlogArticle/BlogArticle'));
const UserGeneralInformation = lazy(
  () =>
    import(
      /* webpackChunkName: "user-general-information" */ '../Pages/userPanel/components/UserGeneralInformation/UserGeneralInformation'
    )
);
const UserPanel = lazy(() => import(/* webpackChunkName: "user-panel" */ '../Pages/userPanel/UserPanel'));
const Vacancies = lazy(
  () => import(/* webpackChunkName: "vacancies" */ '../Pages/CompanyPanel/components/Vacancies/Vacancies')
);
const PasswordChange = lazy(
  () => import(/* webpackChunkName: "password-change" */ '../Components/UI/PasswordChange/PasswordChange')
);
const CompanyVacancy = lazy(
  () =>
    import(
      /* webpackChunkName: "company-vacancy" */ '../Pages/CompanyPanel/components/Vacancies/components/CompanyVacancy/CompanyVacancy'
    )
);
const VacanciesPage = lazy(
  () => import(/* webpackChunkName: "vacancies-page" */ '../Pages/VacanciesPage/VacanciesPage')
);

const VacancyDetails = lazy(
  () => import(/* webpackChunkName: "vacancy-details" */ '../Pages/VacancyDetails/VacancyDetails')
);
const UserSubscriptions = lazy(
  () =>
    import(
      /* webpackChunkName: "user-subscriptions" */ '../Pages/userPanel/components/UserSubscriptions/UserSubscriptions'
    )
);
const Jobs = lazy(() => import(/* webpackChunkName: "user-jobs" */ '../Pages/userPanel/components/Jobs/Jobs'));
const Welcome = lazy(() => import(/* webpackChunkName: "welcome" */ '../Pages/Welcome/Welcome'));
const ContextData = lazy(
  () => import(/* webpackChunkName: "context-data" */ '../Pages/Information/Components/ContextData/ContextData')
);

const Partners = lazy(() => import(/* webpackChunkName: "context-data" */ '../Pages/Partners/Partners'));

const Statistics = lazy(
  () => import(/* webpackChunkName: "general-information" */ '../Pages/CompanyPanel/components/Statistics/Statistics')
);
const Benefits = lazy(
  () =>
    import(/* webpackChunkName: "general-information" */ '../Pages/AboutCompany/AboutCompanyPages/Benefits/Benefits')
);
const Compare = lazy(() => import(/* webpackChunkName: "compareCompany" */ '../Pages/Compare/Compare'));
const CompareCompany = lazy(
  () => import(/* webpackChunkName: "compareCompany" */ '../Pages/CompareCompany/CompareCompany')
);
const ApplicantDetails = lazy(
  () => import(/* webpackChunkName: "general-information" */ '../Pages/ApplicantDetails/ApplicantDetails')
);
const Unsubscribe = lazy(() => import(/* webpackChunkName: "unsubscribe" */ '../Pages/Unsubscribe/Unsubscribe'));
const DeleteAccount = lazy(
  () => import(/* webpackChunkName: "delete-account" */ '../Pages/DeleteAccount/DeleteAccount')
);
const UserSettings = lazy(
  () => import(/* webpackChunkName: "user-settings" */ '../Components/UI/UserSettings/UserSettings')
);
const FAQ = lazy(() => import(/* webpackChunkName: "faq"  */ '../Pages/FAQ/FAQ'));
const Reviews = lazy(
  () => import(/* webpackChunkName: "Reviews"  */ '../Pages/AboutCompany/AboutCompanyPages/Reviews/Reviews')
);
const CreateReview = lazy(
  () =>
    import(/* webpackChunkName: "CreateReview"  */ '../Pages/AboutCompany/AboutCompanyPages/CreateReview/CreateReview')
);
const SavedTalents = lazy(
  () => import(/* webpackChunkName: "saved-talents" */ '../Pages/CompanyPanel/components/SavedTalents/SavedTalents')
);

interface ProtectedRouteProps {
  isAllowed: boolean;
  redirectPath?: string;
  children?: ReactElement;
}

const ProtectedRoute = ({ isAllowed = false, redirectPath = '/', children }: ProtectedRouteProps) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }
  return children ? children : <Outlet />;
};

const Routing: React.FC = () => {
  const { isLoggedIn, userType, email } = useSelector((state: RootState) => state.auth);
  const verified = !!localStorage.getItem('Email');
  const location = useLocation();

  useEffect(() => {
    SocialIntegrations('Routing', email, { role: userType === 1 ? 'User' : 'Company' });
  }, [location]);

  return (
    <div style={{ flexGrow: 1 }}>
      <Suspense fallback={<FullPageLoader />}>
        <Routes>
          <Route path='/' element={<HomePage page={UserActivePageEnum.LandingPage} />} />
          <Route
            path='/signUp'
            element={
              <ProtectedRoute isAllowed={isLoggedIn === false} redirectPath='/'>
                <SignUpPage />
              </ProtectedRoute>
            }
          />

          <Route path='/welcome' element={<Welcome />} />

          <Route
            path='/signIn'
            element={
              <ProtectedRoute isAllowed={isLoggedIn === false} redirectPath='/'>
                <SignIn />
              </ProtectedRoute>
            }
          />

          <Route
            path='/passwordRecovery'
            element={
              <ProtectedRoute isAllowed={isLoggedIn === false} redirectPath='/'>
                <PasswordRecovery />
              </ProtectedRoute>
            }
          >
            <Route path=':status' />
          </Route>

          <Route path='/compare' element={<Compare />} />
          <Route path='/compare/:compareUrl' element={<CompareCompany />} />

          <Route path='/search' element={<Search />} />

          <Route path='/companies' element={<Companies page={UserActivePageEnum.CompaniesSearch} />} />

          <Route path='/news' element={<Blog />} />
          <Route path='/news/:id' element={<BlogArticle />} />

          {/* this is new route */}
          <Route path='/:id' element={<AboutCompany page={UserActivePageEnum.CompanyPage} />}>
            <Route path='' element={<AboutCompanyDetails />} />
            <Route path='benefits' element={<Benefits />} />
            <Route path='technologies' element={<Technologies />} />
            <Route
              path='vacancies'
              element={<VacanciesPage companyView={true} page={UserActivePageEnum.VacanciesSearch} />}
            />
            <Route path='reviews' element={<Reviews />} />
            <Route path='reviews/createReview' element={<CreateReview />} />
          </Route>
          {/* till here */}

          {/* this is old route and must be deleted */}
          <Route path='/companies/:id' element={<AboutCompany page={UserActivePageEnum.CompanyPage} />}>
            <Route path='generalInformation' element={<AboutCompanyDetails />} />
            <Route path='benefits' element={<Benefits />} />
            <Route path='technologies' element={<Technologies />} />
            <Route
              path='vacancies'
              element={<VacanciesPage companyView={true} page={UserActivePageEnum.VacanciesSearch} />}
            />
          </Route>
          {/* till here */}

          <Route
            path='/companies/:id/vacancies'
            element={<VacanciesPage companyView={true} page={UserActivePageEnum.VacanciesSearch} />}
          />
          <Route path='/unsubscribe' element={<Unsubscribe />} />

          <Route
            path='/vacancies'
            element={<VacanciesPage companyView={false} page={UserActivePageEnum.VacanciesSearch} />}
          />
          <Route path='/vacancies/:id' element={<VacancyDetails page={UserActivePageEnum.VacancyPage} />} />
          <Route path='/:companyId/vacancies/:id' element={<VacancyDetails page={UserActivePageEnum.VacancyPage} />} />

          <Route path='/about' element={<AboutUs />} />
          <Route path='/faq' element={<FAQ />} />

          {/* this is new routes */}
          <Route
            path='/privacyPolicy'
            element={
              <Information>
                <ContextData contentDataType={ContentDataType.PrivacyPolicy} />
              </Information>
            }
          />
          <Route
            path='/terms-of-use'
            element={
              <Information>
                <ContextData contentDataType={ContentDataType.TermsOfUse} />
              </Information>
            }
          />
          {/* till here */}

          <Route path='/information' element={<Information />}>
            {/* this two must be deleted soon */}
            <Route path='privacyPolicy' element={<ContextData contentDataType={ContentDataType.PrivacyPolicy} />} />
            <Route path='terms-of-use' element={<ContextData contentDataType={ContentDataType.TermsOfUse} />} />
            {/* till here */}
            <Route path='support' element={<ContextData contentDataType={ContentDataType.Support} />} />
          </Route>

          <Route
            path='/companyPanel'
            element={
              <ProtectedRoute isAllowed={isLoggedIn === true} redirectPath='/signIn'>
                <CompanyPanel />
              </ProtectedRoute>
            }
          >
            <Route path='generalInformation' element={<AboutCompanyDetails />} />
            <Route path='statistics' element={<Statistics />} />
            <Route path='benefits' element={<Benefits />} />
            <Route path='technologies' element={<Technologies />} />
            <Route path='saved-talents' element={<SavedTalents />} />
            <Route path='settings' element={<UserSettings />}>
              <Route path='passwordChange' element={<PasswordChange />} />
            </Route>
            <Route
              path='vacancies/active'
              element={
                <ProtectedRoute isAllowed={true} redirectPath='/companyPanel/generalInformation'>
                  <Vacancies types={[1]} page={UserActivePageEnum.VacanciesSearch} />
                </ProtectedRoute>
              }
            />
            <Route
              path='vacancies/ended'
              element={
                <ProtectedRoute isAllowed={true} redirectPath='/companyPanel/generalInformation'>
                  <Vacancies types={[2]} page={UserActivePageEnum.VacanciesSearch} />
                </ProtectedRoute>
              }
            />
            <Route
              path='vacancies/drafts'
              element={
                <ProtectedRoute isAllowed={true} redirectPath='/companyPanel/generalInformation'>
                  <Vacancies types={[3]} page={UserActivePageEnum.VacanciesSearch} />
                </ProtectedRoute>
              }
            />
            <Route
              path='vacancies/create'
              element={
                <ProtectedRoute isAllowed={true} redirectPath='/companyPanel/generalInformation'>
                  <CompanyVacancy />
                </ProtectedRoute>
              }
            />
            <Route
              path='vacancies/update/:vacancyId'
              element={
                <ProtectedRoute isAllowed={true} redirectPath='/companyPanel/generalInformation'>
                  <CompanyVacancy />
                </ProtectedRoute>
              }
            />
            <Route path='vacancies' element={<Navigate to='active' />} />
            <Route
              path='vacancies/vacancyDetails/:vacancyTransformedTitle'
              element={
                <ProtectedRoute isAllowed={true} redirectPath='/companyPanel/generalInformation'>
                  <ApplicantDetails />
                </ProtectedRoute>
              }
            />
            <Route path='vacancies/vacancyDetails' element={<Navigate replace to='/companyPanel/vacancies' />} />
          </Route>

          <Route
            path='/userPanel'
            element={
              <ProtectedRoute isAllowed={isLoggedIn === true && userType == UserTypeEnum.User} redirectPath='/signIn'>
                <UserPanel />
              </ProtectedRoute>
            }
          >
            <Route path='generalInformation' element={<UserGeneralInformation />} />
            <Route path='jobs' element={<Jobs />} />
            <Route path='user-subscriptions' element={<UserSubscriptions />} />
            <Route path='settings' element={<UserSettings />}>
              <Route path='passwordChange' element={<PasswordChange />} />
              <Route path='account' element={<DeleteAccount />} />
            </Route>
          </Route>

          <Route path='/Partners' element={<Partners />} />

          <Route path='*' element={<NotFound />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default Routing;
