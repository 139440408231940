import axios from 'axios';
import {
  CreateUpdateSubscriptionsParams,
  SubscribeToCompanyRequestParams,
  updateUserProfileInfoRequestParams,
  UpdateUserSubscribedCompaniesRequestParams,
  UpdateUserSubscribedJobFunctionsRequestParams,
} from './params';
import {
  GetUserSubscribedJobFunctionsType,
  postAddRemoveFavoriteVacancyRequestType,
  UserSubscribedCompaniesType,
} from './types';

const url = process.env.REACT_APP_API_URL;

export const getUserProfileInfoRequest = () => {
  return axios.get(`${url}api/Users/GetUserProfileInfo`, {
    headers: {},
  });
};

export const postUserProfileInfoRequest = (updateUserProfileInfoRequestParams: updateUserProfileInfoRequestParams) => {
  return axios.post(`${url}api/Users/UpdateUserProfileInfo`, updateUserProfileInfoRequestParams, {
    headers: {},
  });
};

export const postUsersTechnologiesRequest = (technologyIds: string[]) => {
  return axios.post(
    `${url}api/Users/UpdateUserTechnologies`,
    {
      technologyIds: technologyIds,
    },
    {
      headers: {},
    }
  );
};

export const getUserSubscribedCompaniesRequest = () => {
  return axios.get<UserSubscribedCompaniesType>(`${url}api/Users/GetUserSubscribedCompanies`, {
    headers: {},
  });
};

export const postUpdateUserSubscribedCompaniesRequest = (params: UpdateUserSubscribedCompaniesRequestParams) => {
  return axios.post(`${url}api/Users/UpdateUserSubscribedCompanies`, params.body, {
    headers: {},
  });
};

export const postSubscribeToCompanyRequest = (params: SubscribeToCompanyRequestParams) => {
  return axios.post(`${url}api/Users/SubscribeToCompany?companyId=${params.body.companyId}`);
};

export const getUserSubscribedJobFunctionsRequest = () => {
  return axios.get<GetUserSubscribedJobFunctionsType>(`${url}api/Users/GetUserSubscribedJobFunctions`, {
    headers: {},
  });
};

export const postUpdateUserSubscribedJobFunctionsRequest = (params: UpdateUserSubscribedJobFunctionsRequestParams) => {
  return axios.post(`${url}api/Users/UpdateUserSubscribedJobFunctions`, params.body, {
    headers: {},
  });
};

export const postApplyToVacancyRequest = (formData: FormData) => {
  return axios.post(`${url}api/Users/ApplyToVacancy`, formData, {
    headers: {},
  });
};

export const postUploadCvRequest = (formData: FormData) => {
  return axios.post(`${url}api/Users/UploadCv`, formData, {
    headers: {},
  });
};

export const postRemoveCvRequest = () => {
  return axios.post(`${url}api/Users/RemoveCv`, null, {
    headers: {},
  });
};

export const getUserSubscriptionsRequest = () => {
  return axios.get(`${url}api/Users/GetUserSubscriptions`);
};

export const postCreateUpdateSubscriptionsRequest = (data: CreateUpdateSubscriptionsParams) => {
  return axios.post(`${url}api/Users/CreateUpdateSubscriptions`, data);
};

export const postDeleteSubscriptionRequest = (id: string) => {
  return axios.post(`${url}api/Users/DeleteSubscription?subscriptionId=${id}`);
};

export const getTalentApplicationsRequest = () => {
  return axios.get(`${url}api/vacancy/getTalentApplications`);
};

export const postAddRemoveFavoriteVacancyRequest = (vacancyData: postAddRemoveFavoriteVacancyRequestType) => {
  return axios.post(`${url}api/Users/AddRemoveFavoriteVacancy`, vacancyData);
};

export const getFavoriteVacanciesRequest = (queryString: string) => {
  return axios.get(`${url}api/Users/getFavoriteVacancies${queryString}`);
};
