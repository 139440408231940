import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import * as Api from '../../Api';

interface SmartSearchState {
  readonly isLoading: boolean;
}

const state: SmartSearchState = {
  isLoading: false,
};

export const getSmartSearch = createAsyncThunk<Api.SmartSearchType, Api.getSmartSearchParams>(
  'search/getSmartSearch',
  async (params: Api.getSmartSearchParams, thunkApi) => {
    try {
      let searchParameters = '';
      params.args.SearchTerm.map((x) => (searchParameters += `&SearchTerm=${x}`));
      const response = await Api.getSmartSearchRequest(params, searchParameters);
      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }

      throw err;
    }
  }
);

export const smartSearchSlice = createSlice({
  name: 'smartSearch',
  initialState: state,
  reducers: {
    updateIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSmartSearch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSmartSearch.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getSmartSearch.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { updateIsLoading } = smartSearchSlice.actions;

export default smartSearchSlice.reducer;
