import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import * as Api from '../../Api';

export interface LanguageState {
  SelectedLanguage: string;
}

const initialState: LanguageState = {
  SelectedLanguage: localStorage.getItem('lang') ? localStorage.getItem('lang')! : '',
};

export const getLanguage = createAsyncThunk<string>('account/getDefaultLanguage', async (_, thunkApi) => {
  try {
    const response = await Api.getLanguageRequest();
    return response.data;
  } catch (err) {
    const error = err;
    if (error) {
      return thunkApi.rejectWithValue((error as AxiosError).response?.data);
    }

    throw err;
  }
});

export const LanguageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    changeLanguage: (state, action: PayloadAction<'en-US' | 'ka-GE'>) => {
      const langCode = action.payload === 'en-US' ? 'en' : 'ka';
      // @ts-ignore
      axios.defaults.headers['accept-language'] = langCode;
      state.SelectedLanguage = action.payload;
      localStorage.setItem('lang', action.payload);
    },
  },
});

export const { changeLanguage } = LanguageSlice.actions;
export default LanguageSlice.reducer;
