import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import * as Api from '../../Api';

interface PasswordChangeSliceSliceState {
  readonly isLoading: boolean;
}

const passwordChangeSliceState: PasswordChangeSliceSliceState = {
  isLoading: false,
};

export const postPasswordChange = createAsyncThunk<Api.ResponseStatusType, Api.postPasswordChangeRequestParams>(
  'account/PasswordChange',
  async (params: Api.postPasswordChangeRequestParams, thunkApi) => {
    try {
      const response = await Api.postPasswordChangeRequest(params);
      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }

      throw error;
    }
  }
);

export const passwordChangeSlice = createSlice({
  name: 'passwordChangeSlice',
  initialState: passwordChangeSliceState,
  reducers: {
    updateIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postPasswordChange.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postPasswordChange.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(postPasswordChange.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { updateIsLoading } = passwordChangeSlice.actions;

export default passwordChangeSlice.reducer;
