import { Field } from 'formik';
import React, { useState } from 'react';
import classes from './styles.module.sass';
import { ReactComponent as PassRevealIcon } from '../../../../Assets/Icons/passReveal.svg';
interface valueProps {
  labelName?: string;
  valueName?: string;
  withoutLabel?: boolean;
  required?: boolean;
  error?: string;
  helperText?: string;
  touched?: boolean;
  value?: string;
  type?: string;
  onKeyUp?(): void;
  autoComplete?: string;
  capitalize?: boolean;
  boldText?: boolean;
  width?: string;
  margin?: string;
  component?: string;
  rows?: string;
  hideRuquiredStar?: boolean;
  placeholder?: string;
  maxLength?: number;
  onClick?(): void;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  onFocus?(): void;
  onBlur?(e: React.ChangeEvent<HTMLInputElement>): void;
  className?: string;
  validate?(value: string): void;
  disabled?: boolean;
}

export default function FormInputFieldValidation({
  labelName,
  value,
  valueName,
  required = false,
  error,
  helperText,
  type = 'text',
  autoComplete,
  capitalize = false,
  boldText = false,
  width,
  margin,
  component,
  rows,
  className,
  withoutLabel,
  validate,
  disabled,
  maxLength,
  ...props
}: valueProps) {
  const [showPass, setShowPass] = useState(false);

  return (
    <>
      <div className={`${classes.inputField} ${value ? classes.input_active : ''} ${className}`}>
        <Field
          validate={validate}
          name={valueName}
          type={showPass ? 'text' : type}
          id={valueName}
          className={`${error ? classes.errorField : ''} ${capitalize ? classes.capitalize : ''}  ${
            boldText ? classes.text_bold : ''
          }`}
          autoComplete={autoComplete}
          component={component}
          rows={rows}
          maxLength={maxLength}
          style={{ width: `${width}%`, margin: `${margin}px 0px` }}
          value={value}
          disabled={disabled}
          {...props}
        />
        {!withoutLabel && (
          <>
            {value ? (
              type !== 'textarea' && (
                <label htmlFor={valueName}>
                  <div className={`${classes.required_small}`}>{labelName}</div>
                </label>
              )
            ) : (
              <div className={`${classes.placeholder}`}>
                {labelName}
                {required && <span>*</span>}
              </div>
            )}
          </>
        )}
        {type === 'password' && (
          <PassRevealIcon className={`${classes.pass_reveal}`} onClick={() => setShowPass((prev) => !prev)} />
        )}
        <div className={`${classes.helperText} ${error ? classes.required : ''}`}>
          {helperText ? helperText : error}
        </div>
      </div>
    </>
  );
}
