import { ResponseNotificationEnum } from '../GlobalTypes';

export interface DictionaryListItemType {
  id: string;
  name: string;
  nameGE: string;
  description: string | null;
  descriptionGE: string | null;
  iconUrl: string | null;
  activeVacancyCount: number;
  activeCompanyCount: number;
  parentDictionary: {
    dictionaryName: string;
    id: string;
    name: string;
    nameGE: string;
  } | null;
  childDictionaries: Array<{
    dictionaryName: string;
    id: string;
    name: string;
    nameGE: string;
    activeVacancyCount: number;
    activeCompanyCount: number;
    iconUrl: string | null;
    isSelected: boolean;
  }>;
}

export interface DictionaryType {
  readonly dictionaryName: string;
  readonly dictionaryType: number;
  dictionaryList: Array<DictionaryListItemType>;
}

export interface CompanyType {
  readonly id: string;
  readonly name: string;
  readonly smallProfileImageUrl: string;
  readonly technologies: DictionaryType[];
  readonly teamSize: number;
  readonly activeVacancyCount: number;
  readonly totalPages: number;
  readonly data: [];
  readonly transformedName: string;
}

export interface ResponseModel<T> {
  data: T;
  statusCode: number;
  hasError: boolean;
  logNotifications: ResponseNotification[];
  displayNotifications: ResponseNotification[];
}

export interface ResponseNotification {
  type: ResponseNotificationEnum;
  message: string;
}

export enum DictionaryTypeEnum {
  Benefit = 'Benefit',
  Field = 'Field',
  City = 'City',
  Country = 'Country',
  EmploymentType = 'EmploymentType',
  Experience = 'Experience',
  JobFunction = 'JobFunction',
  WorkLocation = 'WorkLocation',
  TechnologyCategory = 'TechnologyCategory',
  Technology = 'Technology',
}

export interface SeoDetailsType {
  homePageSeoDetails: GeneralSeoDetailsType;
  aboutUsSeoDetails: GeneralSeoDetailsType;
  companiesListSeoDetails: GeneralSeoDetailsType;
  vacanciesListSeoDetails: GeneralSeoDetailsType;
  staticPagesSeoDetails: GeneralSeoDetailsType;
  authSeoDetails: GeneralSeoDetailsType;
  notFoundSeoDetails: GeneralSeoDetailsType;
  compareCompanySeoDetails: GeneralSeoDetailsType;
  blogSeoDetails: GeneralSeoDetailsType;
}

export interface GeneralSeoDetailsType {
  titleEn: string;
  titleGe: string;
  descriptionEn: string;
  descriptionGe: string;
  pageType: PageEnum;
}

export enum PageEnum {
  HomePage,
  AboutUsPage,
  CompaniesListPage,
  VacanciesListPage,
  StaticPages,
  AuthPages,
  NotFoundPage,
}

export interface HeroImageButtonType {
  id: string;
  name: string;
  nameGE: string;
  url: string;
}

export interface HeroImageDetailsType {
  mainText: string;
  mainTextGE: string;
  description: string;
  descriptionGE: string;
  heroButtons: HeroImageButtonType[];
  heroImageURL?: string;
}

export interface countryCodesType {
  readonly id: string;
  readonly countryCodes: {
    readonly name: string;
    readonly dialCode: string;
    readonly code: string;
  }[];
}

export interface UpdateUserFavoriteApplicantProps {
  readonly applicantIds: string[];
  readonly markAsFavorite: boolean;
}

export interface getFavoriteApplicantsType {
  readonly data: {
    readonly title: string;
    readonly titleGE: string;
    readonly vacancyId: string;
    readonly applicants: {
      readonly createDate: string;
      readonly email: string;
      readonly fullName: string;
      readonly id: string;
      readonly isFavorite: boolean | null;
      readonly phone: string;
      readonly vacancyId: string;
      readonly cv: {
        readonly fileName: string;
        readonly url: string;
      };
    }[];
  }[];
}

export interface findTechnologiesInContextType {
  readonly activeCompanyCount: number;
  readonly activeVacancyCount: number;
  readonly childDictionaries: null;
  readonly description: null;
  readonly descriptionGE: null;
  readonly iconUrl: string | null;
  readonly id: string;
  readonly name: string;
  readonly nameGE: string;
  readonly orderIndex: number;
  readonly parentDictionary: {
    readonly dictionaryName: string;
    readonly dictionaryType: number;
    readonly id: string;
    readonly name: string;
    readonly nameGE: string;
  };
}
