// ** hooks
import { blogType } from '../../../../Api';
import useResources from '../../../../Hooks/useResources';

// ** style
import classes from './styles.module.sass';

interface Props {
  title: string;
  subText?: string | string[] | number;
  subText1?: string | number;
  children?: JSX.Element;
  canEdit?: boolean;
  handleEdit?(): void;
  isMandatory?: boolean;
  isPhoneNumber?: boolean;
}

function InfoField({ title, subText, subText1, children, canEdit, handleEdit, isMandatory, isPhoneNumber }: Props) {
  const resources = useResources();
  return (
    <div className={classes.infoContainer}>
      <div className={classes.title}>
        <p>
          {title}: {canEdit && isMandatory && <span>*</span>}
        </p>
        {canEdit && <button onClick={() => handleEdit && handleEdit()}>{resources.Reusable.edit}</button>}
      </div>
      {!!subText && isPhoneNumber ? (
        <a className={classes.subText} href={`tel:${subText}`}>
          {subText}
        </a>
      ) : (
        <div className={classes.subText}>{subText}</div>
      )}
      {!!subText1 && <div className={classes.subText}>{subText1}</div>}
      {children}
    </div>
  );
}

export default InfoField;
