import { useLayoutEffect, useState } from 'react'
import useEventListener from './useEventListener'

interface Props {
    width: number
    height: number
}

function useWindowSize(): Props {
    const init = { width: 0, height: 0 }
    const [windowSize, setWindowSize] = useState<Props>(init)

    const handleSize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        })
    }

    useEventListener('resize', handleSize)

    useLayoutEffect(() => {
        handleSize()
    }, [])

    return windowSize
}

export default useWindowSize
