import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import * as Api from '../../Api';
import { AxiosError } from 'axios';

interface UserPanelState {
  readonly userPanelInfo: Api.UserPanelType;
  readonly FieldsAndExperiences?: Api.DictionaryType[];
  readonly userSubscribedCompanies: Api.UserSubscribedCompaniesType;
  readonly allCompaniesForUserSubCompaniesEdit: Api.CompanyType['data'];
  readonly userSubscribedJobFunctions: Api.GetUserSubscribedJobFunctionsType;
  readonly userSubscribedFilters: Api.userSubscribedFiltersType;
  readonly jobFunctions: Api.DictionaryType[];
  readonly isLoading: boolean;
  readonly hideNotifications: boolean;
  readonly userFavoriteVacancies: string[];
}

const initialState: UserPanelState = {
  userPanelInfo: {
    data: {
      email: '',
      userType: 0,
      experience: {
        id: '',
        name: '',
      },
      field: {
        id: '',
        name: '',
      },
      jobFunctions: [],
      id: '',
      name: '',
      profileImageUrl: '',
    },
  },
  userSubscribedFilters: {
    data: [],
    errors: null,
    succeeded: false,
  },
  userSubscribedCompanies: {
    data: [],
    errors: null,
    succeeded: false,
  },
  userSubscribedJobFunctions: {
    data: [],
    errors: [],
    succeeded: false,
  },
  userFavoriteVacancies: [],
  jobFunctions: [],
  allCompaniesForUserSubCompaniesEdit: [],
  FieldsAndExperiences: undefined,
  isLoading: false,
  hideNotifications: false,
};

export const getUserProfileInfo = createAsyncThunk<Api.UserPanelType>('user/getUserProfileInfo', async () => {
  const response = await Api.getUserProfileInfoRequest();
  return response.data;
});

export const postUserProfileInfo = createAsyncThunk<Api.UserPanelType, Api.updateUserProfileInfoRequestParams>(
  'user/postUserProfileInfo',
  async (params) => {
    const response = await Api.postUserProfileInfoRequest(params);
    return response.data;
  }
);

export const getFieldsAndExperiences = createAsyncThunk<Api.DictionaryType[]>(
  'user/getFieldsAndExperiences',
  async () => {
    const response = await Api.getFieldsAndExperiencesRequest();
    return response.data;
  }
);

export const postUsersTechnologies = createAsyncThunk<Api.CompanyPanelType, string[]>(
  'user/postUsersTechnologies',
  async (technologyIds: string[]) => {
    const response = await Api.postUsersTechnologiesRequest(technologyIds);
    return response.data;
  }
);

export const getUserSubscribedCompanies = createAsyncThunk<Api.UserSubscribedCompaniesType>(
  'user/getUserSubscribedCompanies',
  async () => {
    const response = await Api.getUserSubscribedCompaniesRequest();
    return response.data;
  }
);

export const getAllCompaniesForUserSubCompaniesEdit = createAsyncThunk<Api.CompanyType>(
  'user/getAllCompaniesForUserSubCompaniesEdit',
  async () => {
    const response = await Api.getSearchCompaniesRequest('', 100000000);
    return response.data;
  }
);

export const getTalentApplications = createAsyncThunk<{ data: Api.VacancySearchType[] }>(
  'user/getTalentApplications',
  async () => {
    const response = await Api.getTalentApplicationsRequest();
    return response.data;
  }
);

export const postUpdateUserSubscribedCompanies = createAsyncThunk<
  Api.UpdateUserSubscribedCompaniesType,
  Api.UpdateUserSubscribedCompaniesRequestParams
>('user/postUpdateUserSubscribedCompanies', async (params: Api.UpdateUserSubscribedCompaniesRequestParams) => {
  const response = await Api.postUpdateUserSubscribedCompaniesRequest(params);
  return response.data;
});

export const postSubscribeToCompany = createAsyncThunk<
  Api.UpdateUserSubscribedCompaniesType,
  Api.SubscribeToCompanyRequestParams
>('user/postUpdateUserSubscribedCompanies', async (params: Api.SubscribeToCompanyRequestParams) => {
  const response = await Api.postSubscribeToCompanyRequest(params);
  return response.data;
});

export const getUserSubscribedJobFunctions = createAsyncThunk<Api.GetUserSubscribedJobFunctionsType>(
  'user/getUserSubscribedJobFunctions',
  async () => {
    const response = await Api.getUserSubscribedJobFunctionsRequest();
    return response.data;
  }
);

export const postUpdateUserSubscribedJobFunctions = createAsyncThunk<
  Api.CompanyPanelType,
  Api.UpdateUserSubscribedJobFunctionsRequestParams
>('user/postUpdateUserSubscribedJobFunctions', async (params, thunkApi) => {
  try {
    const response = await Api.postUpdateUserSubscribedJobFunctionsRequest(params);
    return response.data;
  } catch (err) {
    const error = err;
    if (error) {
      return thunkApi.rejectWithValue((error as AxiosError).response?.data);
    }

    throw err;
  }
});

export const getJobFunctions = createAsyncThunk<Api.DictionaryType[]>('user/getJobFunctions', async () => {
  const response = await Api.getJobFunctionsRequest();
  return response.data;
});

export const getUserSubscriptions = createAsyncThunk<Api.userSubscribedFiltersType>(
  `user/getUserSubscriptions`,
  async () => {
    const response = await Api.getUserSubscriptionsRequest();
    return response.data;
  }
);
export const postCreateUpdateSubscriptions = createAsyncThunk<
  Api.UpdateUserSubscribedCompaniesType,
  Api.CreateUpdateSubscriptionsParams
>(`user/postCreateUpdateSubscriptions`, async (data, thunkApi) => {
  try {
    const response = await Api.postCreateUpdateSubscriptionsRequest(data);
    return response.data;
  } catch (err) {
    const error = err;
    if (error) {
      return thunkApi.rejectWithValue((error as AxiosError).response?.data);
    }

    throw err;
  }
});

export const postDeleteSubscription = createAsyncThunk<Api.UpdateUserSubscribedCompaniesType, string>(
  `user/postDeleteSubscription`,
  async (id) => {
    const response = await Api.postDeleteSubscriptionRequest(id);
    return response.data;
  }
);

export const postAddRemoveFavoriteVacancy = createAsyncThunk<
  Api.UpdateUserSubscribedCompaniesType,
  Api.postAddRemoveFavoriteVacancyRequestType
>(`user/postAddRemoveFavoriteVacancy`, async (vacancyData, thunkApi) => {
  try {
    const response = await Api.postAddRemoveFavoriteVacancyRequest(vacancyData);
    return response.data;
  } catch (err) {
    const error = err;
    if (error) {
      return thunkApi.rejectWithValue((error as AxiosError).response?.data);
    }
    throw err;
  }
});

export const getFavoriteVacancies = createAsyncThunk<{ data: Api.VacancySearchType[] }, string>(
  `user/getFavoriteVacancies`,
  async (queryString, thunkApi) => {
    try {
      const response = await Api.getFavoriteVacanciesRequest(queryString);
      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }
      throw err;
    }
  }
);

export const UserPanelSlice = createSlice({
  name: 'userPanel',
  initialState,
  reducers: {
    updateUserTechnologies: (state, action: PayloadAction<Api.TechnologiesType[]>) => {
      state.userPanelInfo.data.technologies = action.payload;
    },
    updateHideNotifications: (state, action: PayloadAction<boolean>) => {
      state.hideNotifications = action.payload;
    },
    resetUserSubscribedCompanies: (state) => {
      state.userSubscribedCompanies = {
        data: [],
        errors: null,
        succeeded: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfileInfo.fulfilled, (state, action) => {
        state.userPanelInfo = action.payload;
        state.isLoading = false;
      })
      .addCase(getUserProfileInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFieldsAndExperiences.fulfilled, (state, action) => {
        state.FieldsAndExperiences = action.payload;
        state.isLoading = false;
      })
      .addCase(getFieldsAndExperiences.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserSubscribedCompanies.fulfilled, (state, action) => {
        state.userSubscribedCompanies = action.payload;
        state.isLoading = false;
      })
      .addCase(getUserSubscribedCompanies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCompaniesForUserSubCompaniesEdit.fulfilled, (state, action) => {
        state.allCompaniesForUserSubCompaniesEdit = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getAllCompaniesForUserSubCompaniesEdit.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postUpdateUserSubscribedCompanies.fulfilled, (state) => {
        state.hideNotifications = false;
        state.isLoading = false;
      })
      .addCase(postUpdateUserSubscribedCompanies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserSubscribedJobFunctions.fulfilled, (state, action) => {
        state.userSubscribedJobFunctions = action.payload;
        state.isLoading = false;
      })
      .addCase(getUserSubscribedJobFunctions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postUpdateUserSubscribedJobFunctions.fulfilled, (state) => {
        state.hideNotifications = false;
        state.isLoading = false;
      })
      .addCase(postUpdateUserSubscribedJobFunctions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getJobFunctions.fulfilled, (state, action) => {
        state.jobFunctions = action.payload;
        state.isLoading = false;
      })
      .addCase(getJobFunctions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postUsersTechnologies.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(postUsersTechnologies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserSubscriptions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserSubscriptions.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getUserSubscriptions.fulfilled, (state, action) => {
        state.userSubscribedFilters = action.payload;
      })
      .addCase(postCreateUpdateSubscriptions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postCreateUpdateSubscriptions.rejected, (state, action) => {
        // @ts-ignore
        toast.error(action.payload?.error);
        state.isLoading = false;
      })
      .addCase(postCreateUpdateSubscriptions.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action.payload.data);
      })
      .addCase(postDeleteSubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postDeleteSubscription.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postDeleteSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action.payload.data);
      })
      .addCase(getTalentApplications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTalentApplications.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getTalentApplications.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(postAddRemoveFavoriteVacancy.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(postAddRemoveFavoriteVacancy.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postAddRemoveFavoriteVacancy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFavoriteVacancies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFavoriteVacancies.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getFavoriteVacancies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userFavoriteVacancies = action.payload.data.map((el) => el.id);
      });
  },
});

export const getTechnologiesAndCategory = createAsyncThunk<Api.DictionaryType[]>(
  'common/getTechnologiesAndCategory',
  async () => {
    const response = await Api.getTechnologiesAndCategoryRequest();
    return response.data;
  }
);

export const { updateUserTechnologies, updateHideNotifications, resetUserSubscribedCompanies } = UserPanelSlice.actions;
export default UserPanelSlice.reducer;
