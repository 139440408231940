import { RefObject, useEffect, useRef } from 'react'

const useEventListener = <KW extends keyof WindowEventMap, KH extends keyof HTMLElementEventMap, T extends HTMLElement | void = void>(
        eventName: KW | KH,
        handler: (event: WindowEventMap[KW] | HTMLElementEventMap[KH] | Event,) => void,
        element?: RefObject<T> ) => {
    const savedHandler = useRef<typeof handler>()
    useEffect(() => {
        const targetElement: T | Window = element?.current || window
        if (!(targetElement && targetElement.addEventListener)) return

        if (savedHandler.current !== handler) {
            savedHandler.current = handler
        }

        const eventListener: typeof handler = event => {
            if (savedHandler?.current) {
                savedHandler.current(event)
            }
        }

        targetElement.addEventListener(eventName, eventListener)
        return () => {
            targetElement.removeEventListener(eventName, eventListener)
        }
    }, [eventName, element, handler])
}

export default useEventListener
