import { configureStore } from '@reduxjs/toolkit';

import { useDispatch } from 'react-redux';
import languageReducer from './slices/languageSlice';
import authReducer from './slices/authSlice';
import seoReducer from './slices/seoDetailSlice';
import signUpReducer from './slices/signUpSlice';
import signInReducer from './slices/signInSlice';
import sendPasswordRecoveryReducer from './slices/sendPasswordRecoverySlice';
import recoverUserPasswordReducer from './slices/recoverUserPasswordSlice';
import CompanyPanelReducer from './slices/companyPanelSlice';
import userPanelReducer from './slices/userPanelSlice';
import homeReducer from './slices/homeSlice';
import smartSearchSlice from './slices/smartSearchSlice';
import companiesReducer from './slices/companiesSlice';
import vacanciesReducer from './slices/vacanciesSlice';
import aboutUsReducer from './slices/aboutUsSlice';
import CompanyPanelVacanciesReducer from './slices/companyPanelVacanciesSlice';
import passwordChangeSlice from './slices/passwordChangeSlice';
import profileImage from './slices/updateProfileImageSlice';
import pageStatistics from './slices/pageStatisticsSlice';
import blogSlice from './slices/blogSlice';

export const store = configureStore({
  reducer: {
    CompanyPanel: CompanyPanelReducer,
    language: languageReducer,
    auth: authReducer,
    seoDetails: seoReducer,
    signUp: signUpReducer,
    signIn: signInReducer,
    recoverUserPassword: recoverUserPasswordReducer,
    sendPasswordRecovery: sendPasswordRecoveryReducer,
    home: homeReducer,
    smartSearch: smartSearchSlice,
    companies: companiesReducer,
    userPanel: userPanelReducer,
    aboutUs: aboutUsReducer,
    companyPanelVacancies: CompanyPanelVacanciesReducer,
    vacancies: vacanciesReducer,
    passwordChange: passwordChangeSlice,
    profileImage: profileImage,
    pageStatistics: pageStatistics,
    blog: blogSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// ! use useAppDispatch with async thunks
export const useAppDispatch = () => useDispatch<AppDispatch>();
