import { useEffect, useRef, useState } from 'react';
import classes from './styles.module.sass';
import { ReactComponent as ArrowDown } from '../../../../Assets/Icons/Icon-arrow-down.svg';
import useResources from '../../../../Hooks/useResources';
import useOutsideClick from '../../../../Hooks/useOutsideClick';

type phoneObjectType = {
  name: string;
  code: string;
  dialCode: string;
};

type props = {
  options: phoneObjectType[];
  value: string | null;
  defaultValue?: string;
  onChange: (val: phoneObjectType) => void;
};

const CountryCodeDropdown = ({ options, value, defaultValue = '+995', onChange }: props) => {
  const optionsRef = useRef<HTMLDivElement>(null);
  const resources = useResources();
  const [open, setOpen] = useState<boolean>(false);
  const [searchVal, setSearchVal] = useState<string>('');

  useOutsideClick(optionsRef, () => setOpen(false));

  const handleChange = (el: phoneObjectType) => {
    setOpen(false);
    onChange(el);
  };

  useEffect(() => {
    if (!value) onChange({ dialCode: defaultValue, code: 'GE', name: 'Georgia' });
  }, []);

  return (
    <div className={classes.countryCodeDropdown}>
      <div className={classes.wrapper}>
        <div className={classes.chosenVal} onClick={() => setOpen((prev) => !prev)}>
          <p>{value || defaultValue} </p>
          <ArrowDown style={open ? { transform: 'rotate(180deg)' } : {}} />
        </div>
        <div className={`${classes.options} ${open && classes.open}`} ref={optionsRef}>
          <div className={classes.searchWrapper}>
            <input
              type='text'
              value={searchVal}
              onChange={(e) => setSearchVal(e.currentTarget.value)}
              placeholder={resources.Reusable.search}
            />
          </div>
          {options
            .filter((el) => filterByProps(el, searchVal))
            .map((el, idx) => (
              <span key={idx} onClick={() => handleChange(el)}>
                {el.code} {el.dialCode}
              </span>
            ))}
        </div>
      </div>
    </div>
  );
};

const filterByProps = (el: { name: string; code: string; dialCode: string }, searchVal: string) => {
  if (searchVal.trim() === '') return true;
  const result =
    el &&
    (el.code.toLowerCase().includes(searchVal.toLowerCase()) ||
      el.dialCode.includes(searchVal) ||
      el.name.toLowerCase().includes(searchVal.toLowerCase()));
  return result;
};

export default CountryCodeDropdown;
