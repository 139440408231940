import * as Api from '../../Api';

const tagFilter = (
  keyword: string,
  dictionaries: Api.DictionaryType[]
): Api.DictionaryType['dictionaryList'] | undefined => {
  const filtered: Api.DictionaryType['dictionaryList'] = [];
  const dictionary = dictionaries.find((d) => d.dictionaryName === 'TechnologyCategory')?.dictionaryList;

  if (keyword.length === 0) {
    dictionary?.forEach((i) => {
      filtered?.push({
        description: i.description,
        descriptionGE: i.descriptionGE,
        iconUrl: i.iconUrl,
        id: i.id,
        name: i.name,
        nameGE: i.nameGE,
        activeVacancyCount: i.activeVacancyCount,
        parentDictionary: i.parentDictionary,
        childDictionaries: i.childDictionaries,
        activeCompanyCount: i.activeCompanyCount,
      });
    });

    return filtered;
  }

  dictionary?.forEach((i) => {
    if (
      i.childDictionaries.some((item) => item.name.toLocaleLowerCase().includes(keyword)) ||
      i.name.toLocaleLowerCase().includes(keyword)
    ) {
      filtered?.push({
        description: i.description,
        descriptionGE: i.descriptionGE,
        iconUrl: i.iconUrl,
        id: i.id,
        name: i.name,
        nameGE: i.nameGE,
        activeVacancyCount: i.activeVacancyCount,
        activeCompanyCount: i.activeCompanyCount,
        parentDictionary: i.parentDictionary,
        childDictionaries: i.childDictionaries.filter(
          (item) => item.name.toLocaleLowerCase().includes(keyword) || i.name.toLocaleLowerCase().includes(keyword)
        ),
      });
    }
  });

  return filtered;
};

export default tagFilter;
