import axios from 'axios';

const url = process.env.REACT_APP_BLOG_URL;
const token = process.env.REACT_APP_BLOG_TOKEN;
const apiUrl = process.env.REACT_APP_API_URL;

export const getAllBlogsRequest = async (queryString: string) => {
  // avoiding axios interceptor
  const response = await fetch(`${url}/api/blogs${queryString}`, {
    headers: {
      Authorization: `bearer ${token}`,
    },
  });
  if (!response.ok) throw new Error('failed to fetch');

  return response.json();
};

export const postSubscribeUnsubscribeToBlogsRequest = (email: string) => {
  const response = axios.post(`${apiUrl}api/users/subscribeUnsubscribeToBlogs`, { email });
  return response;
};

export const postCheckSubscribtionStatusForBlogsRequest = (email: string) => {
  const response = axios.post(`${apiUrl}api/Users/CheckSubscribtionStatusForBlogs`, { email });
  return response;
};
