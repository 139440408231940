import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as Api from '../../Api';

interface BlogState {
  blogs: Api.blogType;
  isLoading: boolean;
  blogArticleTitle: string | null;
}

const initialState: BlogState = {
  blogs: {
    data: [],
    meta: {} as Api.blogType['meta'],
  },
  blogArticleTitle: null,
  isLoading: false,
};

export const getAllBlogs = createAsyncThunk<Api.blogType, string>('blog/getAllBlogs', async (queryString: string) => {
  const response = await Api.getAllBlogsRequest(queryString);
  return response;
});

export const postSubscribeUnsubscribeToBlogs = createAsyncThunk<{ data: { data: string } }, string>(
  'blog/postSubscribeUnsubscribeToBlogs',
  async (email: string) => {
    const response = await Api.postSubscribeUnsubscribeToBlogsRequest(email);
    return response;
  }
);
export const postCheckSubscribtionStatusForBlogs = createAsyncThunk<{ data: { data: boolean } }, string>(
  'blog/postCheckSubscribtionStatusForBlogs',
  async (email: string) => {
    const response = await Api.postCheckSubscribtionStatusForBlogsRequest(email);
    return response;
  }
);

export const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    updateBlogTitle: (state, action) => {
      state.blogArticleTitle = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllBlogs.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllBlogs.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getAllBlogs.fulfilled, (state) => {
      state.isLoading = false;
    });
  },
});

export const { updateBlogTitle } = blogSlice.actions;
export default blogSlice.reducer;
