import React from 'react'
import Button from '../../../Components/UI/ReusableComponents/Button/Button'
import useResources from '../../../Hooks/useResources'
import classNames from "../styles.module.sass"

interface Props {
    readonly profileType: 'personal' | 'business'
    readonly setProfileType: React.Dispatch<React.SetStateAction<'personal' | 'business'>>
}

const ProfileTypeSwitcher = ({ profileType, setProfileType }: Props) => {

    const resources = useResources()


    return (
        <div className={classNames.profileTypeSwitcher}>
            <Button
                className={`${classNames.switcherButton} ${profileType === 'personal' && classNames.active}`}
                onClick={() => setProfileType('personal')}
            >
                {resources.SignUp.personal}
            </Button>
            <Button
                className={`${classNames.switcherButton} ${profileType === 'business' && classNames.active}`}
                onClick={() => setProfileType('business')}
            >
                {resources.SignUp.business}
            </Button>
        </div>
    )
}

export default ProfileTypeSwitcher