import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as Api from '../../Api';
import { AxiosError } from 'axios';
import { updateCoverImageType } from '../../Api';

export interface CompanyPanelState {
  companyPanelInfo: Api.CompanyPanelType;
  isLoading: boolean;
  keyFacts: Api.KeyfactDataType[];
  progressData: {
    progress: number;
    progressList: { name: string; nameGe: string; isComplete: boolean; progressType: number }[];
  };
}

const initialState: CompanyPanelState = {
  companyPanelInfo: {
    data: {
      about: '',
      aboutGe: '',
      activeVacancyCount: 0,
      benefits: [],
      createDate: '',
      email: '',
      id: '',
      errors: '',
      name: '',
      profileImageUrl: '',
      succeeded: false,
      tagLine: '',
      teamSize: 0,
      userType: 0,
      isEmailVerified: false,
      isVerified: false,
      coverImageUrl: null,
    },
  },
  progressData: {
    progress: 0,
    progressList: [],
  },
  keyFacts: [],
  isLoading: false,
};

export const getCompanyProfileInfo = createAsyncThunk<Api.CompanyPanelType>(
  'company/getCompanyProfileInfo',
  async () => {
    const response = await Api.getCompanyProfileInfoRequest();
    return response.data;
  }
);

export const postCompanyTechnologies = createAsyncThunk<Api.CompanyPanelType, string[]>(
  'company/updateCompanyTechnologies',
  async (technologyIds: string[]) => {
    const response = await Api.postCompanyTechnologiesRequest(technologyIds);
    return response.data;
  }
);

export const getTechnologiesAndCategory = createAsyncThunk<Api.DictionaryType[]>(
  'common/getTechnologiesAndCategory',
  async () => {
    const response = await Api.getTechnologiesAndCategoryRequest();
    return response.data;
  }
);

export const getAllBenefits = createAsyncThunk<Api.BenefitApiResponse, string>(
  'common/getAllBenefits',
  async (id: string) => {
    const response = await Api.getAllBenefits(id);
    return response.data;
  }
);

export const postUpdateCompanyTagline = createAsyncThunk('company/UpdateCompanyTagline', async (tagline: string) => {
  const response = await Api.postUpdateCompanyTaglineRequest(tagline);
  return response.data;
});

export const getKeyFacts = createAsyncThunk<Api.keyFactRequestType>('company/getKeyFacts', async () => {
  const response = await Api.getKeyFactsRequest();
  return response.data;
});

export const getCompanyKeyFactsByCompanyId = createAsyncThunk<Api.companyKeyFactType[], string>(
  'company/getCompanyKeyFactsByCompanyId',
  async (id: string) => {
    const response = await Api.getCompanyKeyFactsByCompanyIdRequest(id);
    return response.data;
  }
);

export const postCreateUpdateCompanyKeyFact = createAsyncThunk<unknown, Api.companyKeyFactParams>(
  'company/postCreateUpdateCompanyKeyFact',
  async (keyFact: Api.companyKeyFactParams, thunkApi) => {
    try {
      const response = await Api.postCreateUpdateCompanyKeyFactRequest(keyFact);
      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }

      throw err;
    }
  }
);

export const getCompanyProgress = createAsyncThunk<CompanyPanelState['progressData']>(
  'company/getCompanyProgress',
  async () => {
    const response = await Api.getCompanyProgressRequest();
    return response.data;
  }
);

export const postUpdateCoverImage = createAsyncThunk<updateCoverImageType, FormData>(
  'company/postUpdateCoverImage',
  async (blob: FormData) => {
    const response = await Api.postUpdateCoverImageRequest(blob);
    return response.data;
  }
);

export const getCountryCodes = createAsyncThunk<Api.countryCodesType[]>('company/getCountryCodes', async () => {
  const response = await Api.getCountryCodesRequest();
  return response.data;
});

export const putUpdateUserFavoriteApplicant = createAsyncThunk<unknown, Api.UpdateUserFavoriteApplicantProps>(
  'company/putUpdateUserFavoriteApplicant',
  async (data: Api.UpdateUserFavoriteApplicantProps) => {
    const response = await Api.putUpdateUserFavoriteApplicantRequest(data);
    return response.data;
  }
);

export const getFavoriteApplicants = createAsyncThunk<Api.getFavoriteApplicantsType>(
  'company/getFavoriteApplicants',
  async () => {
    const response = await Api.getFavoriteApplicantsRequest();
    return response.data;
  }
);

export const companyPanelSlice = createSlice({
  name: 'companyPanel',
  initialState,
  reducers: {
    updateProfileProgress: (state, action: PayloadAction<number>) => {
      state.progressData.progress = action.payload;
    },
    updateAboutCompanyText: (state, action: PayloadAction<{ enText: string; geText: string }>) => {
      state.companyPanelInfo.data.about = action.payload.enText;
      state.companyPanelInfo.data.aboutGe = action.payload.geText;
    },
    updateTaglineText: (state, action: PayloadAction<string>) => {
      state.companyPanelInfo.data.tagLine = action.payload;
    },
    updateCompanyKeyFacts: (state, action: PayloadAction<Api.KeyFactsType>) => {
      state.companyPanelInfo.data.keyFacts = action.payload;
    },
    updateCompanyBenefits: (state, action: PayloadAction<Api.BenefitType[]>) => {
      state.companyPanelInfo.data.benefits = action.payload;
    },
    updateCompanyTechnologies: (state, action: PayloadAction<Api.TechnologiesType[]>) => {
      state.companyPanelInfo.data.technologies = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanyProfileInfo.fulfilled, (state, action) => {
      state.companyPanelInfo = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getCompanyProfileInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postCompanyTechnologies.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(postCompanyTechnologies.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTechnologiesAndCategory.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getTechnologiesAndCategory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postUpdateCompanyTagline.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postUpdateCompanyTagline.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(postUpdateCompanyTagline.fulfilled, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllBenefits.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllBenefits.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getAllBenefits.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getKeyFacts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.keyFacts = action.payload.data;
    });
    builder.addCase(getKeyFacts.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getKeyFacts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCompanyKeyFactsByCompanyId.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCompanyKeyFactsByCompanyId.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getCompanyKeyFactsByCompanyId.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(postCreateUpdateCompanyKeyFact.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(postCreateUpdateCompanyKeyFact.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(postCreateUpdateCompanyKeyFact.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCompanyProgress.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCompanyProgress.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getCompanyProgress.fulfilled, (state, action) => {
      state.isLoading = false;
      state.progressData = action.payload;
    });
    builder.addCase(postUpdateCoverImage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postUpdateCoverImage.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(postUpdateCoverImage.fulfilled, (state, action) => {
      state.isLoading = false;
      state.companyPanelInfo.data.coverImageUrl = action.payload.coverImageUrl;
    });
    builder.addCase(getCountryCodes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCountryCodes.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getCountryCodes.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(putUpdateUserFavoriteApplicant.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(putUpdateUserFavoriteApplicant.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putUpdateUserFavoriteApplicant.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getFavoriteApplicants.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getFavoriteApplicants.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getFavoriteApplicants.pending, (state) => {
      state.isLoading = true;
    });
  },
});

export const {
  updateAboutCompanyText,
  updateTaglineText,
  updateCompanyKeyFacts,
  updateCompanyBenefits,
  updateCompanyTechnologies,
  updateProfileProgress,
} = companyPanelSlice.actions;
export default companyPanelSlice.reducer;
