import React, { useEffect, useMemo, useState } from 'react';
import * as Api from '../../../Api';
import { Form, Formik } from 'formik';
import classNames from '../styles.module.sass';
import useDropdown from '../../../Hooks/useDropdown';
import { tagFilter } from '../../../utils/helpers';
import Button from '../../../Components/UI/ReusableComponents/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../Redux/store';
import {
  updateExperience,
  updateField,
  updateJobFunctions,
  updateTechnologies,
} from '../../../Redux/slices/signUpSlice';
import { ArrowDownIcon, ArrowIcon } from '../../../Components/Svgs';
import tagsByCategory from '../../../utils/helpers/tagsByCategory';
import useResources from '../../../Hooks/useResources';
import { GetCurrentLocalizationName } from '../../../utils/helpers/getCurrentLocalization';
import { ReactSVG } from 'react-svg';
import Chekbox from '../../../Components/UI/ReusableComponents/Chekbox/Chekbox';

interface Props {
  readonly step: number;
  readonly setStep: React.Dispatch<React.SetStateAction<number>>;
}

interface Values {
  readonly Field: string;
  readonly Experience: string;
  readonly Technologies: string[];
  readonly jobFunctions: string[];
}

const ExperienceForm = ({ step, setStep }: Props) => {
  const { isSelected: fieldIsSelected, toggleDropdown: fieldToggle, ref: fieldRef } = useDropdown<'div'>();
  const {
    isSelected: experienceIsSelected,
    toggleDropdown: experienceToggle,
    ref: experienceRef,
  } = useDropdown<'div'>();
  const {
    isSelected: technologyTypeIsSelected,
    toggleDropdown: technologyTypeToggle,
    ref: technologyTypeRef,
  } = useDropdown<'div'>();

  const { formData, dictionaries } = useSelector((state: RootState) => state.signUp);
  const dispatch = useDispatch();
  const resources = useResources();
  const { SelectedLanguage } = useSelector((state: RootState) => state.language);

  const [filteredTechnologies, setFilteredTechnologies] = useState<Api.DictionaryType['dictionaryList'] | undefined>();

  useEffect(() => {
    const dictionary = dictionaries.find((d) => d.dictionaryName === 'TechnologyCategory')?.dictionaryList;
    const filtered: Api.DictionaryType['dictionaryList'] = [];

    dictionary?.forEach((i) => {
      filtered?.push({
        description: i.description,
        descriptionGE: i.descriptionGE,
        iconUrl: i.iconUrl,
        id: i.id,
        name: i.name,
        nameGE: i.nameGE,
        activeVacancyCount: i.activeVacancyCount,
        parentDictionary: i.parentDictionary,
        childDictionaries: i.childDictionaries,
        activeCompanyCount: i.activeCompanyCount,
      });
    });

    setFilteredTechnologies(filtered);
  }, []);

  const initialValues: Values = {
    Field: formData.Field ?? '',
    Experience: formData.Experience ?? '',
    Technologies: formData.Technologies ?? ([] as Array<string>),
    jobFunctions: formData.JobFunctions ?? ([] as string[]),
  };

  const handleSubmit = (values: typeof initialValues) => {
    dispatch(updateField(values.Field));
    dispatch(updateExperience(values.Experience));
    dispatch(updateTechnologies(values.Technologies));
    dispatch(updateJobFunctions(values.jobFunctions));
    setStep(step + 1);
  };

  const fieldTechnologies = useMemo(() => {
    const resultArr: Api.jobFunctionsType[] = [];
    dictionaries
      .find((dict) => dict.dictionaryName === 'JobFunction')
      ?.dictionaryList.forEach((dict) => {
        const existingParent = resultArr.find((el) => el.parentId === dict.parentDictionary?.id);
        if (!existingParent) {
          resultArr.push({
            parentId: dict.parentDictionary?.id,
            parentName: dict.parentDictionary?.name,
            parentNameGE: dict.parentDictionary?.nameGE,
            children: [
              {
                id: dict.id,
                name: dict.name,
                nameGE: dict.nameGE,
                activeCompanyCount: dict.activeCompanyCount,
                activeVacancyCount: dict.activeVacancyCount,
              },
            ],
          });
        }
        if (existingParent) {
          resultArr
            .find((el) => el.parentId === dict.parentDictionary?.id)
            ?.children.push({
              id: dict.id,
              name: dict.name,
              nameGE: dict.nameGE,
              activeCompanyCount: dict.activeCompanyCount,
              activeVacancyCount: dict.activeVacancyCount,
            });
        }
      });
    return resultArr;
  }, [dictionaries]);

  const toggleJobFunction = (array: string[], id: string) => {
    if (array.includes(id)) return array.filter((el) => el !== id);
    return [...array, id];
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ setFieldValue, values }) => (
        <Form className={classNames.signUpForm}>
          <div className={classNames.select}>
            <button
              id='Field'
              type='button'
              className={classNames.jobFunctionBtn}
              onClick={() => {
                if (experienceIsSelected) {
                  experienceToggle();
                }
                fieldToggle();
              }}
              style={values.jobFunctions?.length > 0 ? undefined : { color: '#a3a3a3' }}
            >
              <span>
                {values.jobFunctions?.length > 0
                  ? dictionaries
                      .find((el) => el.dictionaryName === 'JobFunction')
                      ?.dictionaryList.filter((el) => values.jobFunctions.includes(el.id))
                      ?.map((el, idx) => {
                        const result = GetCurrentLocalizationName(SelectedLanguage, el, 'name', 'nameGE');
                        if (idx !== values.jobFunctions.length - 1) return `${result}, `;
                        return result;
                      })
                  : resources.SignUp.field}
              </span>
              <ArrowDownIcon fill='#747474' transform={`${fieldIsSelected ? 'rotate(180)' : ''}`} />
            </button>
            <div
              ref={fieldRef}
              className={`${classNames.dropdownDiv} ${classNames.jobFunctionsDiv} ${
                fieldIsSelected ? classNames.show : ''
              }`}
            >
              <ul>
                {fieldTechnologies.map((el) => (
                  <li key={el.parentId} className={classNames.jobFunctions}>
                    <div>
                      <h6>{GetCurrentLocalizationName(SelectedLanguage, el, 'parentName', 'parentNameGE')}</h6>
                      <div className={classNames.fields}>
                        {el.children.map((jobFunc) => (
                          <span
                            key={jobFunc.id}
                            onClick={() =>
                              setFieldValue('jobFunctions', toggleJobFunction(values.jobFunctions, jobFunc.id))
                            }
                          >
                            <Chekbox checked={values.jobFunctions?.includes(jobFunc.id)} />
                            <p>{GetCurrentLocalizationName(SelectedLanguage, jobFunc, 'name', 'nameGE')}</p>
                          </span>
                        ))}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className={classNames.select}>
            <button
              id='Experience'
              type='button'
              onClick={() => experienceToggle()}
              style={!values.Experience ? { color: '#a3a3a3' } : undefined}
            >
              {values.Experience
                ? SelectedLanguage === 'ka-GE'
                  ? dictionaries
                      .find((item) => item.dictionaryName === 'Experience')
                      ?.dictionaryList.find((i) => i.id === values.Experience)?.nameGE
                  : dictionaries
                      .find((item) => item.dictionaryName === 'Experience')
                      ?.dictionaryList.find((i) => i.id === values.Experience)?.name
                : resources.SignUp.experience}
              <ArrowDownIcon fill='#747474' transform={`${experienceIsSelected ? 'rotate(180)' : ''}`} />
            </button>
            <div
              ref={experienceRef}
              className={`${classNames.dropdownDiv} ${experienceIsSelected ? classNames.show : ''}`}
            >
              <ul>
                <li
                  className={`${classNames.listItem} ${classNames.emptyValue}`}
                  value=''
                  onClick={() => {
                    setFieldValue('Experience', '');
                    experienceToggle();
                  }}
                >
                  ---- {resources.SignUp.selectExp} ----
                </li>
                {dictionaries
                  .find((item) => item.dictionaryName === 'Experience')
                  ?.dictionaryList.map((Experience) => (
                    <li
                      className={classNames.listItem}
                      key={Experience.id}
                      value={Experience.id}
                      onClick={() => {
                        setFieldValue('Experience', Experience.id);
                        experienceToggle();
                      }}
                    >
                      {GetCurrentLocalizationName(SelectedLanguage, Experience, 'name', 'nameGE')}
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          <div className={classNames.select}>
            <p className={classNames.chooseTechnology}>{resources.SignUp.chooseTechnology}</p>
            <input
              name='Technologies'
              id='Technologies'
              type='text'
              placeholder={resources.SignUp.type}
              onClick={() => {
                if (!technologyTypeIsSelected) {
                  technologyTypeToggle();
                }
              }}
              onChange={(e) =>
                setFilteredTechnologies(tagFilter(e.target.value.trim().toLocaleLowerCase(), dictionaries))
              }
              autoComplete='off'
            />

            {technologyTypeIsSelected && (
              <div ref={technologyTypeRef} className={`${classNames.dropdownDiv} ${classNames.show_dropdown}`}>
                {filteredTechnologies?.map((dictionary) => (
                  <React.Fragment key={dictionary.id}>
                    {dictionary.childDictionaries.length > 0 && (
                      <div className={classNames.technologyName}>
                        {GetCurrentLocalizationName(SelectedLanguage, dictionary, 'name', 'nameGE')}
                      </div>
                    )}
                    <ul>
                      {dictionary.childDictionaries.map((item) => (
                        <li key={item.id}>
                          <label className={classNames.checkBox}>
                            <input
                              value={GetCurrentLocalizationName(SelectedLanguage, item, 'name', 'nameGE')}
                              type='checkbox'
                              checked={values.Technologies.includes(item.id)}
                              onChange={() => {
                                setFieldValue(
                                  'Technologies',
                                  values.Technologies.includes(item.id)
                                    ? [...values.Technologies.filter((tech) => tech !== item.id)]
                                    : [...values.Technologies, item.id]
                                );
                              }}
                            />
                            <span className={classNames.check} />
                            <span>{GetCurrentLocalizationName(SelectedLanguage, item, 'name', 'nameGE')}</span>
                          </label>
                        </li>
                      ))}
                    </ul>
                  </React.Fragment>
                ))}
                {
                  filteredTechnologies?.every((t) => t.childDictionaries.length === 0) && null
                  // <FilterNoResult />
                }
              </div>
            )}
          </div>

          {values.Technologies.length > 0 && (
            <div className={classNames.tags}>
              {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                Object.keys(
                  tagsByCategory(
                    values.Technologies,
                    dictionaries.find((dictionary) => dictionary.dictionaryName === 'TechnologyCategory')!
                      .dictionaryList,
                    SelectedLanguage
                  )
                ).map((key, index) => (
                  <React.Fragment key={index}>
                    <p className={classNames.tagCategory}>{key}</p>
                    {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                    {tagsByCategory(
                      values.Technologies,
                      dictionaries.find((dictionary) => dictionary.dictionaryName === 'TechnologyCategory')!
                        .dictionaryList,
                      SelectedLanguage
                    )[key].map((technology) => (
                      <div key={technology.id} className={classNames.tag}>
                        {dictionaries
                          .find((dictionary) => dictionary.dictionaryName === 'TechnologyCategory')
                          ?.dictionaryList.map((dictionary) =>
                            SelectedLanguage === 'ka-GE'
                              ? dictionary.childDictionaries.find((i) => i.id === technology.id)?.nameGE
                              : dictionary.childDictionaries.find((i) => i.id === technology.id)?.name
                          )}
                        <ReactSVG
                          src='https://softwaregecdn.azureedge.net/websitefiles/icons/close.svg'
                          width='10px'
                          height='10px'
                          onClick={() =>
                            setFieldValue('Technologies', [...values.Technologies.filter((id) => id !== technology.id)])
                          }
                        />

                        {/* <CloseIcon
                          width='10px'
                          height='10px'
                          onClick={() =>
                            setFieldValue('Technologies', [...values.Technologies.filter((id) => id !== technology.id)])
                          }
                        /> */}
                      </div>
                    ))}
                  </React.Fragment>
                ))
              }
            </div>
          )}
          <div className={classNames.stepButtons}>
            <Button className={classNames.prevStep} type='button' onClick={() => setStep(step - 1)}>
              <ArrowIcon fill='#3E66F8' transform='rotate(180)' /> {resources.Reusable.back}
            </Button>
            <Button className={classNames.nextStep} type='submit'>
              {resources.SignUp.nextStep}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ExperienceForm;
