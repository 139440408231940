import { FC } from 'react';
import classes from './styles.module.sass';

const PartnersFooter: FC = () => {
  return (
    <div className={classes.footer_containter}>
      <p className={classes.title}>Ready to hire?</p>
      <h3 className={classes.title_foot}>
        Create your profile and start publishing <br /> unlimited number of vacancies.
      </h3>
      <div className={classes.footer_bg}></div>
      <button onClick={() => window.scrollTo({ behavior: 'smooth', top: 0 })} className={classes.signup_button}>
        Sign Up Today!
      </button>
      <div className={classes.top_social_hotline_wr}>
        <a href='mailto:info@techwave.ge' className={classes.top_social_phone}>
          info@techwave.ge
        </a>
        <div className={classes.divider}></div>
        <a href='tel:+995598261616' className={classes.top_social_phone}>
          +995 598 26 16 16
        </a>
      </div>
    </div>
  );
};

export default PartnersFooter;
