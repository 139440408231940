import axios from "axios";
import { getSmartSearchParams } from ".";

const url = process.env.REACT_APP_API_URL;

export const getSmartSearchRequest = (
  params: getSmartSearchParams,
  arg: string
) => {
  return axios.get(
    `${url}api/Search/GetSmartSearch?take=${params.args.Take}` + arg,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
};

export const getLatestVacanciesRequest = () => {
  return axios.get(`${url}api/Vacancy/GetLatestVacancies`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const getNewCompaniesRequest = () => {
  return axios.get(`${url}api/Company/GetNewCompanies`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};
