import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import * as Api from '../../Api';

interface companyCompareNameType {
  readonly firstCompanyName: string;
  readonly secondCompanyName: string;
}

interface TechnologiesState {
  readonly technologies: Api.DictionaryType[];
  readonly companies: Api.CompanyType | null;
  readonly isLoading: boolean;
  readonly submittedFilteredTechnologyTags: string[];
  readonly submittedFilteredTechnologies: Api.DictionaryType['dictionaryList'] | undefined;
  readonly submittedFilterTeamSize: string;
  readonly submittedSortBy: number;
  readonly companyName: string;
  readonly companyAbout: string;
  readonly companyAboutGE: string;
  readonly companyId: string | undefined;
  readonly companyEmployees: number | null;
  readonly isHiring: boolean;
  readonly transformedName: string;
  readonly isClaimingNecessary?: boolean;
  readonly CompareCompanyNames: companyCompareNameType;
  readonly profileImage: string;
  readonly companyRate: null | {
    readonly averageRating: number;
    readonly starFive: number;
    readonly starFour: number;
    readonly starThree: number;
    readonly starTwo: number;
    readonly starOne: number;
    readonly totalReviews: number;
  };
}

const state: TechnologiesState = {
  technologies: [],
  companies: null,
  isLoading: false,
  submittedFilteredTechnologyTags: [],
  submittedFilteredTechnologies: [],
  submittedFilterTeamSize: '',
  companyName: '',
  companyAbout: '',
  companyAboutGE: '',
  companyId: undefined,
  submittedSortBy: 0,
  companyEmployees: null,
  isHiring: false,
  transformedName: '',
  CompareCompanyNames: {
    firstCompanyName: '',
    secondCompanyName: '',
  },
  companyRate: null,
  profileImage: '',
};

export const getAllDictionaries = createAsyncThunk<Api.DictionaryType[]>('companies/getDictionaries', async () => {
  const response = await Api.getAllDictionariesRequest();
  return response.data;
});

export const getSearchCompanies = createAsyncThunk<Api.CompanyType, string>(
  'companies/getSearchCompanies',
  async (queryString) => {
    const response = await Api.getSearchCompaniesRequest(queryString);
    return response.data;
  }
);

export const getCompaniesByName = createAsyncThunk<Api.companyByNamesType[], string>(
  'companies/getSearchCompanies',
  async (companyName) => {
    const response = await Api.getCompaniesByNameRequest(companyName);
    return response.data;
  }
);

export const getGetCompareCompanies = createAsyncThunk<Api.getCompanyComparisionRequestType>(
  'companies/getSearchCompanies',
  async () => {
    const response = await Api.getGetCompareCompaniesRequest();
    return response.data;
  }
);

export const getCompareCompanies = createAsyncThunk<Api.getCompareCompaniesType[], string>(
  'companies/getSearchCompanies',
  async (compareUrl) => {
    const response = await Api.getCompareCompaniesRequest(compareUrl);
    return response.data;
  }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCompanyDetails = createAsyncThunk<any, string>('companies/GetCompanyDetails', async (id) => {
  const response = await Api.getCompanyDetailsRequest(id);
  return response.data;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postUpdateCompanyAbout = createAsyncThunk<
  { data: object; erros: null; succeeded: boolean },
  { enText: string; geText: string }
>('companies/postUpdateCompanyAbout', async ({ enText, geText }) => {
  const response = await Api.postUpdateCompanyAboutRequest(enText, geText);
  return response.data;
});

export const postUpdateCompanyTagline = createAsyncThunk<{ data: object; erros: null; succeeded: boolean }, string>(
  'companies/postUpdateCompanyTagline',
  async (id: string, thunkApi) => {
    try {
      const response = await Api.postUpdateCompanyTaglineRequest(id);
      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }
      throw err;
    }
  }
);

export const postUpdateCompanyKeyFacts = createAsyncThunk<
  { data: object; erros: null; succeeded: boolean },
  Api.keyFactsType
>('companies/postUpdateCompanyKeyFacts', async (id: Api.keyFactsType) => {
  const response = await Api.postUpdateCompanyKeyFactsRequest(id);
  return response.data;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDictionary = createAsyncThunk<any>('companies/getDictionaryRequest', async () => {
  const response = await Api.getDictionaryRequest();
  return response.data;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postUpdateCompanyBenefits = createAsyncThunk<any, FormDataEntryValue[]>(
  'companies/postUpdateCompanyBenefitsRequest',
  async (benefits: FormDataEntryValue[]) => {
    const response = await Api.postUpdateCompanyBenefitsRequest(benefits);
    return response.data;
  }
);

export const getCompanyReviews = createAsyncThunk<Api.getCompanyReviewsType, string>(
  'companies/getCompanyReviews',
  async (companyId: string) => {
    const response = await Api.getCompanyReviewsRequest(companyId);
    return response.data;
  }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postCreateUpdateReview = createAsyncThunk<any, Api.createUpdateReviewType>(
  'companies/postCreateUpdateReview',
  async (data, thunkApi) => {
    try {
      const response = await Api.postCreateUpdateReviewRequest(data);
      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }
      throw err;
    }
  }
);

export const postLikeReview = createAsyncThunk<{ data: number }, string>(
  'companies/postLikePreview',
  async (reviewId) => {
    const response = await Api.postLikeReviewRequest(reviewId);
    return response.data;
  }
);

export const postDeleteReview = createAsyncThunk<{ data: string }, string>(
  'companies/postDeleteReview',
  async (reviewId) => {
    const response = await Api.postDeleteReviewRequest(reviewId);
    return response.data;
  }
);

export const getUserReview = createAsyncThunk<{ data: Api.getCompanyReviewsType['data'][0] }, string>(
  'companies/getUserReview',
  async (reviewId) => {
    const response = await Api.getUserReviewRequest(reviewId);
    return response.data;
  }
);

export const companiesSlice = createSlice({
  name: 'companies',
  initialState: state,
  reducers: {
    setSubmittedFilteredTechnologies: (
      state,
      action: PayloadAction<Api.DictionaryType['dictionaryList'] | undefined>
    ) => {
      state.submittedFilteredTechnologies = action.payload;
    },
    setSubmittedFilteredTechnologyTags: (state, action: PayloadAction<string[]>) => {
      state.submittedFilteredTechnologyTags = action.payload;
    },
    setSubmittedFilterTeamSize: (state, action: PayloadAction<string>) => {
      state.submittedFilterTeamSize = action.payload;
    },
    setSubmittedSortBy: (state, action: PayloadAction<number>) => {
      state.submittedSortBy = action.payload;
    },
    setIsHiring: (state, action: PayloadAction<boolean>) => {
      state.isHiring = action.payload;
    },
    setUpdateCompareCompanyNames: (state, action: PayloadAction<companyCompareNameType>) => {
      state.CompareCompanyNames = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDictionaries.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllDictionaries.fulfilled, (state, action) => {
        state.isLoading = false;
        state.technologies = action.payload;
      })
      .addCase(getAllDictionaries.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getSearchCompanies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSearchCompanies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.companies = action.payload;
      })
      .addCase(getSearchCompanies.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postUpdateCompanyAbout.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(postUpdateCompanyAbout.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postUpdateCompanyAbout.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postUpdateCompanyTagline.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(postUpdateCompanyTagline.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postUpdateCompanyTagline.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postUpdateCompanyKeyFacts.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(postUpdateCompanyKeyFacts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postUpdateCompanyKeyFacts.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getDictionary.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getDictionary.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postUpdateCompanyBenefits.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(postUpdateCompanyBenefits.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCompanyDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCompanyDetails.fulfilled, (state, action) => {
        state.companyName = action.payload.company.name;
        state.companyAbout = action.payload.company.about;
        state.companyAboutGE = action.payload.company.aboutGe;
        state.companyId = action.payload.company.id;
        state.companyEmployees = action.payload.company.teamSize;
        state.transformedName = action.payload.company.transformedName;
        state.isClaimingNecessary = action.payload.company.isClaimingNecessary;
        state.profileImage = action.payload.company.profileImageUrl;
        state.companyRate = action.payload.company.companyRate;
        state.isLoading = false;
      })
      .addCase(getCompanyDetails.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postCreateUpdateReview.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(postCreateUpdateReview.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postCreateUpdateReview.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postDeleteReview.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(postDeleteReview.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postDeleteReview.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserReview.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserReview.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getUserReview.fulfilled, (state) => {
        state.isLoading = false;
      });
    // .addCase(getCompanyReviews.fulfilled, state=> {
    //   state.isLoading = false
    // })
    // .addCase(getCompanyReviews.rejected, state=> {
    //   state.isLoading = false
    // })
    // .addCase(getCompanyReviews.pending, state=> {
    //   state.isLoading = true
    // })
  },
});

export const {
  setSubmittedFilteredTechnologyTags,
  setSubmittedFilteredTechnologies,
  setSubmittedFilterTeamSize,
  setSubmittedSortBy,
  setIsHiring,
  setUpdateCompareCompanyNames,
} = companiesSlice.actions;
export default companiesSlice.reducer;
