import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import * as Api from '../../Api';

interface VacanciesState {
  readonly isLoading: boolean;
  readonly modalLoading: boolean;
  readonly vacancyDetails?: Api.VacancyDetailType;
  readonly incorrectVacancyId: boolean;
  readonly vacancies: Api.SearchVacancyParams | null;
  readonly technologies: Api.DictionaryType[];
  readonly submittedFilteredObject: {
    Technologies: string[];
    EmploymentTypies: string[];
    WorkLocations: string[];
    [key: string]: string[];
  };
  readonly submittedFilteredTechnologies: Api.DictionaryType['dictionaryList'] | undefined;
  readonly submittedPage: number;
  readonly searchTermText: string;
  readonly submittedSortBy: number;
  readonly FormData: Api.ApplyToVacancyParams['body'];
  readonly otherAndSimilarVac?: {
    readonly similarVacancies: Api.VacancySearchType[];
    readonly otherVacancies: Api.VacancySearchType[];
  };
}

const state: VacanciesState = {
  isLoading: false,
  modalLoading: false,
  vacancyDetails: undefined,
  incorrectVacancyId: false,
  vacancies: null,
  technologies: [],
  submittedFilteredTechnologies: [],
  submittedFilteredObject: {
    Technologies: [],
    EmploymentTypies: [],
    WorkLocations: [],
  },
  submittedPage: 1,
  searchTermText: '',
  submittedSortBy: 1,
  FormData: {
    VacancyId: '',
    FullName: '',
    Email: '',
    Phone: '',
    Message: '',
    CV: undefined,
    UseLastVacancy: false,
  },
};

export const getVacancy = createAsyncThunk<Api.VacancyDetailType, Api.getVacancyRequestType>(
  'vacancy/getVacancy',
  async (params) => {
    const response = await Api.getVacancyRequest(params.id, params.fromAdmin);
    return response.data.data;
  }
);

export const getAllDictionaries = createAsyncThunk<
  Api.DictionaryType[],
  { id?: string; filter?: string; vacancy?: boolean }
>('vacancy/getDictionaries', async ({ id, filter, vacancy = true }) => {
  const response = await Api.getAllDictionariesRequest(vacancy, id, filter);
  return response.data;
});

export const getSearchVacancies = createAsyncThunk<Api.SearchVacancyParams, string>(
  'vacancies/getSearchVacancies',
  async (queryString) => {
    const response = await Api.getSearchVacanciesRequest(queryString);
    return response.data;
  }
);

export const postApplyToVacancy = createAsyncThunk(
  'Users/ApplyToVacancy',
  async (params: Api.ApplyToVacancyParams, thunkApi) => {
    try {
      const formData = new FormData();

      if (params.body.CV != null) {
        formData.append('CV', params.body.CV);
      }
      formData.append('VacancyId', params.body.VacancyId);
      formData.append('FullName', params.body.FullName);
      formData.append('Email', params.body.Email);
      formData.append('Phone', params.body.Phone);
      formData.append('Message', params.body.Message);
      formData.append('UseLastVacancy', params.body.UseLastVacancy.toString());

      const response = await Api.postApplyToVacancyRequest(formData);
      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }
      throw err;
    }
  }
);

export const postUploadCv = createAsyncThunk('Users/UploadCv', async (CV: File, thunkApi) => {
  try {
    const formData = new FormData();
    formData.append('CV', CV);

    const response = await Api.postUploadCvRequest(formData);
    return response.data;
  } catch (err) {
    const error = err;
    if (error) {
      return thunkApi.rejectWithValue((error as AxiosError).response?.data);
    }
    throw err;
  }
});

export const postRemoveCv = createAsyncThunk('Users/RemoveCv', async () => {
  try {
    const response = await Api.postRemoveCvRequest();
    return response.data;
  } catch (err) {
    const error = err;
    throw err;
  }
});

export const getVacancyApplicants = createAsyncThunk<Api.applicantsType, string>(
  'Users/VacancyApplicants',
  async (id: string) => {
    const response = await Api.getVacancyApplicantsRequest(id);
    return response.data;
  }
);

export const getVacancyApplicantsCv = createAsyncThunk<Blob, string[]>(
  'Users/vacancyApplicantsCv',
  async (id: string[]) => {
    const response = await Api.getVacancyApplicantsCvRequest(id);
    return response.data;
  }
);

export const getVacancyApplication = createAsyncThunk<Api.applicantDataType, string>(
  'Users/getVacancyApplication',
  async (id: string) => {
    const response = await Api.getVacancyApplicationRequest(id);
    return response.data;
  }
);

export const postFindTechnologiesInContext = createAsyncThunk<Api.findTechnologiesInContextType[], string>(
  'users/postFindTechnologiesInContext',
  async (data) => {
    const response = await Api.postFindTechnologiesInContextRequest(data);
    return response.data;
  }
);

export const getSimilarAndOtherVacancies = createAsyncThunk<
  Api.SearchOtherAndSimilarVacancyType,
  Api.GetSimilarAndOtherVacancyRequestParams
>('vacancies/getOtherAndSimVacancies', async (params) => {
  const response = await Api.getSimilarAndOtherVacancyRequest(params);
  return response.data.data;
});

export const vacanciesSlice = createSlice({
  name: 'vacancies',
  initialState: state,
  reducers: {
    eraseVacancyDetails: (state) => {
      state.vacancyDetails = undefined;
    },
    setSubmittedFilteredTechnologies: (
      state,
      action: PayloadAction<Api.DictionaryType['dictionaryList'] | undefined>
    ) => {
      state.submittedFilteredTechnologies = action.payload;
    },
    setSubmittedFilterObject: (
      state,
      action: PayloadAction<{
        Technologies: string[];
        EmploymentTypies: string[];
        WorkLocations: string[];
      }>
    ) => {
      state.submittedFilteredObject = action.payload;
      state.submittedPage = 1;
    },
    clearSubmittedFilterObject: (state) => {
      (state.submittedFilteredObject = {
        Technologies: [],
        EmploymentTypies: [],
        WorkLocations: [],
      }),
        (state.submittedSortBy = 1),
        (state.searchTermText = '');
    },
    setSubmittedSortBy: (state, action: PayloadAction<number>) => {
      state.submittedSortBy = action.payload;
    },
    setSubmittedPage: (state, action: PayloadAction<number>) => {
      state.submittedPage = action.payload;
    },
    setSearchTermText: (state, action: PayloadAction<string>) => {
      state.searchTermText = action.payload;
      state.submittedPage = 1;
    },
    updateFormData: (state, action: PayloadAction<Api.ApplyToVacancyParams['body']>) => {
      state.FormData = action.payload;
    },
    clearOtherAndSimilarVac: (state) => {
      state.otherAndSimilarVac = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVacancy.fulfilled, (state, action) => {
        state.vacancyDetails = action.payload;
        if (action.payload === null) state.incorrectVacancyId = true;
        else state.incorrectVacancyId = false;
        state.isLoading = false;
      })
      .addCase(getVacancy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVacancy.rejected, (state) => {
        state.isLoading = false;
        state.incorrectVacancyId = true;
      })
      .addCase(getAllDictionaries.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllDictionaries.fulfilled, (state, action) => {
        state.isLoading = false;
        state.technologies = action.payload;
      })
      .addCase(getAllDictionaries.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getSearchVacancies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSearchVacancies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.vacancies = action.payload;
      })
      .addCase(getSearchVacancies.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postApplyToVacancy.pending, (state) => {
        // state.isLoading = true;
        state.modalLoading = true;
      })
      .addCase(postApplyToVacancy.fulfilled, (state, action) => {
        state.modalLoading = false;
        state.vacancies = action.payload;
      })
      .addCase(postApplyToVacancy.rejected, (state) => {
        state.modalLoading = false;
      })
      .addCase(getSimilarAndOtherVacancies.fulfilled, (state, action) => {
        state.otherAndSimilarVac = action.payload;
      })
      .addCase(getVacancyApplicants.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getVacancyApplicants.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getVacancyApplicants.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVacancyApplicantsCv.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVacancyApplicantsCv.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getVacancyApplicantsCv.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getVacancyApplication.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVacancyApplication.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getVacancyApplication.fulfilled, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  eraseVacancyDetails,
  setSubmittedFilteredTechnologies,
  setSubmittedFilterObject,
  setSubmittedSortBy,
  setSubmittedPage,
  setSearchTermText,
  clearSubmittedFilterObject,
  updateFormData,
  clearOtherAndSimilarVac,
} = vacanciesSlice.actions;
export default vacanciesSlice.reducer;
