import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import * as Api from '../../Api';

interface SignInState {
  readonly isLoading: boolean;
  readonly isAuthorizeFailed: boolean;
}

const state: SignInState = {
  isLoading: false,
  isAuthorizeFailed: false,
};

export const postUserSignIn = createAsyncThunk<Api.AuthenticationType, Api.postUserSignInRequestParams>(
  'account/postUserSignIn',
  async (params: Api.postUserSignInRequestParams, thunkApi) => {
    try {
      const response = await Api.postUserSignInRequest(params);
      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }

      throw err;
    }
  }
);

export const postRefreshToken = createAsyncThunk<Api.RefreshTokenType, Api.postRefreshTokenRequestParams>(
  'account/postRefreshToken',
  async (params: Api.postRefreshTokenRequestParams, thunkApi) => {
    try {
      const response = await Api.postRefreshTokenRequest(params);
      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }

      throw err;
    }
  }
);

export const signInSlice = createSlice({
  name: 'signIn',
  initialState: state,
  reducers: {
    updateIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postUserSignIn.pending, (state) => {
        state.isLoading = true;
        state.isAuthorizeFailed = false;
      })
      .addCase(postUserSignIn.fulfilled, (state) => {
        state.isLoading = false;
        state.isAuthorizeFailed = false;
      })
      .addCase(postUserSignIn.rejected, (state) => {
        state.isLoading = false;
        state.isAuthorizeFailed = true;
      })
      .addCase(postRefreshToken.pending, (state) => {
        state.isLoading = true;
        state.isAuthorizeFailed = false;
      })
      .addCase(postRefreshToken.fulfilled, (state) => {
        state.isLoading = false;
        state.isAuthorizeFailed = false;
      })
      .addCase(postRefreshToken.rejected, (state) => {
        state.isLoading = false;
        state.isAuthorizeFailed = true;
      });
  },
});

export const { updateIsLoading } = signInSlice.actions;

export default signInSlice.reducer;
