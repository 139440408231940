import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import classes from './header.module.sass';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import LanguageSwitcher from '../ReusableComponents/LanguageSwitcher/LanguageSwitcher';
import { RiArrowDownSLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../Redux/store';
import { logIn, logOut } from '../../../Redux/slices/authSlice';
import { UserIcon } from '../../Svgs';
// import { ReactComponent as LogOutIcon } from '../../../Assets/Icons/logout-icon.svg';
import { UserTypeEnum } from '../../../Api';
import useOutsideClick from '../../../Hooks/useOutsideClick';
import useResources from '../../../Hooks/useResources';
import { resetUserSubscribedCompanies } from '../../../Redux/slices/userPanelSlice';
import { ReactSVG } from 'react-svg';

const Header = () => {
  const { fullName, isLoggedIn, smallProfileImageUrl, userType, viewAs } = useSelector(
    (state: RootState) => state.auth
  );
  const { progressData, companyPanelInfo } = useSelector((state: RootState) => state.CompanyPanel);
  const dispatch = useDispatch();
  const resources = useResources();
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileInfoOpen, setIsMobileInfoOpen] = useState(false);
  const dropDownContainerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const adminVerified = companyPanelInfo.data.isVerified;
  const verified = adminVerified || progressData.progress === 100;
  const firstName = userType === UserTypeEnum.User ? fullName && fullName.split(' ')[0] : fullName;

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const app = document.getElementsByClassName('app')[0] as HTMLDivElement;
    const top = app.scrollTop;
    setScrollPosition(top);
  };

  useEffect(() => {
    const app = document.getElementsByClassName('app')[0] as HTMLDivElement;
    if (!app) return;
    app.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      app.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useOutsideClick(dropDownContainerRef, () => {
    if (isDropdownOpen) {
      setIsDropdownOpen(false);
    }
  });

  const toggleMobileMenu = () => {
    window.scroll(0, 1);

    setIsMobileOpen((prev) => {
      if (prev) {
        document.body.classList.remove('fixed');
      } else {
        document.body.classList.add('fixed');
      }

      return !prev;
    });
  };

  const handleNavigate = (path: string, e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    document.body.classList.remove('fixed');
    setIsMobileOpen(false);
    setIsDropdownOpen(false);
    if (e) {
      (e.target as HTMLButtonElement).blur();
    }
    navigate(path);
  };

  const handleLogOut = (viewAs?: boolean) => {
    dispatch(logOut());
    dispatch(resetUserSubscribedCompanies());
    setIsDropdownOpen(false);
    if (viewAs) window.location.href = `https://admin.techwave.ge`;
    else navigate('/');
  };

  const showLogInButtons = location.pathname !== '/partners';

  const userMenuDisplay = (menuClass: string) => {
    return (
      <>
        {userType === UserTypeEnum.User && (
          <>
            <button
              className={`${menuClass} ${location.pathname === '/userPanel/generalInformation' ? classes.active : ''} `}
              onClick={(e) => {
                handleNavigate('/userPanel/generalInformation', e);
              }}
            >
              {resources.Reusable.myProfile}
            </button>
            <button
              className={`${menuClass} ${
                location.pathname === '/userPanel/settings/passwordChange' ? classes.active : ''
              } `}
              onClick={(e) => handleNavigate('/userPanel/settings/passwordChange', e)}
            >
              {resources.Header.changePassword}
            </button>
            <button
              className={`${menuClass} ${location.pathname === '/userPanel/user-subscriptions' ? classes.active : ''} `}
              onClick={(e) => {
                handleNavigate('/userPanel/user-subscriptions', e);
              }}
            >
              {resources.Reusable.mySubscriptions}
            </button>
          </>
        )}
        {userType === UserTypeEnum.Company && (
          <>
            <button
              className={`${menuClass} ${
                location.pathname === `/companyPanel/generalInformation` ? classes.active : ''
              } `}
              onClick={(e) => {
                handleNavigate(`/companyPanel/generalInformation`, e);
              }}
            >
              {resources.Companies.myCompany}
            </button>
            <button
              className={`${menuClass} ${
                location.pathname === '/companyPanel/vacancies/active' ? classes.active : ''
              } ${!verified ? classes.disabled : ''}`}
              onClick={(e) => handleNavigate('/companyPanel/vacancies/active', e)}
            >
              {resources.Reusable.vacancies}
            </button>
            <button
              className={`${menuClass} ${
                location.pathname === '/companyPanel/settings/passwordChange' ? classes.active : ''
              } `}
              onClick={(e) => handleNavigate('/companyPanel/settings/passwordChange', e)}
            >
              {resources.Header.changePassword}
            </button>
          </>
        )}
      </>
    );
  };

  return (
    <>
      {viewAs && (
        <div className={classes.viewAs}>
          <p>
            {resources.Header.ViewingAs}
            <b>'{fullName}'</b>
          </p>
          <button onClick={() => handleLogOut(true)}>{resources.Header.exitViewAs}</button>
        </div>
      )}
      <header
        className={`${classes.component} ${!showLogInButtons ? classes.component_partners : ''}   ${
          isMobileOpen ? classes.open : ''
        } ${scrollPosition !== 0 ? classes.isScrolled : ''}`}
      >
        <div className='base_container'>
          <div className={classes.content}>
            <nav className={classes.nav}>
              <div className={classes.nav_left}>
                <div className={classes.nav_left_content}>
                  <Link to='/' className={classes.nav_logo_wr}>
                    <img
                      src={'https://softwaregecdn.azureedge.net/websitefiles/images/logo_new.svg'}
                      alt='Techwave.ge'
                      className={classes.nav_logo}
                      width={125}
                      height={34}
                    />
                  </Link>
                </div>
              </div>
              <div className={classes.nav_middle}>
                <div className={classes.nav_middle_content}>
                  <ul className={classes.nav_links}>
                    <li className={classes.nav_link_item}>
                      <NavLink
                        to='/companies'
                        className={(routeData) =>
                          routeData.isActive ? `${classes.nav_link} ${classes.active}` : classes.nav_link
                        }
                      >
                        {resources.Reusable.companies}
                      </NavLink>
                    </li>
                    <li className={classes.nav_link_item}>
                      <NavLink
                        to='/vacancies'
                        className={(routeData) =>
                          routeData.isActive ? `${classes.nav_link} ${classes.active}` : classes.nav_link
                        }
                      >
                        {resources.Reusable.vacancies}
                      </NavLink>
                    </li>
                    <li className={classes.nav_link_item}>
                      <NavLink
                        to='/news'
                        className={(routeData) =>
                          routeData.isActive ? `${classes.nav_link} ${classes.active}` : classes.nav_link
                        }
                      >
                        {resources.Reusable.blog}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              {!showLogInButtons && (
                <div className={classes.nav_right}>
                  <div className={classes.nav_right_content}>
                    {/* not logged in */}
                    <div className={classes.nav_sign_links}>
                      <NavLink to='/signIn' className={classes.nav_signup_link} onClick={() => dispatch(logIn())}>
                        {resources.Header.signIn}
                      </NavLink>
                    </div>
                  </div>
                </div>
              )}
              {showLogInButtons && (
                <div className={classes.nav_right}>
                  <div className={classes.nav_right_content}>
                    {/* not logged in */}
                    {!isLoggedIn && (
                      <div className={classes.nav_sign_links}>
                        <NavLink to='/signIn' className={classes.nav_signin_link} onClick={() => dispatch(logIn())}>
                          {resources.Header.signIn}
                        </NavLink>
                        <NavLink to='/signUp' className={classes.nav_signup_link}>
                          {resources.Header.signUp}
                        </NavLink>
                      </div>
                    )}

                    {/* logged in */}
                    {isLoggedIn && (
                      <div
                        ref={dropDownContainerRef}
                        className={`${classes.nav_profile_info} ${isDropdownOpen && classes.open}`}
                      >
                        <button className={classes.nav_profile_btn} onClick={() => setIsDropdownOpen((p) => !p)}>
                          {smallProfileImageUrl ? (
                            <img src={smallProfileImageUrl} className={classes.nav_profile_icon} alt='icon' />
                          ) : (
                            <div className={classes.navProfileIcon}>
                              <UserIcon fill='white' height='11' width='11' />
                            </div>
                          )}

                          <span className={classes.nav_profile_username}>
                            {firstName}
                            <RiArrowDownSLine />
                          </span>
                        </button>
                        <div className={classes.nav_profile_infolist}>
                          <div className={classes.nav_profile_info_links}>
                            {userMenuDisplay(classes.nav_profile_info_link)}
                          </div>

                          <div className={classes.nav_profile_logout_btnwr}>
                            <button className={classes.nav_profile_logout_btn} onClick={() => handleLogOut()}>
                              {resources.Header.logOut}
                              <ReactSVG src='https://softwaregecdn.azureedge.net/websitefiles/icons/logout-icon.svg' />

                              {/* <LogOutIcon /> */}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className={classes.nav_lang_switcher_wr}>
                      <LanguageSwitcher />
                    </div>
                  </div>
                </div>
              )}
            </nav>

            <button className={classes.burger_toggler} onClick={toggleMobileMenu}></button>
          </div>
        </div>

        {isMobileOpen && (
          <div className={classes.mobile_menu}>
            <div className={classes.mobile_menu_content}>
              <ul className={classes.mobile_menu_links}>
                <li className={classes.mobile_menu_linkitem}>
                  <button
                    className={`${classes.mobile_menu_link} ${location.pathname === '/about' ? classes.active : ''}`}
                    onClick={() => handleNavigate('/about')}
                  >
                    {resources.Header.aboutUs}
                  </button>
                </li>

                <li className={classes.mobile_menu_linkitem}>
                  <button
                    className={`${classes.mobile_menu_link} ${
                      location.pathname === '/companies' ? classes.active : ''
                    }`}
                    onClick={() => handleNavigate('/companies')}
                  >
                    {resources.Reusable.companies}
                  </button>
                </li>

                <li className={classes.mobile_menu_linkitem}>
                  <button
                    className={`${classes.mobile_menu_link} ${
                      location.pathname === '/vacancies' ? classes.active : ''
                    }`}
                    onClick={() => handleNavigate('/vacancies')}
                  >
                    {resources.Reusable.vacancies}
                  </button>
                </li>
                <li className={classes.mobile_menu_linkitem}>
                  <button
                    className={`${classes.mobile_menu_link} ${location.pathname === '/compare' ? classes.active : ''}`}
                    onClick={() => handleNavigate('/compare')}
                  >
                    {resources.Compare.compare}
                  </button>
                </li>
                <li className={classes.mobile_menu_linkitem}>
                  <button
                    className={`${classes.mobile_menu_link} ${location.pathname === '/news' ? classes.active : ''}`}
                    onClick={() => handleNavigate('/news')}
                  >
                    {resources.Reusable.blog}
                  </button>
                </li>
              </ul>

              {showLogInButtons && (
                <>
                  <div className={classes.mobile_menu_langswitcher}>
                    <LanguageSwitcher />
                  </div>

                  {/* Not Logged In */}
                  {!isLoggedIn && (
                    <div className={classes.mobile_menu_sign_links}>
                      <button className={classes.mobile_menu_signin} onClick={() => handleNavigate('/signIn')}>
                        {resources.Header.signIn}
                      </button>
                      <button className={classes.mobile_menu_signup} onClick={() => handleNavigate('/signUp')}>
                        {resources.Header.signUp}
                      </button>
                    </div>
                  )}

                  {/* LoggedIn */}
                  {isLoggedIn && (
                    <div className={classes.navmob_profile_info}>
                      <button className={classes.navmob_profile_btn} onClick={() => setIsMobileInfoOpen((p) => !p)}>
                        {smallProfileImageUrl ? (
                          <img src={smallProfileImageUrl} className={classes.navmob_profile_icon} alt='icon' />
                        ) : (
                          <div className={classes.navProfileIcon}>
                            <UserIcon fill='white' height='11' width='11' />
                          </div>
                        )}
                        <span
                          className={`${classes.navmob_profile_username} ${isMobileInfoOpen ? classes.active : ''}`}
                        >
                          {fullName}
                          <RiArrowDownSLine />
                        </span>
                      </button>

                      {isMobileInfoOpen && (
                        <div className={classes.navmob_profile_infolist}>
                          <div className={classes.navmob_profile_info_links}>
                            {userMenuDisplay(classes.navmob_profile_info_link)}
                          </div>

                          <div className={classes.navmob_profile_logout_btnwr}>
                            <button className={classes.navmob_profile_logout_btn} onClick={() => dispatch(logOut())}>
                              {resources.Header.logOut}
                              <ReactSVG src='https://softwaregecdn.azureedge.net/websitefiles/icons/logout-icon.svg' />

                              {/* <LogOutIcon /> */}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </header>
    </>
  );
};

export default Header;
