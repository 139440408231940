import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classes from './CookiesModal.module.sass';

const CookiesModal = () => {
  const [cookiesModal, setCookiesModal] = useState('hidden');
  const [hideCookie, setHideCookie] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('cookiesModal') === 'accept') {
      setCookiesModal('accept');
    } else if (localStorage.getItem('cookiesModal') === 'decline') {
      setCookiesModal('accept');
    } else if (!localStorage.getItem('cookiesModal') && hideCookie) {
      setCookiesModal('hidden');
    } else {
      setCookiesModal('visible');
    }
  }, [cookiesModal, hideCookie]);

  const cookiesModalDecline = () => {
    setCookiesModal('decline');
    localStorage.setItem('cookiesModal', 'decline');
  };
  const cookiesModalAccept = () => {
    setCookiesModal('accept');
    localStorage.setItem('cookiesModal', 'accept');
  };

  return (
    <>
      {cookiesModal === 'visible' && (
        <div className={classes.cookiesmodal}>
          <button className={classes.cookies_toggler} onClick={() => setHideCookie(true)}></button>
          <div className={classes.cookies_content}>
            <p className={classes.cookies_copy}>
              <span className={classes.strong}>Cookies:</span> We would like to collect data from your device while you
              use this website. We do this using cookies. You can find out more in our{' '}
              <Link to='/Information/privacyPolicy' className={classes.link}>
                cookie policy
              </Link>
              . Collecting this data helps us provide the best experience for you, keeps your account secure, helps us
              provide social media features and allows us to personalise advert and service message content. Please
              select 'Accept all' to consent to us collecting your data in this way. To see other data collection
              options, select 'Preferences'.
            </p>
            <div className={classes.cookies_action}>
              <button className={classes.accept} onClick={cookiesModalAccept}>
                Accept
              </button>
              <button className={classes.decline} onClick={cookiesModalDecline}>
                Decline
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookiesModal;
