import axios from 'axios';
import {
  CompanyVacancyRequestParams,
  GetSimilarAndOtherVacancyRequestParams,
  ResponseModel,
  VacancySearchType,
} from '..';

const url = process.env.REACT_APP_API_URL;

export const getAuthorizeCompanyVacanciesRequest = async (props: {
  type: number;
}): Promise<ResponseModel<VacancySearchType[]>> => {
  return axios.get(`${url}api/Vacancy/GetAuthorizeCompanyVacancies?Type=${props.type}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
};

export const getVacancyRequest = (id: string, fromAdmin?: string) => {
  return axios.get(`${url}api/Vacancy/GetVacancy?vacancyId=${id}${fromAdmin ? '&fromAdmin=true' : ''}`);
};

export const getSimilarAndOtherVacancyRequest = (params: GetSimilarAndOtherVacancyRequestParams) =>
  axios.post(`${url}api/Vacancy/SearchCompanySimAndOtherVacancies`, params, {
    headers: {},
  });

export const getCompanyVacancyRequest = (props: { vacancyId: string }) => {
  return axios.get(`${url}api/Vacancy/GetCompanyVacancy?vacancyId=${props.vacancyId}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
};

export const postDeleteCompanyVacancyRequest = (id: string) => {
  return axios.post(`${url}api/Vacancy/DeleteCompanyVacancy?vacancyId=${id}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
};

export const postEndCompanyVacancyRequest = (id: string) => {
  return axios.post(`${url}api/Vacancy/EndCompanyVacancy?vacancyId=${id}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
};

export const postRenewCompanyVacancyRequest = (id: string) => {
  return axios.post(`${url}api/Vacancy/RenewCompanyVacancy?vacancyId=${id}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
};

export const postCreateCompanyVacancyRequest = (params: CompanyVacancyRequestParams) => {
  return axios.post(`${url}api/Vacancy/CreateCompanyVacancy`, params.body, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
};

export const postUpdateCompanyVacancyRequest = (params: CompanyVacancyRequestParams) => {
  return axios.post(`${url}api/Vacancy/UpdateCompanyVacancy?vacancyId=${params.query.vacancyId}`, params.body, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
};

export const getSearchVacanciesRequest = (queryString: string) => {
  return axios.get(`${url}api/Vacancy/SearchVacancies${queryString ? `${queryString}&pageSize=36` : `?&pageSize=36`}`);
};

export const getVacancyApplicantsRequest = (id: string) => {
  return axios.get(`${url}api/vacancy/getVacancyApplicants?vacancyId=${id}`);
};

export const getVacancyApplicantsCvRequest = (ids: string[]) => {
  return axios.post(`${url}api/vacancy/getVacancyApplicantsCv`, ids, {
    responseType: 'blob',
  });
};

export const getVacancyApplicationRequest = (id: string) => {
  return axios.get(`${url}api/vacancy/getVacancyApplication?applicationId=${id}`);
};
