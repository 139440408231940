import Aidnet from '../../../Assets/Images/CompanyLogos/Black/aidnet.jpg';
import Apex from '../../../Assets/Images/CompanyLogos/Black/apex.jpg';
import Apex2 from '../../../Assets/Images/CompanyLogos/Black/apex2.jpg';
import Cactus from '../../../Assets/Images/CompanyLogos/Black/cactus.jpg';
import CandiKandy from '../../../Assets/Images/CompanyLogos/Black/candiKandy.jpg';
import Exadel from '../../../Assets/Images/CompanyLogos/Black/exadel.jpg';
import FRT from '../../../Assets/Images/CompanyLogos/Black/frt.jpg';
import Gegidze from '../../../Assets/Images/CompanyLogos/Black/gegidze.jpg';
import Lightspeed from '../../../Assets/Images/CompanyLogos/Black/lightspeed.jpg';
import LogNSoftwareDevelopment from '../../../Assets/Images/CompanyLogos/Black/log_n_software_development.jpg';
import Noxtton from '../../../Assets/Images/CompanyLogos/Black/noxtton.jpg';
import OnePoint from '../../../Assets/Images/CompanyLogos/Black/one_point.jpg';
import Picnic from '../../../Assets/Images/CompanyLogos/Black/picnic.jpg';
import Rebank from '../../../Assets/Images/CompanyLogos/Black/rebank.jpg';
import RelityInteractive from '../../../Assets/Images/CompanyLogos/Black/relity_interactive.jpg';
import Scandiweb from '../../../Assets/Images/CompanyLogos/Black/scandiweb.jpg';
import SDSolutions from '../../../Assets/Images/CompanyLogos/Black/sd_solutions.jpg';
import SoftwareDevelopment from '../../../Assets/Images/CompanyLogos/Black/software_development.jpg';
import Weigandt from '../../../Assets/Images/CompanyLogos/Black/weigandt.jpg';
import WhiteLabel from '../../../Assets/Images/CompanyLogos/Black/white_label.jpg';
import WizardsDev from '../../../Assets/Images/CompanyLogos/Black/wizardsDev.jpg';

import AidnetColor from '../../../Assets/Images/CompanyLogos/Color/aidnet.jpg';
import ApexColor from '../../../Assets/Images/CompanyLogos/Color/apex.jpg';
import Apex2Color from '../../../Assets/Images/CompanyLogos/Color/apex2.jpg';
import CactusColor from '../../../Assets/Images/CompanyLogos/Color/cactus.jpg';
import CandiKandyColor from '../../../Assets/Images/CompanyLogos/Color/candiKandy.jpg';
import ExadelColor from '../../../Assets/Images/CompanyLogos/Color/exadel.jpg';
import FRTColor from '../../../Assets/Images/CompanyLogos/Color/frt.jpg';
import GegidzeColor from '../../../Assets/Images/CompanyLogos/Color/gegidze.jpg';
import LightspeedColor from '../../../Assets/Images/CompanyLogos/Color/lightspeed.jpg';
import LogNSoftwareDevelopmentColor from '../../../Assets/Images/CompanyLogos/Color/log_n_software_development.jpg';
import NoxttonColor from '../../../Assets/Images/CompanyLogos/Color/noxtton.jpg';
import OnePointColor from '../../../Assets/Images/CompanyLogos/Color/one_point.jpg';
import PicnicColor from '../../../Assets/Images/CompanyLogos/Color/picnic.jpg';
import RebankColor from '../../../Assets/Images/CompanyLogos/Color/rebank.jpg';
import RelityInteractiveColor from '../../../Assets/Images/CompanyLogos/Color/relity_interactive.jpg';
import ScandiwebColor from '../../../Assets/Images/CompanyLogos/Color/scandiweb.jpg';
import SDSolutionsColor from '../../../Assets/Images/CompanyLogos/Color/sd_solutions.jpg';
import SoftwareDevelopmentColor from '../../../Assets/Images/CompanyLogos/Color/software_development.jpg';
import WeigandtColor from '../../../Assets/Images/CompanyLogos/Color/weigandt.jpg';
import WhiteLabelColor from '../../../Assets/Images/CompanyLogos/Color/white_label.jpg';
import WizardsDevColor from '../../../Assets/Images/CompanyLogos/Color/wizardsDev.jpg';

const companies = [
  {
    name: 'Arttteo Software Development',
    logo: SoftwareDevelopment,
    logoHover: SoftwareDevelopmentColor,
    link: 'https://www.techwave.ge/companies/arttteo-software-development',
  },
  {
    name: 'SD Solutions',
    logo: SDSolutions,
    logoHover: SDSolutionsColor,
    link: 'https://www.techwave.ge/companies/sd-solutions',
  },
  {
    name: 'Scandiweb',
    logo: Scandiweb,
    logoHover: ScandiwebColor,
    link: 'https://www.techwave.ge/companies/scandiweb',
  },
  {
    name: 'Exadel Georgia',
    logo: Exadel,
    logoHover: ExadelColor,
    link: 'https://www.techwave.ge/companies/exadel-georgia',
  },
  {
    name: 'Flat Rock Technology',
    logo: FRT,
    logoHover: FRTColor,
    link: 'https://www.techwave.ge/companies/flat-rock-technology',
  },
  {
    name: 'Noxtton',
    logo: Noxtton,
    logoHover: NoxttonColor,
    link: 'https://www.techwave.ge/companies/noxtton',
  },
  {
    name: 'APEX Ltd',
    logo: Apex,
    logoHover: ApexColor,
    link: 'https://www.techwave.ge/companies/apex-ltd',
  },
  {
    name: 'Gegidze Agency',
    logo: Gegidze,
    logoHover: GegidzeColor,
    link: 'https://www.techwave.ge/companies/gegidze-agency',
  },
  {
    name: 'ReBank',
    logo: Rebank,
    logoHover: RebankColor,
    link: 'https://www.techwave.ge/companies/rebank',
  },
  {
    name: 'Log N Software Development',
    logo: LogNSoftwareDevelopment,
    logoHover: LogNSoftwareDevelopmentColor,
    link: 'https://www.techwave.ge/companies/log-n-software-development',
  },
  {
    name: 'Lightspeed',
    logo: Lightspeed,
    logoHover: LightspeedColor,
    link: 'https://www.techwave.ge/companies/lightspeed',
  },
  {
    name: 'White Label Agency',
    logo: WhiteLabel,
    logoHover: WhiteLabelColor,
    link: 'https://www.techwave.ge/companies/white-label-agency',
  },
  {
    name: 'Picnic',
    logo: Picnic,
    logoHover: PicnicColor,
    link: 'https://www.techwave.ge/companies/picnic',
  },
  {
    name: 'Reality Interactive',
    logo: RelityInteractive,
    logoHover: RelityInteractiveColor,
    link: 'https://www.techwave.ge/companies/reality-interactive',
  },
  {
    name: 'Aidnet',
    logo: Aidnet,
    logoHover: AidnetColor,
    link: 'https://www.techwave.ge/companies/aidnet',
  },
  {
    name: 'CactusSoft',
    logo: Cactus,
    logoHover: CactusColor,
    link: 'https://www.techwave.ge/companies/cactussoft',
  },
  {
    name: 'CandiKandy',
    logo: CandiKandy,
    logoHover: CandiKandyColor,
    link: 'https://www.techwave.ge/companies/candikandy',
  },
  {
    name: 'One Point HR Outsourcing',
    logo: OnePoint,
    logoHover: OnePointColor,
    link: 'https://www.techwave.ge/companies/one-point-hr-outsourcing',
  },
  {
    name: 'Weigandt Consulting GmbH',
    logo: Weigandt,
    logoHover: WeigandtColor,
    link: 'https://www.techwave.ge/companies/weigandt-consulting-gmbh',
  },
  {
    name: 'WizardsDev',
    logo: WizardsDev,
    logoHover: WizardsDevColor,
    link: 'https://www.techwave.ge/companies/wizardsdev',
  },
];

export default companies;
