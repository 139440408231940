import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import classes from './styles.module.sass';
import FormInputFieldValidation from '../../../../Components/UI/ReusableComponents/FormInputFieldValidation/FormInputFieldValidation';
import Modal from '../../../../Components/UI/ReusableComponents/Modal/Modal';
import useResources from '../../../../Hooks/useResources';
import Button from '../../../../Components/UI/ReusableComponents/Button/Button';
import {
  KeyfactDataType,
  combinedKeyFactType,
  companyKeyFactParams,
  companyKeyFactType,
  keyFactEnum,
} from '../../../../Api';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../Redux/store';
import Chekbox from '../../../../Components/UI/ReusableComponents/Chekbox/Chekbox';
import Radio from '../../../../Components/UI/ReusableComponents/Radio/Radio';
import { postCreateUpdateCompanyKeyFact } from '../../../../Redux/slices/companyPanelSlice';
import { toast } from 'react-toastify';
import RadioSelect from '../../../../Components/UI/ReusableComponents/RadioSelect/RadioSelect';

interface Props {
  keyFacts: KeyfactDataType | companyKeyFactType | undefined;
  setShowOverviewModal: React.Dispatch<React.SetStateAction<KeyfactDataType | companyKeyFactType | undefined>>;
  showOverviewModal: boolean;
  refetchData: () => void;
}

function OverviewForm({ keyFacts, showOverviewModal, setShowOverviewModal, refetchData }: Props) {
  const resources = useResources();
  const dispatch = useAppDispatch();
  const { SelectedLanguage } = useSelector((state: RootState) => state.language);
  const [searchOptVal, setSearchOptVal] = useState<string>('');

  if (!keyFacts) return null;

  const initialValues: combinedKeyFactType = {
    id: keyFacts.type === 'companyKeyFactType' ? keyFacts.keyFactId : keyFacts.id,
    name: keyFacts.name,
    nameGE: keyFacts.nameGE,
    options: keyFacts.options,
    checkedOptions: keyFacts?.type === 'companyKeyFactType' ? keyFacts.checkedOptions : null,
    descriptionEN: keyFacts?.type === 'companyKeyFactType' ? keyFacts.descriptionEN : '',
    descriptionGE: keyFacts?.type === 'companyKeyFactType' ? keyFacts.descriptionGE : '',
    descriptionNumber: keyFacts?.type === 'companyKeyFactType' ? keyFacts.descriptionNumber : null,
    hide: keyFacts?.type === 'companyKeyFactType' ? false : keyFacts.hide,
    isMandatory: keyFacts.isMandatory,
    keyFactType: keyFacts.keyFactType,
    orderIndex: keyFacts.orderIndex,
  };

  const handleSubmit = (data: combinedKeyFactType) => {
    const result: companyKeyFactParams = {
      keyFactId: data.id,
      descriptionGE: data.descriptionGE,
      descriptionEN: data.descriptionEN,
      descriptionNumber: data.descriptionNumber ? data.descriptionNumber : 0,
      checkedOptions: data.checkedOptions ? data.checkedOptions : [],
    };

    dispatch(postCreateUpdateCompanyKeyFact(result)).then((res) => {
      // @ts-ignore
      if (res.payload.error) return toast.error(res.payload.error);
      setShowOverviewModal(undefined);
      refetchData();
    });
  };

  const handleCheck = (values: combinedKeyFactType, id: string) => {
    if (values.checkedOptions?.find((opt) => opt === id)) {
      return values.checkedOptions.filter((el) => el !== id);
    }
    if (!values.checkedOptions) return [id];
    return [...values.checkedOptions, id];
  };

  return (
    <Modal
      action={resources.Companies.AboutCompanyOverview}
      subTitle={SelectedLanguage === 'ka-GE' ? keyFacts.nameGE : keyFacts.name}
      onClick={() => setShowOverviewModal(undefined)}
      visible={showOverviewModal}
    >
      {keyFacts && (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
          {({ errors, touched, values, isValid, setFieldValue }) => (
            <Form className={classes.infoForm}>
              <div>
                {!isValid && values.keyFactType !== keyFactEnum.Year && (
                  <div className={classes.errorBox}>{resources.Companies.incorrectFields}</div>
                )}

                {values.keyFactType === keyFactEnum.Text && (
                  <div key={'text'}>
                    <FormInputFieldValidation
                      labelName={values.name}
                      valueName='descriptionEN'
                      value={values.descriptionEN?.toString()}
                      error={touched.descriptionEN ? errors.descriptionEN : ''}
                      touched={touched.descriptionEN != null && touched.descriptionEN}
                      autoComplete='descriptionEN'
                    />
                    <FormInputFieldValidation
                      labelName={values.nameGE}
                      valueName='descriptionGE'
                      value={values.descriptionGE?.toString()}
                      error={touched.descriptionGE ? errors.descriptionGE : ''}
                      touched={touched.descriptionGE != null && touched.descriptionGE}
                      autoComplete='descriptionGE'
                    />
                  </div>
                )}

                {values.keyFactType === keyFactEnum.Number && (
                  <div key={'number'}>
                    <FormInputFieldValidation
                      labelName={SelectedLanguage === 'ka-GE' ? values.nameGE : values.name}
                      valueName='descriptionNumber'
                      value={values.descriptionNumber?.toString()}
                      error={touched.descriptionNumber ? errors.descriptionNumber : ''}
                      touched={touched.descriptionNumber != null && touched.descriptionNumber}
                      autoComplete='descriptionNumber'
                      type='number'
                    />
                  </div>
                )}

                {values.keyFactType === keyFactEnum.Year && (
                  <div key={'number'}>
                    <FormInputFieldValidation
                      labelName={SelectedLanguage === 'ka-GE' ? values.nameGE : values.name}
                      valueName='descriptionNumber'
                      value={values.descriptionNumber?.toString()}
                      error={touched.descriptionNumber ? errors.descriptionNumber : ''}
                      touched={touched.descriptionNumber != null && touched.descriptionNumber}
                      autoComplete='descriptionNumber'
                      type='number'
                      validate={(value: string) =>
                        +value < 1800 || +value > new Date().getFullYear() ? resources.Companies.validYear : ''
                      }
                    />
                  </div>
                )}

                {values.keyFactType === keyFactEnum.MultipleChoice && (
                  <div key={'multiple'}>
                    <div className={classes.searchInputWrapper}>
                      <RadioSelect
                        label={`${resources.Reusable.choose} ${
                          SelectedLanguage === 'ka-GE' ? values.nameGE : values.name
                        }`}
                        chosenValue={searchOptVal}
                        setChosenValue={setSearchOptVal}
                        placeHolder={resources.Reusable.type}
                        normalInput
                        id={values.id}
                      />
                    </div>
                    {/* only checked options */}
                    {values.options?.map((el) => {
                      const checked = !!values.checkedOptions?.includes(el.id || '');
                      if (checked)
                        return (
                          <div
                            key={el.id}
                            className={`${classes.option} ${checked && classes.checked}`}
                            onClick={() => setFieldValue('checkedOptions', handleCheck(values, el.id!))}
                          >
                            <Chekbox checked={checked} />
                            <p>{SelectedLanguage === 'ka-GE' ? el.nameGE : el.name}</p>
                          </div>
                        );
                    })}
                    <div className={classes.divider} />
                    {/* in this filter we are filtering if its Not checked or searchValue contains name or nameGE */}
                    {/* only unchecked options */}
                    {values.options
                      ?.filter(
                        (el) =>
                          !values.checkedOptions?.find((opt) => opt === el.id) &&
                          (el.name.toLowerCase().includes(searchOptVal.toLowerCase()) ||
                            el.nameGE.includes(searchOptVal))
                      )
                      .map((el) => {
                        const checked = !!values.checkedOptions?.find((opt) => opt === el.id);
                        return (
                          <div
                            key={el.id}
                            className={`${classes.option} ${checked && classes.checked}`}
                            onClick={() => setFieldValue('checkedOptions', handleCheck(values, el.id!))}
                          >
                            <Chekbox checked={checked} />
                            <p>{SelectedLanguage === 'ka-GE' ? el.nameGE : el.name}</p>
                          </div>
                        );
                      })}
                  </div>
                )}

                {values.keyFactType === keyFactEnum.SingleChoice && (
                  <div key={'single'}>
                    {values.options?.map((el) => {
                      const checked = !!values.checkedOptions?.find((opt) => opt === el.id);
                      return (
                        <>
                          <Radio
                            checked={checked}
                            setChecked={() => setFieldValue('checkedOptions', [el.id])}
                            name={SelectedLanguage === 'ka-GE' ? el.nameGE : el.name}
                          />
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
              <div>
                {' '}
                <div className={classes.horizontalDivider}></div>
                <Button className={classes.submitButton} type='submit'>
                  {resources.Reusable.save}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
}

export default OverviewForm;
