import React from 'react'
import useResources from '../../../Hooks/useResources'
import useWindowSize from '../../../Hooks/useWindowSize'
import classNames from "../styles.module.sass"

interface Props {
    readonly step: number
    readonly profileType: string
}

const SignUpSteps = ({ step, profileType }: Props) => {

    const resources = useResources()

    const { width: windowWidth } = useWindowSize()
    return (
        <div className={classNames.steps} style={step === 4 ? {display: 'none'} : undefined}>
            <div className={`
                ${classNames.step} 
                ${step === 1 && classNames.active}
                ${step > 1 && classNames.completed}
            `}>
                <p>{profileType === 'personal' ? resources.SignUp.personalD : resources.SignUp.companyD}{windowWidth > 426 && ` ${resources.SignUp.details}`}</p>
                <div className={classNames.line} />
            </div>
            {profileType === 'personal' && (
                <div className={`
                ${classNames.step} 
                ${step === 2 && classNames.active}
                ${step > 2 && classNames.completed}
                `}>
                    <p>{resources.SignUp.experience}</p>
                    <div className={classNames.line} />
                </div>
            )}
            <div className={`${classNames.step} ${step === 3 && classNames.active}`}>
                <p>{resources.SignUp.profile}</p>
                <div className={classNames.line} />
            </div>
        </div>
    )
}

export default SignUpSteps