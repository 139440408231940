import React, { useState } from 'react';
import InfoField from '../../../../Components/UI/ReusableComponents/InfoField/InfoField';
import SectionHeaderEdit from '../../../../Components/UI/ReusableComponents/SectionHeaderEdit/SectionHeaderEdit';
import useResources from '../../../../Hooks/useResources';
import classes from './styles.module.sass';
import Modal from '../../../../Components/UI/ReusableComponents/Modal/Modal';
import { KeyfactDataType, companyKeyFactType, keyFactEnum } from '../../../../Api';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../Redux/store';
import InputField from '../../../../Components/UI/ReusableComponents/InputField/InputField';
import { Form, Formik } from 'formik';

interface Props {
  keyFacts: (KeyfactDataType | companyKeyFactType)[];
  canEdit: boolean;
  setShowOverviewModal: React.Dispatch<React.SetStateAction<KeyfactDataType | companyKeyFactType | undefined>>;
  refetchData: () => void;
}

function Overview({ keyFacts, setShowOverviewModal, canEdit, refetchData }: Props) {
  const resources = useResources();
  const { SelectedLanguage } = useSelector((state: RootState) => state.language);

  const handleEdit = (keyFact: KeyfactDataType | companyKeyFactType) => {
    setShowOverviewModal(keyFact);
    refetchData();
  };

  const chosenValue = (keyFact: companyKeyFactType) => {
    switch (keyFact.keyFactType) {
      case keyFactEnum.MultipleChoice:
      case keyFactEnum.SingleChoice:
        if (keyFact.options && keyFact.checkedOptions) {
          return keyFact.checkedOptions
            .map((opt, idx) => {
              const result = keyFact.options?.find((el) => el.id === opt);
              if (result) {
                const selectedResult = SelectedLanguage === 'ka-GE' ? result.nameGE : result.name;
                if (keyFact.checkedOptions && keyFact.checkedOptions.length - 1 !== idx) return `${selectedResult}, `;
                return selectedResult;
              }
              return '';
            })
            .join('');
        }
        break;
      case keyFactEnum.Number:
      case keyFactEnum.Year:
        return keyFact.descriptionNumber?.toString();
      case keyFactEnum.Text:
        return SelectedLanguage === 'ka-GE' ? keyFact.descriptionGE : keyFact.descriptionEN;
    }
  };

  return (
    <>
      <SectionHeaderEdit
        header={resources.SignUp.details}
        canEdit={false}
        // handleClick={() => setShowOverviewModal(true)}
      />
      <div className={classes.overviewContainer}>
        {keyFacts.map((keyFact) => {
          if (keyFact.type === 'companyKeyFactType') {
            const value = chosenValue(keyFact);
            return (
              <InfoField
                key={keyFact.keyFactId}
                title={SelectedLanguage === 'ka-GE' ? keyFact.nameGE : keyFact.name}
                subText={value}
                canEdit={canEdit}
                handleEdit={() => handleEdit(keyFact)}
                isMandatory={keyFact.isMandatory}
              />
            );
          }
          return (
            <InfoField
              key={keyFact.id}
              title={SelectedLanguage === 'ka-GE' ? keyFact.nameGE : keyFact.name}
              subText={''}
              canEdit={canEdit}
              handleEdit={() => handleEdit(keyFact)}
              isMandatory={keyFact.isMandatory}
            />
          );
        })}
      </div>
    </>
  );
}

export default Overview;
