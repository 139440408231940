import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import * as Api from "../../Api";

interface UpdateProfileImageSliceState {
  readonly isLoading: boolean;
}

const updateProfileImageSliceState: UpdateProfileImageSliceState = {
  isLoading: false,
};

export const postUpdateProfileImage = createAsyncThunk<
  Api.ResponseUpdateProfileImageStatusType,
  Api.postUpdateProfileImageParams
>(
  "account/UpdateProfileImage",
  async (params: Api.postUpdateProfileImageParams, thunkApi) => {
    try {
      const formData = new FormData();

      if (params.body.ProfileImage != null) {
        formData.append("ProfileImage", params.body.ProfileImage);
      }
      
      const response = await Api.postUpdateProfileImageRequest(formData);

      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }

      throw error;
    }
  }
);

export const updateProfileImageSlice = createSlice({
  name: "recoverUserPasswordSlice",
  initialState: updateProfileImageSliceState,
  reducers: {
    updateIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postUpdateProfileImage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postUpdateProfileImage.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(postUpdateProfileImage.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { updateIsLoading } = updateProfileImageSlice.actions;

export default updateProfileImageSlice.reducer;
