import React from 'react';
import classes from './styles.module.sass';
import { ReactComponent as ChekIcon } from '../../../../Assets/Icons/check-icon-white.svg';

interface Props {
  checked?: boolean;
  onChange?: (val: boolean) => void;
  white?: boolean;
}

function Chekbox({ checked, onChange, white }: Props) {
  return (
    <div
      onClick={() => onChange && onChange(!checked)}
      className={`${checked ? classes.checked : classes.chekbox} ${white && classes.white}`}
      role='checkbox'
    >
      {checked && <ChekIcon />}
    </div>
  );
}

export default Chekbox;
