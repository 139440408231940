import React, { SVGProps } from 'react'

const PlusIcon = ({ ...props }: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            {...props}
        >
            <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(30 -6) rotate(90)">
                <path id="Path_19097" data-name="Path 19097" d="M18,7.5v21" fill="none" stroke="#a3a3a3" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                <path id="Path_19098" data-name="Path 19098" d="M7.5,18h21" fill="none" stroke="#a3a3a3" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
            </g>
        </svg>

    )
}

export default PlusIcon