import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

// ** style
import classes from './style.module.sass';

// ** 3rd party
import { ReactSVG } from 'react-svg';

// ** icons
import { ReactComponent as CalendarIcon } from '../../../../Assets/Icons/calendar-icon.svg';

// ** hooks
import useResources from '../../../../Hooks/useResources';
import useOutsideClick from '../../../../Hooks/useOutsideClick';
import { useRef } from 'react';

type CompanyTooltipModalProps = {
  verified: boolean;
  onClose(): void;
};

const CompanyTooltipModal = ({ verified, onClose }: CompanyTooltipModalProps) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const resources = useResources();
  useOutsideClick(tooltipRef, onClose);

  return ReactDOM.createPortal(
    <div className={classes.tooltipModal}>
      <div className={classes.tooltipModalWrapper} ref={tooltipRef}>
        <h5>{verified ? resources.Companies.AboutCompanyVerified : resources.Companies.AboutCompanyUnverified}</h5>
        <span>
          <ReactSVG
            src='https://softwaregecdn.azureedge.net/websitefiles/icons/circle-check-solid.svg'
            className={`${!verified && classes.notVerified}`}
          />
          <p>
            {verified ? resources.Companies.thisAccountVerified : resources.Companies.thisAccountUnclaimed}
            <Link to='/about'>{resources.Reusable.learnMore}</Link>
          </p>
        </span>
        {verified && (
          <span>
            <CalendarIcon />
            <p>{resources.Companies.verifiedSince}</p>
          </span>
        )}
        <button onClick={onClose}>{resources.Reusable.dismiss}</button>
      </div>
    </div>,
    document.getElementById('companyTooltipModal') as HTMLDivElement
  );
};

export default CompanyTooltipModal;
