import { Form, Formik } from 'formik';
import React from 'react';
import classes from './styles.module.sass';
import * as Yup from 'yup';
import FormInputFieldValidation from '../../../../Components/UI/ReusableComponents/FormInputFieldValidation/FormInputFieldValidation';
import Modal from '../../../../Components/UI/ReusableComponents/Modal/Modal';
import { KeyFacts } from '../../types';
import useResources from '../../../../Hooks/useResources';
import Button from '../../../../Components/UI/ReusableComponents/Button/Button';
import { countryCodesType, postUpdateCompanyKeyFactsUpdated } from '../../../../Api';
import { Dropdown } from '../../../../Components/UI/ReusableComponents/Dropdown/Dropdown';
import CountryCodeDropdown from '../../../../Components/UI/ReusableComponents/CountryCodeDropdown/CountryCodeDropdown';

interface Props {
  keyFacts: KeyFacts;
  setShowInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
  showInfoModal: boolean;
  refetchData: () => void;
  countryCodes?: countryCodesType['countryCodes'];
}

const phoneRegExp = /^[+]?\d+( \d+)*$/;
const URL =
  //eslint-disable-next-line
  /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

function InfoForm({ keyFacts, showInfoModal, setShowInfoModal, refetchData, countryCodes }: Props) {
  const resources = useResources();

  const FormSchema = Yup.object().shape({
    email: Yup.string().nullable().email(resources.SignUp.emailWrongFormat).required(resources.SignUp.emailRequired),
    phoneNumber: Yup.string().nullable().matches(phoneRegExp, resources.Companies.invalidPhone),
    website: Yup.string().nullable().matches(URL, resources.Companies.incorrectUrl),
    googleMapUrl: Yup.string().nullable().matches(URL, resources.Companies.incorrectUrl),
    headQuarterGoogleMapUrl: Yup.string().nullable().matches(URL, resources.Companies.incorrectUrl),
    facebookUrl: Yup.string().nullable().matches(URL, resources.Companies.incorrectUrl),
    linkedInUrl: Yup.string().nullable().matches(URL, resources.Companies.incorrectUrl),
    twitterUrl: Yup.string().nullable().matches(URL, resources.Companies.incorrectUrl),
    youTubeUrl: Yup.string().nullable().matches(URL, resources.Companies.incorrectUrl),
    tikTokUrl: Yup.string().nullable().matches(URL, resources.Companies.incorrectUrl),
  });

  const handleSubmit = (data: KeyFacts) => {
    postUpdateCompanyKeyFactsUpdated(data).then((resp) => {
      setShowInfoModal(false);
      refetchData();
    });
  };

  return (
    <Modal action={resources.Companies.Infobar} onClick={() => setShowInfoModal(false)} visible={showInfoModal}>
      <Formik initialValues={keyFacts} onSubmit={handleSubmit} validationSchema={FormSchema} enableReinitialize>
        {({ errors, touched, values, isValid, setFieldValue }) => (
          <Form className={classes.infoForm}>
            <div className={classes.phoneWrapper}>
              {countryCodes && (
                <div className={classes.phoneCode}>
                  <CountryCodeDropdown
                    options={countryCodes}
                    onChange={(val) => setFieldValue('countryCode', val.dialCode)}
                    value={values.countryCode}
                  />
                </div>
              )}
              <FormInputFieldValidation
                labelName={resources.Companies.PhoneNumber}
                valueName='phoneNumber'
                value={values.phoneNumber}
                error={touched.phoneNumber ? errors.phoneNumber : ''}
                touched={touched.phoneNumber != null && touched.phoneNumber}
                autoComplete='phoneNumber'
              />
            </div>
            <div className={classes.horizontalDivider}></div>
            <FormInputFieldValidation
              labelName={resources.Companies.Email}
              valueName='email'
              value={values.email}
              error={touched.email ? errors.email : ''}
              touched={touched.email != null && touched.email}
              autoComplete='email'
            />
            <div className={classes.horizontalDivider}></div>
            <FormInputFieldValidation
              labelName={resources.Companies.Website}
              valueName='website'
              value={values.website}
              error={touched.website ? errors.website : ''}
              touched={touched.website != null && touched.website}
              autoComplete='website'
            />
            <div className={classes.horizontalDivider}></div>
            <FormInputFieldValidation
              labelName={resources.Companies.AboutCompanyKeyFactsGeorgiaOffice}
              valueName='georgiaOffice'
              value={values.georgiaOffice}
              error={touched.georgiaOffice ? errors.georgiaOffice : ''}
              touched={touched.georgiaOffice != null && touched.georgiaOffice}
              autoComplete='georgiaOffice'
              type='textarea'
              component='textarea'
              rows='1'
            />
            <FormInputFieldValidation
              labelName={resources.Companies.GoogleMapUrl}
              valueName='googleMapUrl'
              value={values.googleMapUrl}
              error={touched.googleMapUrl ? errors.googleMapUrl : ''}
              touched={touched.googleMapUrl != null && touched.googleMapUrl}
              autoComplete='googleMapUrl'
            />
            <div className={classes.horizontalDivider}></div>
            <FormInputFieldValidation
              labelName={resources.Companies.Headquarters}
              valueName='headQuarter'
              value={values.headQuarter}
              error={touched.headQuarter ? errors.headQuarter : ''}
              touched={touched.headQuarter != null && touched.headQuarter}
              autoComplete='headQuarter'
              type='textarea'
              component='textarea'
              rows='1'
            />
            <FormInputFieldValidation
              labelName={resources.Companies.GoogleMapUrl}
              valueName='headQuarterGoogleMapUrl'
              value={values.headQuarterGoogleMapUrl}
              error={touched.headQuarterGoogleMapUrl ? errors.headQuarterGoogleMapUrl : ''}
              touched={touched.headQuarterGoogleMapUrl != null && touched.headQuarterGoogleMapUrl}
              autoComplete='headQuarterGoogleMapUrl'
            />
            <div className={classes.horizontalDivider}></div>
            <div className={classes.label}>{resources.Companies.SocialNetwork}</div>
            <FormInputFieldValidation
              labelName={'Facebook'}
              valueName='facebookUrl'
              value={values.facebookUrl}
              error={touched.facebookUrl ? errors.facebookUrl : ''}
              touched={touched.facebookUrl != null && touched.facebookUrl}
              autoComplete='facebookUrl'
            />
            <FormInputFieldValidation
              labelName={'Linkedin'}
              valueName='linkedInUrl'
              value={values.linkedInUrl}
              error={touched.linkedInUrl ? errors.linkedInUrl : ''}
              touched={touched.linkedInUrl != null && touched.linkedInUrl}
              autoComplete='linkedInUrl'
            />
            <FormInputFieldValidation
              labelName={'Twitter'}
              valueName='twitterUrl'
              value={values.twitterUrl}
              error={touched.twitterUrl ? errors.twitterUrl : ''}
              touched={touched.twitterUrl != null && touched.twitterUrl}
              autoComplete='twitterUrl'
            />
            <FormInputFieldValidation
              labelName={'Youtube'}
              valueName='youTubeUrl'
              value={values.youTubeUrl}
              error={touched.youTubeUrl ? errors.youTubeUrl : ''}
              touched={touched.youTubeUrl != null && touched.youTubeUrl}
              autoComplete='youTubeUrl'
            />
            <FormInputFieldValidation
              labelName={'TikTok'}
              valueName='tikTokUrl'
              value={values.tikTokUrl}
              error={touched.tikTokUrl ? errors.tikTokUrl : ''}
              touched={touched.tikTokUrl != null && touched.tikTokUrl}
              autoComplete='tikTokUrl'
            />
            <Button className={classes.submitButton} type='submit'>
              {resources.Reusable.save}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default InfoForm;
