import React from 'react';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.css';
import './index.sass';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './Redux/store';
import { HelmetProvider } from 'react-helmet-async';
import { LiveChatLoaderProvider, Messenger } from 'react-live-chat-loader';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <HelmetProvider>
          <LiveChatLoaderProvider provider='messenger' providerKey='109832551823211'>
            <App />
            <Messenger />
          </LiveChatLoaderProvider>
        </HelmetProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
