import React from 'react';
import classes from './styles.module.sass';
import useResources from '../../../../Hooks/useResources';

interface Props {
  tabs: { value: string | number; name: string; comingSoon?: boolean }[];
  activeTab: string | number;
  onChange: (val: string | number) => void;
  comparePage?: boolean;
}

function Tabs({ tabs, activeTab, onChange, comparePage }: Props) {
  const resources = useResources();
  return (
    <>
      <div className={`${classes.tabs} ${comparePage && classes.comparePage}`}>
        {tabs.map((tab) => {
          return (
            <div
              className={activeTab === tab.value ? classes.activeTab : classes.tab}
              key={tab.value}
              onClick={() => onChange(tab.value)}
            >
              <div>
                <div>
                  {tab.comingSoon && <span>{resources.Reusable.comingSoon}</span>}
                  {tab.name}
                </div>
              </div>
              <div className={classes.divider}></div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Tabs;
